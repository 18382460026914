/* eslint-disable react/style-prop-object */
import React, {Component} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Link} from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { toast } from 'react-toastify';
import { FormattedNumber, FormattedDate } from 'react-intl'
import axios from 'axios';
import Footer from '../components/footer';
import msg from '../images/msgnav.svg';
import project from '../images/landing/project.svg';
import twitter from '../images/twitternav.svg';
import social from '../images/socialnav.svg';
import internet from '../images/landing/internet.svg';
import refresh from '../images/landing/refresh.svg';
import copy from '../images/home/copy.svg';
import { buyTokens, getEthRaised, getTotalTokensAllocated } from '../web3Helper';
import Countdown from 'react-countdown';
import { getIdoCurrency, getChainSymbol } from '../utils';
import Loader from 'react-loader-spinner';
import binance from '../images/keymile/binance.svg';
import ethereum from '../images/keymile/ethereum.svg';
import polygon from '../images/keymile/polygon.svg';

class ProjectDetail extends Component {

  state = {
    estimatedGasForBuyingTokens: 0,
    refreshRotation: 0,
    ido: {},
    totalTokensAllocated: 0,
    ethRaised: 0,
    isTotalAllocationsLoading: true,
    isTotalRaiseEthLoading: true,
  }

  CopyText = ()=> {
    toast.success("Address Copy to Clipboard");
  }

  initDetails = async () => {
    const {match} = this.props;
    try {

      this.setState({ isTotalAllocationsLoading: true, isTotalRaiseEthLoading: true })

      const response = await axios.get(`/ido/${match.params.id}`);
      const { ido } = response.data;
      let ethRaised = ido.totalRaisedEth;

      buyTokens({
        contractAddress: ido.contractAddress,
        contractType: ido.contractType,
        value: ido.minAllocationEth || 0,
      }, true)
      .then((estimatedGasForBuyingTokens) => this.setState({ estimatedGasForBuyingTokens }))
      .catch((e) => console.log('Unable to fetch estimtes', e))

      if(!ethRaised) {
        getEthRaised({ contractAddress: ido.contractAddress, contractType: ido.contractType })
        .then((totalEthRaised) => 
          setTimeout(() => this.setState({ ethRaised: totalEthRaised, isTotalRaiseEthLoading: false }), 5000)
        )
        .catch((e) => {
          console.log('Error while fetching eth raised');
          setTimeout(() => this.setState({ isTotalRaiseEthLoading: false }), 5000)
        })
      } else {
        setTimeout(() => this.setState({ isTotalRaiseEthLoading: false }), 5000)
      }


      if(ido.contractAddress && !ido.tokensSold) {
        getTotalTokensAllocated({ contractAddress: ido.contractAddress, contractType: ido.contractType })
          .then((totalTokensAllocated) => 
            setTimeout(() => this.setState({ totalTokensAllocated, isTotalAllocationsLoading: false }), 5000)
          )
          .catch((e) => {
            console.log('Unable to fetch tokens allotted', e);
            setTimeout(() => this.setState({ isTotalAllocationsLoading: false }), 5000)
          })
      } else {
        setTimeout(() => this.setState({ isTotalAllocationsLoading: false }), 5000)
      }

      this.setState({
        ido,
        ethRaised
      })
    } catch (e) {
      toast.error('Network Error!')
    }
  }
  
  componentDidMount () {
    this.initDetails();
  }

  handleRefresh = async () => {
    this.setState({
      refreshRotation: this.state.refreshRotation + 360
    })
    await this.initDetails();
  }

  displayCurrency = (ido) => getIdoCurrency(ido.contractType, ido.blockChainNetworkId);

  render() {
    const {ido, estimatedGasForBuyingTokens, refreshRotation, ethRaised, totalTokensAllocated, isTotalAllocationsLoading, isTotalRaiseEthLoading} = this.state;
    const currentChainSymbol = getChainSymbol(ido.blockChainNetworkId);

    return (
      <div className="row no-gutters project-detail">
        <div className="col-12 first-div">
          <div className="row no-gutters">
            <div className="col-lg-6 col-sm-6">
              <div className="top-div">
                <div className="heading-div">
                  <img className="ido-logo" width="80" src={ ido.logoUrl ? process.env.REACT_APP_API_URL + ido.logoUrl : project } alt=""/>
                  <div className="name-div">
                    <h1>{ido.name || '-'}</h1>
                    <div className="img-div">
                      { !!ido.mediumLink &&
                        <a href={ido.mediumLink} target="_blank" rel="noreferrer">
                          <img src={social} alt=""/>
                        </a>
                      }
                      { !!ido.twitterLink &&
                        <a href={ido.twitterLink} target="_blank" rel="noreferrer">
                          <img src={twitter} alt=""/>
                        </a>
                      }
                      { !!ido.telegramLink &&
                        <a href={ido.telegramLink} target="_blank" rel="noreferrer">
                          <img src={msg} alt=""/>
                        </a>
                      }
                      { !!ido.websiteUrl &&
                        <a href={ido.websiteUrl} target="_blank" rel="noreferrer">
                          <img src={internet} alt=""/>
                        </a>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {
                ido.description && ido.description.split('\n').map((description) => <p className="detail">{description}</p>)
              }
              <div className="mid-div">
                <div>
                  <small>TOTAL RAISE</small>
                  <h4>{ !!ido.isFree ? "FREE" : <FormattedNumber maximumFractionDigits={0} currency="USD" style="currency" value={ido.totalRaise ? ido.totalRaise : 0} /> }</h4>
                </div>
                <div>
                  <small>NETWORK</small>
                  {
                    currentChainSymbol === 'BSC' ? <img width="40" src={binance} alt=""/> :
                    currentChainSymbol === 'ETH' ? <img width="40" src={ethereum} alt=""/> :
                    currentChainSymbol === 'MATIC' ? <img width="40" src={polygon} alt=""/> : null
                  }
                </div>
              </div>
              <div className="bottam-div">
                <div>
                  <small>PARTICIPANTS</small>
                  <p><FormattedNumber value={ido.allocations ? ido.allocations: 0} /></p>
                </div>
                <div>
                  <small>ALLOCATION SIZE</small>
                  {/* eslint-disable-next-line react/style-prop-object */}
                  <p><FormattedNumber maximumFractionDigits={0} currency="USD" style="currency" value={ido.allocationSize ? ido.allocationSize : 0} /></p>
                </div>
                <div>
                  <small>ACCESS</small>
                  <p className="text-uppercase">
                    {
                      ido && ido._id ? 
                        ido.prokingOnly ? <span>PRO KING</span>
                        : ido.access.length ?
                          ido.access.map( (acc, index) => (
                            <span>{!!index && '/'}{acc}</span>
                          ))
                        : '-'
                      : '-'
                    }
                  </p>
                </div>
              </div>
              <p>{ido && ido.type === 'GAME' ? 'IGO' : 'IDO'} SALE CONTRACT ADDRESS</p>
              {
                ido && ido.contractAddress ?
                <>
                  <p className="address">
                    {ido.contractAddress }  
                    <CopyToClipboard
                      text={ido.contractAddress}
                      onCopy={this.CopyText}>
                      <img className="ml-2 copy" src={copy} alt="" />
                    </CopyToClipboard></p>
                  <p className="mobile-address">
                    {ido.contractAddress.substr(0,6) + '...'  + ido.contractAddress.substr(-4)} 
                    <CopyToClipboard
                      text={ido.contractAddress}
                      onCopy={this.CopyText}>
                      <img className="ml-2 copy" src={copy} alt="" />
                    </CopyToClipboard>
                  </p>
                </>
                : 
                <>
                  <p className="address">
                    -
                  </p>
                  <p className="mobile-address">
                    -
                  </p>
                </>
              }
                    
             {(ido.status === 'WHITELISTING_APPLICATION_OPEN') && <button onClick={()=> this.props.history.push({pathname: `/dashboard/whiteList-application/${ido._id}`})}  className="btn">Apply For Whitelisting</button>}
             {(ido.status === 'LIVE_WHITELISTING_OPEN') && <Link to={`/white-list/${ido._id}`} className="btn">Join Live Whitelisting</Link>}
             {(ido.status === 'LIVE_WHITELISTING_CLOSED') && <Link to={`/allocation/${ido._id}`} className="btn">Get Allocation</Link>}
              {ido && ido.blockChainNetworkId === "1"  && ido.contractAddress && <a target="_blank" href={`https://etherscan.io/address/${ido.contractAddress}`} className="btn" rel="noreferrer">View Etherscan</a>}
              {ido && ido.blockChainNetworkId === "3"  && ido.contractAddress && <a target="_blank" href={`https://ropsten.etherscan.io/address/${ido.contractAddress}`} className="btn" rel="noreferrer">View Etherscan</a>}
              {ido && ido.blockChainNetworkId === "56" && ido.contractAddress && <a target="_blank" href={`https://bscscan.com/address/${ido.contractAddress}`} className="btn" rel="noreferrer">View BscScan</a>}
              {ido && ido.blockChainNetworkId === "97" && ido.contractAddress && <a target="_blank" href={`https://testnet.bscscan.com/address/${ido.contractAddress}`} className="btn" rel="noreferrer">View BscScan</a>}
              {ido && ido.blockChainNetworkId === "137" && ido.contractAddress && <a target="_blank" href={`https://polygonscan.com/address/${ido.contractAddress}`} className="btn" rel="noreferrer">View PolygonScan</a>}
              {ido && ido.blockChainNetworkId === "80001" && ido.contractAddress && <a target="_blank" href={`https://mumbai.polygonscan.com/address/${ido.contractAddress}`} className="btn" rel="noreferrer">View PolygonScan</a>}
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="box">
                <img 
                  className="refresh"
                  src={refresh}
                  alt="" 
                  onClick={this.handleRefresh}
                  style={{ transform: `rotate(${refreshRotation}deg)`, margin:'0px' }}
                />
                <p className="first-p">LIVE WHITELISTING STARTS IN</p>
                <span className="time">
                  {!!ido.liveWhitelistingStartDate  && <Countdown
                    date={ ido.liveWhitelistingStartDate || Date.now() }
                    renderer={({ formatted: { days, hours, minutes, seconds }}) => 
                      `${days} D : ${hours} H : ${minutes} M : ${seconds} S`
                    }
                  />}
                  {ido.liveWhitelistingStartDate === null && <span className="ml-2 time">To Be Announced</span>}
                  {ido.liveWhitelistingStartDate === undefined && <span className="ml-2 time">-</span>}
                </span>
                <div className="amount-div">
                  <div>
                    <small>SWAP AMOUNT</small>
                    <p><FormattedNumber value={ido.tokensToBeDistributed  ? ido.tokensToBeDistributed  : 0} /> {ido.tokenSymbol? ido.tokenSymbol: ''}</p>
                  </div>
                  <span>1 {this.displayCurrency(ido)}=<FormattedNumber maximumFractionDigits={0} notation="compact" compactDisplay="short" value={ Number( (ido.tokensPerWei || 0) ) } /> {ido.tokenSymbol}</span>
                </div>
                <div className="claim-div">
                  <div>
                    <small>TOTAL RAISE</small>
                    <p>
                      {
                        isTotalRaiseEthLoading ?
                          <Loader
                            type="Puff"
                            color="#ffffff"
                            className="d-inline mr-1"
                            height={ 15 }
                            width={ 15 }
                          />
                        : ethRaised || '-' 
                      }
                      /
                      {ido.totalRaiseEth || '-'} 
                      {this.displayCurrency(ido)}</p>
                  </div>
                  <div>
                    <small>ESTIMATED GAS FEE</small>
                    <p>{ ido.contractAddress ? <><FormattedNumber notation="compact" compactDisplay="short" value={estimatedGasForBuyingTokens} /> GWEI </> : '--' }</p>
                  </div>
                </div>
                <small>PROGRESS</small>
                <ProgressBar now={ !isTotalAllocationsLoading ? ((ido.tokensSold || totalTokensAllocated) * 100 / ido.tokensToBeDistributed) : 0 } />
                <div className="progress-div">
                  <p>
                    {
                      isTotalAllocationsLoading ?
                        <Loader
                          type="Puff"
                          color="#ffffff"
                          className="d-inline mr-1"
                          height={ 15 }
                          width={ 15 }
                        />
                      : ido.tokensToBeDistributed ? parseInt( (ido.tokensSold || totalTokensAllocated) * 100 / ido.tokensToBeDistributed) : 0 
                    }
                    % Completed
                  </p>
                  <p>
                    {
                      isTotalAllocationsLoading ?
                        <Loader
                          type="Puff"
                          color="#ffffff"
                          className="d-inline mr-1"
                          height={ 15 }
                          width={ 15 }
                        />
                      : <FormattedNumber notation="compact" compactDisplay="short" value={ ido.tokensSold ? ido.tokensSold : totalTokensAllocated ? totalTokensAllocated : 0} />
                    }
                    /
                    <FormattedNumber notation="compact" compactDisplay="short" value={ido.tokensToBeDistributed ? ido.tokensToBeDistributed: 0} /> {ido && ido.tokenSymbol ? ido.tokenSymbol.toUpperCase() : ''}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" col-12 last-div">
          <div className="row no-gutters">
            <div className="col-12">
              <div className="heading-div">
                <p className="active">Pool Details</p>
                {/* <p className="ml-3">About the project</p> */}
              </div>
            </div>
            <div className="col-lg-5 col-sm-5">
              <p>POOL INFORMATION</p>
              <div className="box">
                <p>TOKEN DISTRIBUTION</p>
                <span> { !!ido.tokenDistributionDate ? <FormattedDate value={ido.tokenDistributionDate} /> : '-' } </span>
                <div className="mid-div">
                  <div className="w-50">
                    <p>MIN. ALLOCATION</p>
                    <span><FormattedNumber maximumFractionDigits={0} currency="USD"  style="currency" value={ido.minAllocation ? ido.minAllocation : 0} /></span>
                  </div>
                  <div>
                    <p>ALLOCATION SIZE</p>
                    <span><FormattedNumber maximumFractionDigits={0} currency="USD"  style="currency" value={ido.allocationSize ? ido.allocationSize:0} /></span>
                  </div>
                </div>
                <div className="mid-div">
                  <div className="w-50">
                    <p>MIN. ALLOCATION ({this.displayCurrency(ido)})</p>
                    <span>{ ido.minAllocationEth ? <FormattedNumber value={ ido.minAllocationEth} />  : '-' } {this.displayCurrency(ido)}</span>
                  </div>
                  <div>
                    <p>ALLOCATION SIZE ({this.displayCurrency(ido)})</p>
                    <span>{ ido.allocationSizeEth ? <FormattedNumber value={ido.allocationSizeEth} />  : '-' } {this.displayCurrency(ido)}</span>
                  </div>
                </div>
                {/* Not scope of current development, can be integrated in future */}
                {/* <p>MIN SWAP LEVEL</p>
                <span><FormattedNumber value={ido.minSwapLevelEth} /> {this.displayCurrency(ido)}</span> */}
              </div>
            </div>
            <div className="col-lg-7 col-sm-7">
              <p className="token-p">TOKEN INFORMATION</p>
              <div className="box box2">
                <div className="mid-div">
                  <div>
                    <p>NAME</p>
                    <span>{ido.tokenName || '-'}</span>
                  </div>
                  <div>
                    <p>SYMBOL</p>
                    <span>{ido.tokenSymbol || '-'}</span>
                  </div>
                </div>
                <div className="mid-div">
                  <div>
                    <p>DECIMALS</p>
                    <span>{ido.tokenDecimals || '-'}</span>
                  </div>
                  <div>
                    <p>TOTAL SUPPLY</p>
                    <span><FormattedNumber notation="compact" compactDisplay="short" value={ido.totalTokenSupply?ido.totalTokenSupply:0} /></span>
                  </div>
                </div>
                <p>ADDRESS</p>
                <span className="address">{ido.tokenContractAddress || '-'} 
                { !!ido.tokenContractAddress &&
                  <CopyToClipboard text={ido.tokenContractAddress || '-'}
                    onCopy={this.CopyText}>
                    <img className="ml-1 copy" src={copy} alt="" />
                  </CopyToClipboard>
                }  
                </span>
                {ido && ido.tokenContractAddress && <span className="mobile-address">{ido.tokenContractAddress.substr(0,6) + '...'  + ido.tokenContractAddress.substr(-4)}<CopyToClipboard text={ido.tokenContractAddress}
                  onCopy={this.CopyText}>
                  <img className="copy" src={copy} alt="" />
                </CopyToClipboard></span>}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <Footer />
        </div>
      </div>
    )
  }
}

export default ProjectDetail;
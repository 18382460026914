import React, {Component} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

import Footer from '../components/footer';
import Navbar from '../components/topNav';
import Pool from '../components/landing/Pool';
import copy from '../images/home/copy.svg';

import video from '../images/video.mp4';
import mobileVideo from '../images/landing/mobileVideo.mp4';
import binance from '../images/home/binance.png';
import polygon from '../images/landing/polygon.png';
import solana from '../images/landing/solana.png';
import ethe from '../images/home/ethe.png';
import polkadat from '../images/home/polkadat.png';
import FeaturedPool from '../components/landing/FeaturedPool';
import SaleModal from '../components/landing/saleModal';
import KeyMileStones from '../components/landing/keyMileStone';
import EmailSubscription from '../components/landing/emailSubscription';
import Button from '../components/Button';
class LandingPage extends Component {
  
  state = {
    value: '0x2341dd0a96a0dab62aa1efb93d59ff7f3bdb8932',
    copied: false,
    id: "test",
    toggle: false
  };

  CopyText = ()=> {
    this.setState({copied: true});
    toast.success("Address Copy to Clipboard");
  }
  render() {
    const { toggle } = this.state;
    return (
      <div className="row no-gutters landing">
        <Navbar id={this.state.id} bar="home" />
        <SaleModal toggle={toggle} onCloseModal={()=> this.setState({toggle: false})} />
        <video className="web-video" autoPlay loop>
          <source src={video} type='video/mp4' />
        </video>
        <video className="mob-video" autoPlay loop>
          <source src={mobileVideo} type='video/mp4' />
        </video>
        <div className="col-12 top-div">
          <h1>We're on an <span>expedition</span></h1>
          <h1 className="heading">to find the <span>capital</span> you need.</h1>
          <p>Prostarter is a community-centric and transparent DeFi cross-chain platform offering 
              project fundraisings, token sales, and much more for the Crypto community.</p>
          <Button onClick={()=> this.setState({toggle: true})} value="Buy $PROT" /> 
          <Button onClick={()=> this.props.history.push('/ido')} className="btn" value="Kickstart your project" />
          <div className="address-div">
            <p>CONTRACT ADDRESS</p>
            <a  target="_blank" href="https://etherscan.io/address/0x2341dd0a96a0dab62aa1efb93d59ff7f3bdb8932" rel="noreferrer">0x234...….b8932</a>
            <CopyToClipboard text={this.state.value}
              onCopy={this.CopyText}>
              <img className="copy" src={copy} alt="" />
            </CopyToClipboard>
        </div>
        <span className="p">Powered By.</span>
         <div className="power row no-gutters">
           <div className="col">
          <img className="" src={polkadat} alt="" />
           </div>
           <div className="col">
          <img className="" src={binance} alt="" />
           </div>
           <div className="col">
          <img className="" src={ethe} alt="" />
           </div>
           <div className="col">
          <img className="" src={polygon} alt="" />
           </div>
           <div className="col">
          <img className="g" src={solana} alt="" />
           </div>
          </div>
         </div>
         <KeyMileStones />
        <Pool />
        <FeaturedPool />
        <EmailSubscription />
        <div className="col-md-12">
          <Footer />
        </div>
      </div>
        )
    }
}

export default LandingPage;
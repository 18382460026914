import React, {Component} from 'react';
import Loader from 'react-loader-spinner';
import { Scrollbars } from 'react-custom-scrollbars';
import Countdown from 'react-countdown';

class Rewards extends Component {
 
  render() {
    const { purchases, claimsInProcess, isClaimsLoading, onClaim } = this.props;

    return (
      <>
        <div className="table-responsive">
          <table className="table table-borderless">
            <thead>
              <tr className=" top-div">
                <th >NFT Title</th>
                <th >NFT Quantity</th>
                <th>NFT Contract Address</th>
                <th>Time Left</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {
                !isClaimsLoading && purchases.map((purchase) =>
                  <tr className="sec-div hide">
                    <td className="text-capitalize">{purchase.mysteryBoxName}</td>
                    <td  className="chnage">{purchase.quantity}</td>
                    <td  className="chnage">{purchase.nftContractAddress}</td>
                    <td>
                      <div className="count">
                        <Countdown date={new Date(purchase.releaseTime * 1000).getTime()} />
                      </div> 
                    </td>
                    <td>
                      {
                        purchase.isClaimed ? 
                          <div className="badge badge-success">Claimed</div>
                        : <button 
                            disabled={new Date() < new Date(purchase.releaseTime * 1000) } 
                            onClick={() => onClaim({ index: purchase.index })} 
                            className="btn global-btn"
                          >
                          {
                            claimsInProcess[`${purchase.index}`] ?
                              <Loader
                                type="Puff"
                                color="#ffffff"
                                height={ 30 }
                                width={ 30 }
                              />
                            : 'Claim Now'
                          }
                        </button>
                      }
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
         </div>  
         <Scrollbars style={ { height: 500, width: 'auto' } }
        className="scroll"
        renderTrackHorizontal={ props => <div { ...props } className="track-horizontal"/> }
        renderTrackVertical={ props => <div { ...props } className="track-vertical"/> }>
          <table className="table table-borderless">
            <thead>
            <tr className=" hide top-div">
                <th >NFT Title</th>
                <th >NFT Quantity</th>
                <th>NFT Contract Address</th>
                <th>Time Left</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                !isClaimsLoading && purchases.map((purchase) =>
                  <tr className="sec-div">
                    <td className="text-capitalize">{purchase.mysteryBoxName}</td>
                    <td  className="chnage">{purchase.quantity}</td>
                    <td  className="chnage">{purchase.nftContractAddress}</td>
                    <td>
                      <div className="count">
                        <Countdown date={new Date(purchase.releaseTime * 1000).getTime()} />
                      </div> 
                    </td>
                    <td>
                      {
                        purchase.isClaimed ? 
                          <div className="badge badge-success">Claimed</div>
                        : <button 
                            disabled={new Date() < new Date(purchase.releaseTime * 1000) } 
                            onClick={() => onClaim({ index: purchase.index })} 
                            className="btn global-btn"
                          >
                          {
                            claimsInProcess[`${purchase.index}`] ?
                              <Loader
                                type="Puff"
                                color="#ffffff"
                                height={ 30 }
                                width={ 30 }
                              />
                            : 'Claim Now'
                          }
                        </button>
                      }
                    </td>
                  </tr>
                )
              }

            </tbody>
          </table>
      </Scrollbars>  
           
      </>
    )
    }
}

export default Rewards;
import React, {Component} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import axios from 'axios';
import { toast } from 'react-toastify';
import lock from '../../images/dashboard/lock2.svg';
import { getUserAddress, getProtBalance, getBlockChainNetworkId } from '../../web3Helper';
import Countdown from 'react-countdown';
import Loader from 'react-loader-spinner';
import { StakingService } from '../../services';
import { withDashboardContext } from '../../store/DashboardContext';

class LockTokens extends Component {
  
  constructor() {
    super();
    this.state = {
      existingLockedAmount: 0,
      lockAmount: 0,
      daysLocked: 0,
      buttonStatus: 0, // 0 is for asking to lock tokens, 1 is for approving tokens, 2 is for confirming lock
      buttonLoadingStatus: 0, // 0 is for no loaders set, 1 is for approval in progress, 2 is for confirming lock in progress
      locks: [],
      lockReleasesInProcess: {},
      networkId: null
    }
    this.stakingService = new StakingService();
  }

  initUserLockData = () => {
    this.stakingService
      .getStakeInfo()
      .then(({ locked }) => {
        this.setState({
          existingLockedAmount: locked,
        })
      })

    getUserAddress().then( async (account) => {
      const { networkId } = this.state;

      if( !!account ) {
        try {
          const lockResp = await axios.get(
            `/lock/user/${account}`, 
            { 
              params: { 
                blockChainNetworkId: networkId 
              },
            }
          );
          this.setState({
            locks: lockResp.data.locks.map((lock) => ({...lock, status: 'loading'}))
          }, () => {

            Promise.all(
              lockResp
              .data
              .locks
              .map((lock) => 
                this.stakingService.checkStake({ 
                  lockId: lock.lockId 
                })
                .then((isReleased) => {
                  const updatedLock = this.state.locks.find((stateLock) => 
                    stateLock.lockId === lock.lockId );
                  return {
                    ...updatedLock, 
                    status: isReleased ? 'released' : 'locked' 
                  };
                })
                .catch((err) => {
                  console.log('Issue while fetching lock data', err)
                  const updatedLock = this.state.locks.find((stateLock) => 
                    stateLock.lockId === lock.lockId );

                  return {
                    ...updatedLock,
                    status: 'unavailable' 
                  };
                })
              )
            )
            .then((updatedLocks) =>{
              this.setState({ locks: updatedLocks })
            })
          })
        } catch(err){
          console.log('s',err);
          toast.error('Server Error.');
        }
      }
    })
  }

  componentDidMount() {
    getBlockChainNetworkId()
      .then(id => this.setState({networkId: id}))
      .catch(e=> console.log(e))
      .finally(this.initUserLockData);
  }

  setLockReleasesInProcess = ( index, flag ) => {
    const { lockReleasesInProcess } = this.state
    lockReleasesInProcess[index] = flag;
    this.setState({
      lockReleasesInProcess
    })
  }

  handleRelease = (lock) => {
    if(!this.validateBlockchainNetwork()) return;

    if(!this.isReleaseButtonDisabled(lock.releaseTime)) {

      this.setLockReleasesInProcess( lock.lockId, true );
        this.stakingService.unStake({ 
          lockId: lock.lockId 
        })
        .then( async () => {
          this.props.dashboardContext.initBalances && this.props.dashboardContext.initBalances();
          this.initUserLockData();
          toast.success('Lock released successfully, status will be updated in some time.');
        }).catch( async (e) => {
          if(e === 'Lock already released.') {
            this.initUserLockData();
            this.props.dashboardContext.initBalances && this.props.dashboardContext.initBalances();
          } else {
            toast.error( typeof e === 'string' ? e : e.message );
          }
          this.setLockReleasesInProcess( lock.lockId, false );
        })
    } else {
      toast.error('Cannot Release Lock')
    }
  }

  isReleaseButtonDisabled = (releaseTime) => !this.stakingService.isReleasable && Math.floor(new Date().getTime()/1000) < releaseTime 

  handleInputChange = (event) => this.setState({
    [event.target.name]: event.target.value
  })

  isValidateLock = async () => {
    try {
      const { lockAmount, daysLocked } = this.state;
      const {lockConfigurations} = this.props.dashboardContext;
      const maximumLockDays = lockConfigurations.value.maximumLockDays;
      const minimumLockAmount= lockConfigurations.value.minimumLockAmount;
      const protBalance = await getProtBalance();
      const userHolding = (await this.stakingService.getStakeInfo()).holding;
      
      const totalTokens = protBalance + userHolding;
  
      if(lockConfigurations === null) {
        toast.error('Lock Configuration Unavailable')
        return false;
      }
      if( typeof Number(lockAmount) !== 'number' ) {
        toast.error('Invalid Lock Amount.')
        return false;
      }
  
      if( Number(lockAmount) < minimumLockAmount ) {
        toast.error(`Lock Amount Should Be Greater Than ${minimumLockAmount}.`)
        return false;
      }
  
      if( typeof Number(daysLocked) !== 'number' || Number(daysLocked) <= 0 || Number(daysLocked) > maximumLockDays ) {
        toast.error('Invalid Days Locked.')
        return false;
      }
  
      if( Number(lockAmount) > Number(totalTokens)  ) {
        toast.error('Insufficient Tokens.')
        return false;
      }
  
      return true;
    } catch(err) {
      return false;
    }
  }

  handleConfirmLock = async () => {
    if( !(await this.isValidateLock()) ) return;
    const { lockAmount, daysLocked } = this.state;
    if(!this.validateBlockchainNetwork()) return;

    this.setState({
      buttonLoadingStatus: 2,
    })
    this.stakingService.stake({
      amount: lockAmount,
      periodInDays: daysLocked
    })
    .then(() => {
      this.props.dashboardContext.initBalances && this.props.dashboardContext.initBalances();
      this.initUserLockData();
      this.setState({
        buttonStatus: 0,
        buttonLoadingStatus: 0,
      })
      toast.success('Tokens locked successfully! The Lock will appear in the dashboard in some time.');
    }).catch((e) => {
      this.setState({
        buttonStatus: 0,
        buttonLoadingStatus: 0,
      })
      console.log('e', e)
      toast.error( typeof e === 'string' ? e : e.message );
    })
  };

  validateBlockchainNetwork = () => {
    if(!this.stakingService.isSupportedNetwork) {
      toast.error('Please, connect to the correct network');
      return false;
    }
    return true;
  }

  handleApprove = async () => {
    if( !(await this.isValidateLock()) ) return;
    const { lockAmount } = this.state;

    if(!this.validateBlockchainNetwork()) return;

    this.stakingService
    .getStakeInfo()
    .then(({
      holding,
    }) => {
      const tokensToLock = Number((lockAmount - holding).toFixed(2));
      if ( tokensToLock > 0 ) {
        this.setState({
          buttonLoadingStatus: 1,
        })
        this.stakingService.approve({
          amount: 1000000
        }).then(() =>{
          this.setState({
            buttonStatus: 2,
            buttonLoadingStatus: 0,
          })
          toast.success('Tokens approved, Please click on the "Confirm" button to Lock the tokens.');
        })
        .catch((e) => {
          this.setState({
            buttonStatus: 0,
            buttonLoadingStatus: 0,
          })
          console.log('e', e)
          toast.error( typeof e === 'string' ? e : e.message );
        })
      } else {
        this.setState({
          buttonStatus: 2,
          buttonLoadingStatus: 0,
        })
      }
    })
  };

  handleLock = async () => {
    if( !(await this.isValidateLock()) ) return;
    const { lockAmount } = this.state;
    if(!this.validateBlockchainNetwork()) return;
    
    this.stakingService
    .getStakeInfo()
    .then( async ({
      holding
    }) => {
      
      const tokensToLock = Number((lockAmount - holding).toFixed(2));

      if ( tokensToLock > 0 ) {

        const allowance = await this.stakingService.allowance();
        if(allowance >= tokensToLock) {
          this.setState({
            buttonStatus: 2,
          })
        } else {
          toast.success('Please click on "Approve" to provide us approval to lock your wallet tokens.');
          this.setState({
            buttonStatus: 1,
          })
        }
      } else {
        toast.success('Please click on the "Confirm" to Lock the tokens.');
        this.setState({
          buttonStatus: 2,
        })
      }
    })
  };

  render() {
    const { buttonStatus, buttonLoadingStatus, lockReleasesInProcess } = this.state;
    const { dashboardContext: { tickets = 0 } } = this.props;
    return (
      <div className="row no-gutters lock">
      <div className="left col-lg-5 col-sm-5">
        <div className="header-div">
          <div>
            <h6>TOTAL LOCKED</h6>
            <h1>{this.state.existingLockedAmount}</h1>
          </div>
          <div>
            <h6>NO. OF TICKETS</h6>
            <h1>{tickets}</h1>
          </div>
        </div>
        <div className="body-div">
            <img src={lock} alt="" />
            <h6>LOCK YOUR PROT TOKENS!</h6>
            <p>You can lock your PROT tokens (including Vested Tokens) to become Pro King or get tickets for IDO Live whitelisting, Tokens can only be unlocked when the days set to lock are completed.</p>
            <h6>Number of PROT Tokens</h6>
            <input type="text" className="form-control" name="lockAmount" onChange={this.handleInputChange} placeholder="For example. 500 $PROT"/>
            <h6>Number of Lock Days</h6>
            <input type="text" className="form-control" name="daysLocked"  onChange={this.handleInputChange} placeholder="For example. 30 Days"/>
            
            {
              buttonStatus === 0 
              ? <button className="btn global-btn chnge" onClick={() => this.handleLock()}>Lock Tokens</button>
              : buttonStatus === 1 ?  
                <button className="btn global-btn chnge" disabled={buttonLoadingStatus === 1} onClick={() => this.handleApprove()}>
                  {
                    buttonLoadingStatus === 1 ?
                      <Loader
                        type="Puff"
                        color="#ffffff"
                        height={ 30 }
                        width={ 30 }
                      />
                    : "Approve Tokens"
                  }
                    
                </button>
              : buttonStatus === 2
              ? <button className="btn global-btn chnge" disabled={buttonLoadingStatus === 2} onClick={() => this.handleConfirmLock()}>
                 {
                    buttonLoadingStatus === 2 ?
                      <Loader
                        type="Puff"
                        color="#ffffff"
                        height={ 30 }
                        width={ 30 }
                      />
                    : "Confirm Lock"
                  }
                </button>
              : null
            }
        </div>
      </div>
      <div className="right col-lg-7 col-sm-7">
      <table className="table top-table table-borderles ">
        <thead>
          <tr className=" top-div">
            <th>Date</th>
            <th>Amount</th>
            <th>Days</th>
            <th>Time Left</th>
            <th>Action</th>
          </tr>
        </thead>
      <tbody>
        <tr className="sec-div hide ">
          <td>07/05/2021 | 05:24:03</td>
          <td className="color-td">23</td>
          <td className="color-td">1</td>
          <td>
            00:00:00:00
          </td>
          <td>
            <button className="btn"   >Unlock</button>
          </td>
          </tr>
      
      </tbody>
    </table>
    <Scrollbars style={ { height: 580, width: 'auto' } }
      className="scroll"
      renderTrackHorizontal={ props => <div { ...props } className="track-horizontal"/> }
      renderTrackVertical={ props => <div { ...props } className="track-vertical"/> }>
        <table className="table table-borderles ">
        <thead>
          <tr className="top-div hide">
          <th >Date</th>
            <th >Amount</th>
            <th>Days</th>
            <th>Time Left</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
         {
      this.state.locks.map((lock) => 
        <tr className="sec-div">
          <td>{ new Date(lock.releaseTime * 1000).toLocaleDateString() + ' | ' + new Date(lock.releaseTime * 1000).toLocaleTimeString() }</td>
          <td className="color-td">{lock.tokens}</td>
          <td className="color-td">{lock.daysLocked}</td>
          <td>
            <div className="count">
              {
                lock.status === 'loading' ? 
                  <Loader
                    type="Puff"
                    color="#ffffff"
                    height={ 20 }
                    width={ 20 }
                  />
                : <Countdown date={ lock.status === 'released' ? new Date().getTime() : new Date(lock.releaseTime * 1000).getTime()} />
              }
            </div> 
          </td>
          <td>
            {
              lock.status === 'loading' ? 
                <Loader
                  type="Puff"
                  color="#ffffff"
                  height={ 20 }
                  width={ 20 }
                />
              : lock.status === 'released' ? 
                <div className="badge badge-success">Released</div>
              : lock.status === 'unavailable' ? 
                <div className="badge badge-danger">Wrong Network</div>
              : <button className="btn global-btn" onClick={() => this.handleRelease(lock)} disabled={this.isReleaseButtonDisabled(lock.releaseTime) || !!lockReleasesInProcess[lock.lockId]} >
                  {
                    !!lockReleasesInProcess[lock.lockId] ? 
                      <Loader
                        type="Puff"
                        color="#ffffff"
                        height={ 20 }
                        width={ 20 }
                      />
                    : "Unlock"
                  }
                </button>
            }
          </td>
          </tr>
      )
    }
  
        
    </tbody>
            </table>
    </Scrollbars>
    <Scrollbars style={ { height: 508, width: 'auto' } }
      className="scroll-m"
      renderTrackHorizontal={ props => <div { ...props } className="track-horizontal"/> }
      renderTrackVertical={ props => <div { ...props } className="track-vertical"/> }>
          {
            this.state.locks.map((lock) => 
              <div className="card">
                <h5 className="card-title">&nbsp;</h5>
                <div className="box">
                  <div>
                  <span>TOKENS</span>
                    <p>{lock.tokens}</p>
                  </div>
                  <div>
                    <span>DAYS</span>
                    <p>{lock.daysLocked}</p>
                  </div>
                  <div>
                    <span>Time LEFT</span>
                    <p>
                      {
                        lock.status === 'loading' ? 
                          <Loader
                            type="Puff"
                            color="#ffffff"
                            height={ 20 }
                            width={ 20 }
                          />
                        : <Countdown date={ lock.status === 'released' ? new Date().getTime() : new Date(lock.releaseTime * 1000).getTime()} />
                      }
                    </p>
                  </div>
                </div>
                <div className="sec-box">
                  <div>
                    <span>Date</span>
                    <p>{lock.date}</p>
                  </div>
                  {

                    lock.status === 'loading' ? 
                      <Loader
                        type="Puff"
                        color="#ffffff"
                        height={ 20 }
                        width={ 20 }
                      />
                    : lock.status === 'released' ? 
                      <div className="badge badge-success">Released</div>
                    : lock.status === 'unavailable' ? 
                      <div className="badge badge-danger">Wrong Network</div>
                    : <button className="btn" onClick={() => this.handleRelease(lock)} disabled={this.isReleaseButtonDisabled(lock.releaseTime) || !!lockReleasesInProcess[lock.lockId]}>
                      {
                        !!lockReleasesInProcess[lock.lockId] ? 
                          <Loader
                            type="Puff"
                            color="#ffffff"
                            height={ 20 }
                            width={ 20 }
                          />
                        : "Unlock"
                      }
                    </button>

                  }
                </div>

              </div>
            )
          }
          </Scrollbars>
      </div>
      </div>
      
        )
    }
}

export default withDashboardContext(LockTokens);
import { getContractABI, getContractAddress } from "../utils/abiHelper";
import { ether, getWeb3IfAvailable, parseError, toNumber, toRealNumber, addressZero } from "../web3Utils";
import { ContractService } from "./ContractService";

export const ROLE_MAPPING = {
    NON_WHITELISTED: {
      roleId: 0,
      name: 'NON_WHITELISTED',
    },
    PROKING: {
      roleId: 1,
      name: 'PROKING',
    },
    TICKET_HOLDER: {
      roleId: 2,
      name: 'TICKET_HOLDER',
    },
    WHITELISTED: {
      roleId: 3,
      name: 'WHITELISTED',
    }
}
export default class MysteryBoxService extends ContractService {

    constructor(args = {}) {
        super();
        this.contractType = 'MysteryBoxV2'
        this.abi = getContractABI(this.contractType);
    }

    async preHook() {
        this.web3 = await getWeb3IfAvailable()
        this.accounts = await this.web3.eth.getAccounts();
        this.contractAddress = await getContractAddress(this.contractType);
        this.contractInstance = new this.web3.eth.Contract(
            this.abi,
            this.contractAddress
        );
    }
    
    async getBoxDetails(boxId) {

        const userBoxMeta = await this.contractInstance.methods.getUserBoxMeta(boxId, this.accounts[0]).call();
        
        let results = await Promise.allSettled([
            this.contractInstance.methods.getMysteryBox(boxId).call(),
            this.contractInstance.methods.getPerWalletLimit(boxId, userBoxMeta.roleId).call(),
            this.contractInstance.methods.getPerWalletLimit(boxId, ROLE_MAPPING['PROKING'].roleId).call(),
            this.contractInstance.methods.getPerWalletLimit(boxId, ROLE_MAPPING['TICKET_HOLDER'].roleId).call(),
            this.contractInstance.methods.getBoxPaymentInfo(boxId).call(),
        ]);

        return {
            userRoleId: toRealNumber(userBoxMeta.roleId),
            totalPurchased: toRealNumber(userBoxMeta.totalPurchased),
            totalSold: results[0].value ? toRealNumber(results[0].value.totalSold) : 0,
            boxesToSell: results[0].value ? toRealNumber(results[0].value.boxesToSell) : 0,
            perWalletLimit: results[1].value ? toRealNumber(results[1].value) : 0,
            perWalletLimitForProking: results[2].value ? toRealNumber(results[2].value) : 0,
            perWalletLimitForTicketHolder: results[3].value ? toRealNumber(results[3].value) : 0,
            amount: results[4].value ? toNumber(results[4].value.amount) : 0,
            isPurchaseOpen: !!results[0].value.isPurchaseOpen,
            saleTime: toRealNumber(results[0].value.saleTime),
            saleReleaseTime: toRealNumber(results[0].value.saleReleaseTime),
            claimReleaseTime: toRealNumber(results[0].value.claimReleaseTime),
        }
    }

    async buy({
        boxId = '',
        quantity,
        amount
    }) {
        try {
            const gasPrice = await this.web3.eth.getGasPrice();

            let args = [
                boxId,
                Number(quantity)
            ], options = {
                gasPrice,
                from: this.accounts[0]
            };

            const boxPaymentInfo = await this.contractInstance.methods.getBoxPaymentInfo(boxId).call();

            if(boxPaymentInfo.paymentAddress === addressZero()) {
                options['value'] = ether(amount);
                args.push(ether(0));
            } else {
                args.push(ether(amount));
            }

            const estimatedGas = await this.contractInstance
                .methods
                .buy( ...args)
                .estimateGas(options);
             
            options['gas'] = estimatedGas + 5000;
            await this.contractInstance
                .methods
                .buy( ...args )
                .send(options);
        } catch(err) {
            throw parseError(err);
        }
        return 'Box bought successfully!';
    }

    async getUserPurchases({
        boxId,
    }) {
            
        const userBoxMeta = await this.contractInstance.methods.getUserBoxMeta(boxId, this.accounts[0]).call();

        let purchases = await Promise.allSettled(
            new Array(toRealNumber(userBoxMeta.purchaseCount)).fill(0).map(
                (arg, index) => this.contractInstance.methods.getUserPurchaseByIndex(boxId, this.accounts[0], index).call(),
            )
        );

        return purchases
            .map((purchase, index) => ({
                index,
                contractAddress: this.contractAddress,
                ...(
                    purchase.value ? {
                        mysteryBoxName: purchase.value.name,
                        nftContractAddress: purchase.value.nftToken,
                        quantity: toRealNumber(purchase.value.purchaseQuantity),
                        releaseTime: toRealNumber(purchase.value.releaseTime),
                        amount: toNumber(purchase.value.purchaseAmount),
                        isClaimed: purchase.value.isClaimed
                    } : {}
                )
            }))
    }

    async claim({
        boxId,
        index
    }) {
        try {
            const gasPrice = await this.web3.eth.getGasPrice();

            const estimatedGas = await this.contractInstance.methods.claim(
                boxId,
                Number(index)
            ).estimateGas({
                from: this.accounts[0]
            });
             
            await this.contractInstance
                .methods
                .claim(
                    boxId,
                    Number(index)
                )
                .send({ 
                    from: this.accounts[0],
                    gasPrice,
                    gas: estimatedGas + 5000,
                });
        } catch(err) {
            throw parseError(err);
        }
        return 'Box claimed successfully!';
    }

}
import React, {Component} from 'react';
import Loader from 'react-loader-spinner';
import { getChainSymbol, getIdoCurrency } from '../../utils';
import { FormattedNumber, FormattedDate } from 'react-intl'

class Rules extends Component {
 
  render() {
    const { isLoading, mysteryBoxName, currentBlockChainNetworkId, blockChainNetworkId, perWalletLimitForProking, perWalletLimitForTicketHolder, usd, boxesToSell, saleTime, saleReleaseTime, claimReleaseTime } = this.props;

    return (  
      <>
        {
          !currentBlockChainNetworkId ?
           null
          : isLoading ?
            <div className="text-center">
              <Loader
                type="Puff"
                color="#ffffff"
                className="d-inline mr-1"
                height={ 50 }
                width={ 50 }
              /> 
            </div>
          :
          <div className="rules">
            <p className="ml-2">Here are the details and rules for Prostarter Community to participate in the sale of {mysteryBoxName} Mystery Boxes.</p>
            <ul>
              <li><b>Total Mystery Boxes:</b> {boxesToSell}</li>
              {/* eslint-disable-next-line  react/style-prop-object */}
              <li><b>Price per Mystery Box:</b> <FormattedNumber maximumFractionDigits={0} currency="USD" style="currency" value={usd} /></li>
              <li><b>Mystery Box Buying Limit for Pro Kings:</b> {perWalletLimitForProking}</li>
              <li><b>Mystery Box Buying Limit for Ticket Holders:</b> {perWalletLimitForTicketHolder}</li>
              <li>The Mystery Box pool will be opened on <b><FormattedDate timeZone="UTC" hour12 dateStyle="medium" timeStyle="long" value={saleTime} /></b> only for Pro Kings and Ticket Holders.</li>
              <li>Both Pro Kings and Ticket Holders will be able to buy Mystery Boxes on an FCFS basis. The pool will be opened for the public after <b><FormattedDate timeZone="UTC" hour12 dateStyle="medium" timeStyle="long" value={saleReleaseTime} /></b> if any Mystery Boxes are left.</li>
              <li>NFT Sale of {mysteryBoxName} Mystery Boxes will be held on {getChainSymbol(blockChainNetworkId)} and users will have to spend {getIdoCurrency('',blockChainNetworkId)} to buy Mystery Boxes.</li>
              {
                claimReleaseTime ?
                  <li>Mystery Box will be revealed on <b><FormattedDate timeZone="UTC" hour12 dateStyle="medium" timeStyle="long" value={claimReleaseTime} /></b>.</li>
                : null
              }
            </ul>
          </div>
        }
      </>
    )
  }
}

export default Rules;
import React, {Component} from 'react';
import profile from '../images/profile.svg';
import { Tooltip } from 'reactstrap';
import calculator from '../images/dashboard/calculator.svg';
import rocket from '../images/landing/rocket.svg';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import tool from '../images/dashboard/tool.svg';
import lock from '../images/dashboard/lock.svg';
import king from '../images/dashboard/king.svg';
import money from '../images/dashboard/money.svg';
import tick from '../images/dashboard/tick.svg';
import customerTick from '../images/dashboard/customerTick.svg';
import Customer from '../components/dashboard/customer';
import Project from '../components/dashboard/project';
import Accumulation from '../components/dashboard/accumulation';
import { getNetworkName } from '../utils';
import Calculator from '../components/dashboard/Calculator';
import WhiteList from '../components/dashboard/white';
import LockTokens from '../components/dashboard/LockTokens';
import { withDashboardContext } from '../store/DashboardContext';

class Dashboard extends Component {

  state = {
    tooltipOpen: false,
    tooltipOpen2: false,
    tooltipOpen3: false,
    tooltipOpen4: false,
  }

  toggle = () => this.setState({tooltipOpen: !this.state.tooltipOpen});
  toggle2 = () => this.setState({tooltipOpen2: !this.state.tooltipOpen2});
  toggle3 = () => this.setState({tooltipOpen3: !this.state.tooltipOpen3});
  toggle4 = () => this.setState({tooltipOpen4: !this.state.tooltipOpen4});

  render() {

    const { 
      tooltipOpen,
      tooltipOpen2,
      tooltipOpen3,
      tooltipOpen4,
    } = this.state;

    const { 
      match, 
      dashboardContext: { 
        account,
        isVerified,
        isProking,
        totalProt,
        totalHolding,
        totalTokens,
        locked,
        blockChainNetworkId,
      }
    } = this.props;

    return (
       <div className="row no-gutters dashboard px-4 py-5">
        <div className="col-md-12">
            <div className="row no-gutters">
              <div className="col-lg-3 col-sm-3">
                <div className="card side-card">
                  <div className="top-img">
                   {isProking && <img src={king} alt=""/>}
                  </div>
                  <div className="profile">
                    <img src={profile} alt="" width="22%" />
                    <div>
                      <span>YOUR ADDRESS</span>
                      <p>{ account ? account.substr(0,6) + '...'  + account.substr(-4): 'Connect Wallet' }</p>
                      <span>Network:</span> <span className="font-weight-bold">{getNetworkName(blockChainNetworkId.toString())}</span>
                    </div>
                  </div>
                  {/* <button className="btn">Your Holdings: {totalProt} PROT</button> */}
                  <div className="total-holding">
                    <div>
                    <span id="TooltipExample1">TOTAL BALANCE <img src={tool} alt="" /></span>
                    <Tooltip className="toltip" placement="right" isOpen={tooltipOpen} target="TooltipExample1" toggle={this.toggle} >
                      Sum of Locked, Vested Holdings and Wallet Balance
                    </Tooltip>                
                    <p className="total">{totalTokens}</p>
                    </div>
                    <div>
                    <span id="TooltipExample2">LOCKED TOKENS <img src={tool} alt="" /></span>
                    <Tooltip className="toltip" placement="right" isOpen={tooltipOpen2} target="TooltipExample2" toggle={this.toggle2} >
                      Sum of all the locked tokens
                    </Tooltip>  
                    <p>{locked}</p>
                    </div>
                  </div>
                  <div className="total-holding">
                    
                  <div >
                    <span id="TooltipExample3">PROT HOLDINGS <img src={tool} alt="" /></span>
                    <Tooltip className="toltip" placement="right" isOpen={tooltipOpen3} target="TooltipExample3" toggle={this.toggle3} >
                      Sum of all the vested tokens
                    </Tooltip>  
                    <p>{totalHolding}</p>
                  </div>
                  <div>
                    <span id="TooltipExample4">WALLET BALANCE <img src={tool} alt="" /></span>
                    <Tooltip className="toltip" placement="right" isOpen={tooltipOpen4} target="TooltipExample4" toggle={this.toggle4} >
                      Your current wallet balance
                    </Tooltip>
                    <p>{totalProt}</p>
                  </div>
                  </div>
                  <hr />
                  <p className="card-text">Dashboard</p>
                  <ul>
                    <Link className="text-white" to="/dashboard/kyc">
                      <li className={`${match.params.name === 'kyc' ? 'active': ''}`} >
                          <img src={tick} alt="" width="18"/> KYC Status 
                          { isVerified && <img className="tick-img ml-2" width="18" src={customerTick} alt=""/> }
                      </li>
                    </Link>
                    <Link to="/dashboard/projects">
                      <li className={`${match.params.name === 'projects' ? 'active': ''}`}>
                          <img src={rocket} alt="" width="18"/> Projects
                      </li>
                    </Link>
                    <Link to="/dashboard/accumulations">
                      <li className={`${match.params.name === 'accumulations' ? 'active': ''} `}>
                          <img src={money} alt="" width="18"/> Accumulations
                      </li>
                    </Link>
                    <Link to="/dashboard/lock">
                      <li className={`${match.params.name === 'lock' ? 'active': ''} `}>
                        <img style={{marginBottom: '5px'}} src={lock} alt="" width="18"/> Lock Tokens
                      </li>
                    </Link>

                  </ul>
                  <p className="card-text">Application</p>
                  <ul>
                    <Link to="/dashboard/calculator">
                      <li className={`${match.params.name === 'calculator' ? 'active': ''}`}>
                        <img src={calculator} alt="" width="18"/> Calculator
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
              <div className="col-lg-9 col-sm-9">
                <Switch>
                  <Route path="/dashboard/kyc" component={Customer} />
                  <Route path="/dashboard/projects" component={Project} />
                  <Route path="/dashboard/accumulations" component={Accumulation} />
                  <Route path="/dashboard/lock" component={LockTokens} />
                  <Route path="/dashboard/calculator" component={Calculator} />
                  <Route path="/dashboard/whiteList-application/:id" component={WhiteList} />
                </Switch>
              </div>
            </div>
        </div>
          </div>
        )
    }
}

export default withRouter(withDashboardContext(Dashboard));
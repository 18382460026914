import React, {Component} from 'react';
import ItemsCarousel from 'react-items-carousel';

import ProgressBar from 'react-bootstrap/ProgressBar';

class Carousal extends Component {
  
    state = {
        activeItemIndex: 0,
        width: '',
        responsive : {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      }
    }

    componentDidMount () {
        window.addEventListener("resize", this.update);
    }

    update = () => {
    
        this.setState({
          width: window.innerWidth
        });
      };
    changeActiveItem = (activeItemIndex) => this.setState({ activeItemIndex });
  render() {
    const {activeItemIndex, width} = this.state;
    console.log(width)
    return (
       
        <ItemsCarousel
        // Placeholder configurations
        enablePlaceholder
        numberOfPlaceholderItems={5}
        minimumPlaceholderTime={1000}
        placeholderItem={<div style={{ height: 200, background: '#900' }}>Placeholder</div>}

        // Carousel configurations
        numberOfCards={width !== '' &&  width <  760 ? 1 : 3}
        gutter={12}
        showSlither={true}
        firstAndLastGutter={true}
        freeScrolling={false}
        infiniteLoop={true}
        // Active item configurations
        requestToChangeActive={this.changeActiveItem}
        activeItemIndex={activeItemIndex}
        activePosition={'center'}

        chevronWidth={24}
        rightChevron={<button className="btn">{'>'}</button>}
        leftChevron={<button className="btn">{'<'}</button>}
        outsideChevron={false}
      >

            <div className="card" >
              <div className="card-body">
                <h5 className="card-title">Project Summary</h5>
               
                <div className="price">
                <div >
                  <small>SWAP AMOUNT</small>
                  <h6>20000000.00 FCL</h6>
                </div>
                <div >
                  <small>CLOSE IN</small>
                  <h6>ENDED</h6>
                </div>
                <div >
                <small>CLOSE IN</small>
                  <h6>ENDED</h6>
                </div>
                </div>
               
                <small>PROGRESS</small>
                <ProgressBar now={60} />
                <div className="mid-div">
                  <p>%</p>
                  <p><span>499,999</span>/500,000</p>
                </div>
                <div className="botam-div">
                  <div>
                    <small>Participants</small>
                    <p>326</p>
                  </div>
                  <div>
                    <small>Maximum</small>
                    <p>0.52 ETH</p>
                  </div>
                  <div>
                    <small>ACCESS</small>
                    <p>Public</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card text-card" >
              <div className="card-body">
                <h5 className="card-title">About Project</h5>
                <p className="card-text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.sed diam nonumy eirmod temporet</p>
                <p className="card-text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.</p>
                <p className="card-text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.</p>
               
              </div>
            </div>
           
            <div className="card text-card" >
                <h5 className="card-title"> Pool Information</h5>

               <ul>
                   <li>Lorem ipsum</li>
                   <li>Lorem ipsum</li>
               </ul>
               <hr />
               <ul>
                   <li>Lorem ipsum</li>
                   <li>Lorem ipsum</li>
               </ul>
               <hr />
               <ul>
                   <li>Lorem ipsum</li>
                   <li>Lorem ipsum</li>
               </ul>
               <hr />
               <ul>
                   <li>Lorem ipsum</li>
                   <li>Lorem ipsum</li>
               </ul>
              
                </div>
                {/* <div>s</div> */}
                </ItemsCarousel>
        )
    }
}

export default Carousal;
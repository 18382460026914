import React, {Component} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Select from 'react-select';
import { toast } from 'react-toastify';
import search from '../../images/whitelisting/search.svg';
import axios from 'axios';
import { checkClaim, claimVest } from '../../web3Helper';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import Countdown from 'react-countdown';
import Loader from 'react-loader-spinner';
import { withDashboardContext } from '../../store/DashboardContext';
import {Link} from 'react-router-dom';

class Accumulation extends Component {
  
  constructor() {
    super();
    this.state = {
      claims: [],
      claimsFilter: 'unclaimed',
      claimsInProcess: {},
      searchField: '',
      warning: false,
      isLoading: false,
      ...(
        process.env.REACT_APP_ENV === 'prod' ? {
          options: [{value: '1', label: 'Etherum',}, {value: '56', label: 'Binance Smart Chain'}],
          defaultOptions: [{value: '1', label: 'Etherum'}],
        } : {
          options: [{value: '3', label: 'Ropsten '}, {value: '97', label: 'Binance Test Chain'}],
          defaultOptions: [{value: '3', label: 'Ropsten'}],
        }
      )
    }
  }

  componentDidMount(){
    this.getClaims();
  }

  componentDidUpdate(prevProps) {
    if( 
      prevProps.dashboardContext && 
      this.props.dashboardContext && 
      (
        prevProps.dashboardContext.blockChainNetworkId !== 
        this.props.dashboardContext.blockChainNetworkId
        ||
        prevProps.dashboardContext.account !== 
        this.props.dashboardContext.account
      )
    ) {
      this.getClaims();
    }
  }

  getClaims =  async () => {

    const { dashboardContext:{ blockChainNetworkId, account } } = this.props;
    const { claimsFilter } = this.state;
    if( account && blockChainNetworkId ) {
      try {
        this.setState({
          isLoading: false
        })
        const transactionResp = await axios.get(
          `/claim/user/${account}`,
          { 
            params: { 
              filterBy: claimsFilter,
              blockChainNetworkId 
            } 
          }
        );

        this.setState({
          claims: transactionResp.data.claims.map((claim) => ({...claim, status: 'loading'})),
          isLoading: false
        },() => {
          Promise.all(transactionResp
            .data
            .claims
            .map((claim) => 
              checkClaim({
                vestId: claim.vestId,
                contractAddress: claim.contractAddress,
                contractType: claim.contractType 
              })
                .then((isClaimed) => {
                  const updatedClaim = this.state.claims.find((stateClaim) => 
                  stateClaim.idoId === claim.idoId && stateClaim.vestId === claim.vestId );
                    
                  return { ...updatedClaim, status: isClaimed ? 'claimed' : 'vested' };
                })
                .catch((err) => {
                  console.log('Issue while fetching lock data', err)
                  const updatedClaim = this.state.claims.find((stateClaim) => 
                  stateClaim.idoId === claim.idoId && stateClaim.vestId === claim.vestId );
                  return { ...updatedClaim, status: 'unavailable' };
                })
            )).then((updatedClaims) => 
              this.setState({ claims: updatedClaims })
            )
        })
      } catch(e){
        this.setState({
          isLoading: false
        })
        toast.error('Server Error.')
      }
    }
  }

  onFilterChange = (filterValue) => {
    this.setState({
      claimsFilter: filterValue,
      claims: []
    }, () => {
      this.getClaims();
    })
  }

  setClaimsInProcess = ( index, flag ) => {
    const { claimsInProcess } = this.state
    claimsInProcess[index] = flag;
    this.setState({
      claimsInProcess
    })
  }

  setIsClaimed = (claim) => axios.post(`/claim/update-vest/${claim.userAddress}`, { vestId: claim.vestId, isClaimed: true });

  claimVest = (claim) => {
    const { dashboardContext:{ blockChainNetworkId } } = this.props;

    if(blockChainNetworkId.toString() !== claim.blockChainNetworkId) {
     return toast.error('Please, connect to the correct network');
    }

    if(!this.isClaimButtonDisabled(claim.releaseTime)) {
      this.setClaimsInProcess( claim._id, true );
      claimVest({
        vestId: claim.vestId,
        contractAddress: claim.contractAddress,
        contractType: claim.contractType,

      }).then( async () => {
        // Vests will be synced in backend
        // await this.setIsClaimed(claim);
        toast.success('Vesting claimed successfully, status will be updated in some time.');
        this.setClaimsInProcess( claim._id, false );
        this.props.dashboardContext.initBalances && this.props.dashboardContext.initBalances();
      }).catch( async (e) => {
        if( e.includes && e.includes('Already Claimed')){
          await this.setIsClaimed(claim);
          this.props.dashboardContext.initBalances && this.props.dashboardContext.initBalances();
        } else {
          toast.error( typeof e === 'string' ? e : e.message );
        }
        this.setClaimsInProcess( claim._id, false );
      })
    } else {
      toast.error('Cannot Claim Vest')
    }
  }
  isClaimButtonDisabled = (releaseTime) => Math.floor(new Date().getTime()/1000) < releaseTime

  searchClaims = (e) => {
    this.setState({ searchField: e.target.value });
  }

  render() {
    const { dashboardContext: { isVerified } } = this.props;
    const { claims, isLoading, claimsInProcess, searchField, claimsFilter, options, defaultOptions } = this.state;
    const filteredClaims = claims.filter(claim =>
      claim.tokenName.toLowerCase().includes(searchField.toLowerCase()));
    return (
       <>
        <div className="accumulation-div">
        {/* <div className="top-div"> */}
        <input type="search" className="form-control search-input"
         onChange={this.searchClaims} placeholder="Search by Project Name/Token Symbol" />
        <img src={search} alt="" className="search-icon" />
        <div className="filters-div">
         <div className="check-div">
            <label>
              <Checkbox
                type="checkbox"
                checked={ claimsFilter === '' }
                onChange={ () => this.onFilterChange('') }
              />
              &nbsp; All
            </label>
            <label>
              <Checkbox
                type="checkbox"
                checked={ claimsFilter === 'claimed' }
                onChange={ () => this.onFilterChange('claimed') }

              />
              &nbsp; Claimed
            </label>
            <label>
              <Checkbox
                type="checkbox"
                checked={ claimsFilter === 'unclaimed' }
                onChange={ () => this.onFilterChange('unclaimed') }
              />
              &nbsp; Unclaimed
            </label>
          </div>
          {/* <Select
            placeholder=""
            onChange={ value => this.setState({ type: value.label }) }
            defaultValue={defaultOptions}
            options={options} 
          /> */}
        </div>
       {isLoading && <Loader
          className="large-loader"
          type="Puff"
          color="#ffffff"
          height={ 100 }
          width={ 100 }/>
        }
        <table className="table top-table table-borderles ">
            <thead>
    <tr className=" top-div">
      <th >Project Name</th>
      <th >Token Name</th>
      <th>Tokens</th>
      <th>Time Left</th>
      <th>Action</th>
    </tr>
    
  </thead>
  <tbody>
  {
      filteredClaims.map((claim) => 
        <tr className="sec-div hide">
          <td className="text-capitalize">{
            claim.projectName === 'Round 1' 
            ? '(PRE SALES) ' + claim.projectName
            : claim.projectName
          }</td>
          <td>{claim.tokenName}</td>
          <td>{claim.tokens}</td>
          <td>
            <div className="count">
              <Countdown date={new Date(claim.releaseTime * 1000).getTime()} />
            </div> 
          </td>
          <td>{
            claim.status === 'loading' ? 
              <Loader
                type="Puff"
                color="#ffffff"
                height={ 20 }
                width={ 20 }
              />
            : claim.status === 'claimed' ? 
              <div className="badge badge-success">Claimed</div>
            : claim.status === 'unavailable' ? 
              <div className="badge badge-danger">Wrong Network</div>
            : !isVerified ? 
              <Link className="btn" to="/dashboard/kyc" >Complete KYC</Link>
            : <button className="btn" disabled={this.isClaimButtonDisabled(claim.releaseTime) || !!claimsInProcess[claim.vestId] } onClick={() => this.claimVest(claim)}>
                {
                  !!claimsInProcess[claim._id] ? 
                    <Loader
                      type="Puff"
                      color="#ffffff"
                      height={ 20 }
                      width={ 20 }
                    />
                  : "Claim Now"
                }
              </button>
          }</td>
        </tr>
     
     )
      
    }
  </tbody>

  </table>
        <Scrollbars style={ { height: 500, width: 'auto' } }
      className="scroll"
      renderTrackHorizontal={ props => <div { ...props } className="track-horizontal"/> }
      renderTrackVertical={ props => <div { ...props } className="track-vertical"/> }>
            <table className="table table-borderles ">
            <thead>
    <tr className="top-div hide">
      <th >Project Name</th>
      <th>Token Name</th>
      <th>Tokens</th>
      <th>Time Left</th>
      <th>Action</th>
    </tr>
    
  </thead>
 
  <tbody>
   {
      filteredClaims.map((claim) => 
        <tr className="sec-div">
          <td className="text-capitalize">{
            claim.projectName === 'Round 1' 
            ? '(PRE SALES) ' + claim.projectName
            : claim.projectName
          }</td>
          <td  className="color-td">{claim.tokenName}</td>
          <td  className="color-td">{claim.tokens}</td>
          <td>
            <div className="count">
              <Countdown date={new Date(claim.releaseTime * 1000).getTime()} />
            </div> 
          </td>
          <td>
          {
            claim.status === 'loading' ? 
              <Loader
                type="Puff"
                color="#ffffff"
                height={ 20 }
                width={ 20 }
              />
            : claim.status === 'claimed' ? 
              <div className="badge badge-success">Claimed</div>
            : claim.status === 'unavailable' ? 
              <div className="badge badge-danger">Wrong Network</div>
            : !isVerified ? 
              <Link className="btn" to="/dashboard/kyc" >Complete KYC</Link>
            : <button className="btn global-btn" disabled={this.isClaimButtonDisabled(claim.releaseTime) || !!claimsInProcess[claim.vestId] } onClick={() => this.claimVest(claim)}>
               {
                !!claimsInProcess[claim._id] ? 
                  <Loader
                    type="Puff"
                    color="#ffffff"
                    height={ 20 }
                    width={ 20 }
                  />
                : "Claim Now"
              }
            </button>
          }
          </td>
        </tr>
      )
    }
    </tbody>
            </table>
    </Scrollbars>
           
        </div>
        <div className="mobile-accumulation">
          <input type="search" className="form-control search-input"
          onChange={this.searchClaims} placeholder="Search by Project Name/Token Symbol" />
          <img src={search} alt="" className="search-icon" />
          <div className="filters-div">
         <div className="check-div">
            <label>
              <Checkbox
                type="checkbox"
                checked={ claimsFilter === '' }
                onChange={ () => this.onFilterChange('') }
              />
              &nbsp; All
            </label>
            <label>
              <Checkbox
                type="checkbox"
                checked={ claimsFilter === 'claimed' }
                onChange={ () => this.onFilterChange('claimed') }

              />
              &nbsp; Claimed
            </label>
            <label>
              <Checkbox
                type="checkbox"
                checked={ claimsFilter === 'unclaimed' }
                onChange={ () => this.onFilterChange('unclaimed') }
              />
              &nbsp; Unclaimed
            </label>
          </div>
          <Select
            placeholder=""
            onChange={ value => this.setState({ type: value.label }) }
            defaultValue={defaultOptions}
            options={options} />
        </div>
       {isLoading && <Loader
          className="large-loader"
          type="Puff"
          color="#ffffff"
          height={ 100 }
          width={ 100 }/>
        }
          <Scrollbars style={ { height: 508, width: 'auto' } }
            className="scroll-m"
            renderTrackHorizontal={ props => <div { ...props } className="track-horizontal"/> }
            renderTrackVertical={ props => <div { ...props } className="track-vertical"/> }>
            <div className="row no-gutters accumulation-mobile">
              {
              filteredClaims.map((claim) => <div className="col-md-12">
                <div className="card">
                  <h5 className="card-title">{
                claim.projectName === 'round 1' 
                ? '(PRE SALES) ' + claim.projectName
                : claim.projectName
              }</h5>
                  <div className="box">
                    <div>
                      <p>TOKEN NAME</p>
                      <span>{claim.tokenName}</span>
                    </div>
                    <div>
                      <p>TOKENS</p>
                      <span>{claim.tokens}</span>
                    </div>
                    <div>
                      <p>Time Left</p>
                      <div className="count">
                        <Countdown date={new Date(claim.releaseTime * 1000).getTime()} />
                      </div> 
                    </div>
                  </div>
                  {
                    claim.status === 'loading' ? 
                      <Loader
                        type="Puff"
                        color="#ffffff"
                        height={ 20 }
                        width={ 20 }
                      />
                    : claim.status === 'claimed' ? 
                      <div className="badge badge-success">Claimed</div>
                    : claim.status === 'unavailable' ? 
                      <div className="badge badge-danger">Wrong Network</div>
                    : !isVerified ? 
                      <Link className="btn" to="/dashboard/kyc" >Complete KYC</Link>
                    : <button disabled={this.isClaimButtonDisabled(claim.releaseTime) || !!claimsInProcess[claim.vestId] } className="btn claim" onClick={() => this.claimVest(claim)}>
                        {
                            !!claimsInProcess[claim._id] ? 
                              <Loader
                                type="Puff"
                                color="#ffffff"
                                height={ 20 }
                                width={ 20 }
                              />
                            : "Claim Now"
                          }
                        </button>
                  }
                </div>
              </div>)}
            </div>
        </Scrollbars>
       </div>
        </>
        )
    }
}

export default withDashboardContext(Accumulation);
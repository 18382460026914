import React, { Component } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import withMasterLayout from './pages/layout/MasterLayout';
import LandingPage from './pages/LandingPage';
import Pool from './pages/Pool';
import ProKing from './pages/ProKing';
import NFT from './pages/NFT';
import NftMarketPlace from './pages/NftMarketPlace';
import WhiteListing from './pages/Whitlisting';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import Dashboard from './pages/Dashboard';
import Contact from './pages/Contact';
import IDO from './pages/IDO';
import ProjectDetail from './pages/ProjectDetail';
import GetAllocation from './components/whitelisting/getAllocation';
import { IntlProvider } from 'react-intl'
import MysteryBoxes from './pages/MysteryBoxes';
import BuyMysteryBox from './pages/BuyMysteryBox';
import DashboardContext from './store/DashboardContext';
class App extends Component {
  
  render() {

    return (
      <IntlProvider locale="en" defaultLocale="en">
        <ToastContainer key="toasts" autoClose={ 3500 } hideProgressBar />
        <BrowserRouter>
          {/* <ScrollToTop /> */}
          <Switch>
            <Route path="/" component={ LandingPage } exact />
            <Route path="/home" component={ LandingPage } exact />
            <Route path="/pro-king" component={ withMasterLayout(ProKing) } exact />
            <Route path="/project-detail/:id" component={ withMasterLayout(ProjectDetail) } exact />
            <Route path="/allocation/:id" component={ withMasterLayout(GetAllocation) } exact />
            <Route path="/nft" component={ withMasterLayout(NFT) } exact />
            <Route path="/ido" component={ withMasterLayout(IDO) } exact />
            <Route path="/nft-market" component={ withMasterLayout(NftMarketPlace) } exact />
            <Route path="/white-list/:id" component={ withMasterLayout(WhiteListing) } exact />
            <Route path="/pool" component={ withMasterLayout(Pool) } exact />
            <Route
              path="/dashboard" 
              component={
                withMasterLayout(
                  () =>  
                    <DashboardContext > 
                      <Dashboard  />
                    </DashboardContext> 
                )
              } 
            />
            <Route path="/mystery-boxes" component={ withMasterLayout(MysteryBoxes) } exact />
            <Route path="/buy-mystery-box/:id/:name" component={ withMasterLayout(BuyMysteryBox) } />
            <Route path="/contact" component={ withMasterLayout(Contact) } exact />
            <Route path="/terms-condition" component={ withMasterLayout(Terms) } exact />
            <Route path="/privacy-policy" component={ withMasterLayout(PrivacyPolicy) } exact />
          </Switch>
        </BrowserRouter>
      </IntlProvider>
    )
  }
}


export default App;


import React, {Component} from 'react';
import Countdown from 'react-countdown';
import Footer from '../components/footer';
import right from '../images/nftMarket/right.svg';
import left from '../images/nftMarket/left.svg';
import blue from '../images/nftMarket/blue.svg';
import blue2 from '../images/nftMarket/blue2.svg';
import card from '../images/nftMarket/card.svg';
import card2 from '../images/nftMarket/card2.svg';
import card3 from '../images/nftMarket/card3.svg';
import card4 from '../images/nftMarket/card4.svg';
import artist from '../images/nftMarket/artist.svg';
import artist2 from '../images/nftMarket/artist2.svg';
import artist3 from '../images/nftMarket/artist3.svg';
import artist4 from '../images/nftMarket/artist4.svg';
import person2 from '../images/nftMarket/person2.svg';
import person3 from '../images/nftMarket/person3.svg';
import person4 from '../images/nftMarket/person4.svg';
import person from '../images/nftMarket/person.svg';
import red from '../images/nftMarket/red.svg';
import yellow from '../images/nftMarket/yellow.svg';
import logo from '../images/logo.png';
import video from '../images/nftMarket/video.mp4'
import videoMobile from '../images/nftMarket/mobile-video.mp4'
import nft from '../images/nftMarket/nft.svg'
// import Button from  '../components/Button';
// import Carousel from '../components/NFTMarket/carousel';

class NftMarketPlace extends Component {
  
  state = {
    activeItemIndex: 0,
    width: '',
    array : [{image: card, title: 'Cryptopunk #6965', detail: 'Explicabo id corrupti odio fugiat nihil id labore. Qui repudiandae mollitia.',
     person, name:'Garret Grant', email: 'Pearlie.Halvorson55@gmail.com', price: 201, networkImg: blue,
     network:'Tezos', date:'March 22, 2021'  },
    {image: card2, title: 'LeBron James "Cosmic" Dunk #29', detail: 'Explicabo id corrupti odio fugiat nihil id labore. Qui repudiandae mollitia.',
     person: person2, name:'Mariana Gleason', email: 'Finn_Flatley@gmail.com', price: 201, network:'Binance Smart Chain' ,
     networkImg: yellow ,date:'March 22, 2021' },
    {image: card3, title: 'Rick and Morty “The Best I Could Do”', detail: 'Explicabo id corrupti odio fugiat nihil id labore. Qui repudiandae mollitia.',
     person: person3, name:'Pearl Cassin', email: 'Edmund_Prohaska@yahoo.com', price: 201, network: 'Tron',
     networkImg: red, date:'March 22, 2021'  },
    {image: card4, title: 'Rick and Morty “The Best I Could Do”', detail: 'Explicabo id corrupti odio fugiat nihil id labore. Qui repudiandae mollitia.',
     person: person4, name:'Jayden Tillman', email: 'Armando_Friesen@yahoo.com', price: 201, network: 'Ethereum' ,
     networkImg:blue2, date:'March 22, 2021'  },
     {image: card2, title: 'LeBron James "Cosmic" Dunk #29', detail: 'Explicabo id corrupti odio fugiat nihil id labore. Qui repudiandae mollitia.',
     person: person2, name:'Mariana Gleason', email: 'Finn_Flatley@gmail.com', price: 201, network:'Binance Smart Chain' ,
     networkImg: yellow ,date:'March 22, 2021' }
  ],
  array2 : [
    {image: artist, title: 'Rick and Morty “The Best I Could Do”', date: 'April 18, 2021',
     detail: 'Explicabo id corrupti odio fugiat nihil id labo.'},
    {image: artist2, title: 'Rick and Morty “The Best I Could Do”', date: 'April 18, 2021',
     detail: 'Explicabo id corrupti odio fugiat nihil id labo.'},
    {image: artist3, title: 'Rick and Morty “The Best I Could Do”', date: 'April 18, 2021',
     detail: 'Explicabo id corrupti odio fugiat nihil id labo.'},
    {image: artist4, title: 'Rick and Morty “The Best I Could Do”', date: 'April 18, 2021',
     detail: 'Explicabo id corrupti odio fugiat nihil id labo.'},
    {image: artist, title: 'Rick and Morty “The Best I Could Do”', date: 'April 18, 2021',
    detail: 'Explicabo id corrupti odio fugiat nihil id labo.'}



  ]
  }

  render() {
    // const {array, array2} = this.state;
    return (
      <div className="nft-market row no-gutters">
        <div className="col-12">
          <video  className="web-video" autoPlay loop>
            <source src={video} type='video/mp4' />
          </video>
          <video  className="mob-video" autoPlay loop>
            <source src={videoMobile} type='video/mp4' />
          </video>
          <div className="row no-gutters top-div">
            <div className="col-md-1"></div>
            <div className="col-lg-4 col-sm-4 mob-div">
              <img className="nft-img" width="100%" src={nft} alt="" />
            </div>

            <div className="col-lg-5 col-sm-5">
              <img className="logo-img" src={logo} alt=""/>
              <h1><img className="lef-img" alt="lef-img" src={right}/> NFT Marketplace <img src={left}
               className="right-img" alt="right-img"/></h1>
              <p>Presenting an upcoming Prostarter NFT Marketplace, a dedicated platform for all digital artists, NFT creators, and collectors to buy, sell, and promote their art.</p>
              <h4>Supported Blockchains</h4>
              <div className="img-div">
                <img src={blue} alt=""/>
                <img src={yellow} alt=""/>
                <img src={red} alt=""/>
                <img src={blue2} alt=""/>
              </div>
              <h4>Coming Soon</h4>
              <div className="count">
                <Countdown date={new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() + 3,  3, 23, 59, 50, 100).getTime()} />
              </div>
              <div className="time">
                <div>
                  <span>DAYS</span>
                </div> 
               <div> 
                <span>HOURS</span> 
               </div>
               <div>
                <span>MINUTES</span> 
              </div> 
               <div>
                <span>SECONDS</span> 
               </div>
             </div> 
              {/* <Button value="Marketplace" />
              <Button value="Create & Sell" /> */}
            </div>
            
            <div className="col-1"></div>
            <div className="col-lg-4 col-sm-4 web-div">
              <img className="nft-img" width="100%" src={nft} alt="" />
            </div>
          </div>
          {/* <div className="row no-gutters last-div">
            <Carousel array={array} title="Top Trending" />
            <Carousel type="seller" array={array} title="Top Sellers" />
            <Carousel type="artist" array={array2} title="Top Artists" />
          </div> */}
        </div>
        <div className="col-md-12">
          <Footer />
        </div>
      </div>
        )
    }
}

export default NftMarketPlace;
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import cross from '../../images/dashboard/cross.svg';
import tick from '../../images/dashboard/tick.svg';
import { FormattedNumber } from 'react-intl'
import {calculateTickets , evaluateIsProking, calculateDaysForTickets} from '../../utils/index';
import { withDashboardContext } from '../../store/DashboardContext';
class Calculator extends Component {
  state = {
    tickets: null,
    prot: '',
    days: '',
    isProking: false,
    daysError: false,
    tokensError: false,
    tooltipOpen: false,
  }
  Calculate = () => {
    const {lockConfigurations} = this.props.dashboardContext;
    const tokens = this.prot.value;
    const days = this.Days.value;
    const daysError = days > lockConfigurations.value.maximumLockDays;
    const tokensError = tokens < lockConfigurations.value.minimumLockAmount;
    const minTokensForProking = lockConfigurations.value.minimumLockTokensForProking;
    const minTicketsForProking = lockConfigurations.value.minimumTicketsForProking
    const tickets = calculateTickets(tokens, days, lockConfigurations.value.ticketsDivisor);
    const isProking = evaluateIsProking({tokens, tickets,  minTokensForProking, minTicketsForProking });
    this.setState({
      isProking,
      tickets,
      daysError,
      tokensError
    })
  }
  toggle = () => this.setState({tooltipOpen: !this.state.tooltipOpen});


  render() {
    const { isProking, tokensError, tickets, days, prot, daysError } = this.state;
    const {lockConfigurations} = this.props.dashboardContext;
    const daysRequiredForATicket = lockConfigurations ? calculateDaysForTickets( lockConfigurations.value.minimumLockAmountForTickets, lockConfigurations.value.ticketsDivisor, 1 ).toString() : '-';
    
    return (
       <div className="calculator row no-gutters">
               <div className="left col-lg-6 col-sm-6">
                 <h5>TICKET CALCULATOR</h5>
                 <p>Number of $PROT</p>
                 <div className="input-div">
                  <input type="number" ref={ (r) => { this.prot = r; } } className="form-control" placeholder="No. of $PROT Tokens" />
                 <p>No. of Lock Days</p>
                  <input type="number" ref={ (r) => { this.Days = r; } } className="form-control" placeholder="No. of Lock Days" />
                  </div>
                 <p>No. of Tickets</p>
                  <div className={`${(!isProking || daysError || tokensError) & !!tickets ? 'error-result': ''} result`}>
                    <h4>{tickets !== null ? Math.floor(tickets) : '-'}</h4>
                  </div>
                  {isProking && !daysError && !tokensError && <div className="message">
                  <img src={tick} alt="" width="10%" />
                  <div>
                    <p className="p1">You are eligible for <span>PRO KING</span></p>
                    <p className="p2">Congratulations! You're eligible for PRO KING, if you lock <span>{prot}</span> PROT for <span>{days}</span> Days!</p>
                  </div>
                 </div>}
                {(!isProking || daysError || tokensError) && !!tickets  && <div className="message">
                  <img src={cross} alt="" width="10%" />
                  <div>
                    <p className="p1">You are not eligible for Pro King!</p>
                   {!isProking && !tokensError && <p className="p2">Opps! Remember you need to lock atleast <span>{lockConfigurations ? <FormattedNumber value={lockConfigurations.value.minimumLockTokensForProking} /> : '-'}</span> PROT Tokens and your total accumulated tickets should be more then <span>{lockConfigurations ? <FormattedNumber value={lockConfigurations.value.minimumTicketsForProking} /> : '-'}</span> to qualify for Pro King!</p>}
                   {tokensError && <p className="p2">Opps! Remember you need to provide atleast <span>{lockConfigurations ? <FormattedNumber value={lockConfigurations.value.minimumLockAmount} /> : '-'}</span> PROT to lock your tokens!</p>}
                   {daysError && <p className="p2">Maximum {lockConfigurations ? <FormattedNumber value={lockConfigurations.value.maximumLockDays} /> : '-'} days allowed to lock.</p>}
                  </div>
                 </div>}
               <button onClick={this.Calculate} className="btn global-btn">Calculate</button>
               </div>
               <div className="right col-lg-6 col-sm-6">
                 <p><span>No of PROT Tokens:</span> The amount of your $PROT holdings you want to lock.</p>
                 <p><span>No of Lock Days:</span> The total number of days you want to lock your $PROT tokens for.</p>
                 <h4>Requirements</h4>
                  <ul>
                    <li>You need to lock minimum {lockConfigurations ? <FormattedNumber value={lockConfigurations.value.minimumLockAmountForTickets} /> : '-'} $PROT for atleast {daysRequiredForATicket} days to have 1 Ticket.</li>
                    <li>To become a PRO King, you need to lock at least {lockConfigurations ? <FormattedNumber value={lockConfigurations.value.minimumLockTokensForProking} /> : '-' } PROT and have at least {lockConfigurations ? <FormattedNumber value={lockConfigurations.value.minimumTicketsForProking} /> : '-'} tickets.</li>
                  </ul>
                  <h4>How Ticket Calculator Works</h4>
                  <ul>
                    <li>Enter the amount of $PROT tokens you want to lock.</li>
                    <li>Enter the number of days you want to lock $PROT tokens for.</li>
                    <li>Click on calculate button to find out how many tickets.</li>
                  </ul>
                  <p>Click here to <Link className="link" to="/dashboard/lock">lock your $PROT tokens</Link>. Also,
                   read <a target="_blank"  className="link" href="https://prostarter.medium.com/what-is-prostarter-lottery-ticket-system-and-how-it-works-23f8e809a16a" rel="noreferrer">Prostarter Lottery Ticket System</a> to know more.</p>
                 </div>
               </div>
             
        )
    }
}

export default withDashboardContext(Calculator);
import React, {Component} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Loader from 'react-loader-spinner';

class Rules extends Component {

  render() {
    const { items, boxesToSell, isLoading, currentBlockChainNetworkId } = this.props;
    return (
      <div className="table-responsive">
      <table className="table table-borderless">
        <thead>
          <tr className=" top-div">
            <th >S.No.</th>
            <th >NFT Items</th>
            <th>NFT Quantity</th>
            <th>Rarity</th>
            <th width="w-25"><span>&nbsp;</span></th>
          </tr>
          
        </thead>
        <tbody>
          {
            currentBlockChainNetworkId && !isLoading && items.map((item,index) =>
              <tr className="sec-div hide">
                <td>{index+1}</td>
                <td>{item.imageUrl && <img src={item.imageUrl} alt="" className="mr-2" />}{item.name}</td>
                <td>{item.quantity}</td>
                <td className="change">{boxesToSell ? (item.quantity/boxesToSell * 100).toFixed(2) : '-' }%</td>
                <td width="w-25"><span>&nbsp;</span></td>
              </tr>
            )
          }
        </tbody>
      </table>
      <Scrollbars style={ { height: 500, width: 'auto' } }
        className="scroll"
        renderTrackHorizontal={ props => <div { ...props } className="track-horizontal"/> }
        renderTrackVertical={ props => <div { ...props } className="track-vertical"/> }>
          <table className="table table-borderless">
            <thead>
              <tr className="hide top-div">
                <th >S.No.</th>
                <th >NFT Items</th>
                <th>NFT Quantity</th>
                <th>Rarity</th>
                <th width="w-25"><span>&nbsp;</span></th>
              </tr>
              
            </thead>
            <tbody>
              {
                isLoading && 
                <tr className="sec-div">
                  <td colSpan="5" className="text-center">
                    <Loader
                      type="Puff"
                      color="#ffffff"
                      className="d-inline mr-1"
                      height={ 50 }
                      width={ 50 }
                    />
                  </td>
                </tr>
              }
              {
                currentBlockChainNetworkId && !isLoading && items.map((item,index) =>
                  <tr className="sec-div">
                    <td>{index+1}</td>
                    <td>{item.imageUrl && <img src={item.imageUrl} alt="" className="mr-2" />}{item.name}</td>
                    <td>{item.quantity}</td>
                    <td className="change">{boxesToSell ? (item.quantity/boxesToSell * 100).toFixed(2) : '-' }%</td>
                    <td width="w-25"><span>&nbsp;</span></td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </Scrollbars>
    </div>       
        )
    }
}

export default Rules;
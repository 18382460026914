import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Card from './card';
import clock from '../../images/landing/clock.svg';
import Loader from 'react-loader-spinner';
class Pool extends Component {
  
  state = {
    projects: [],
    loader: false
  }
  componentDidMount() {
    this.poolProjects();
  }

  poolProjects = async () => {
    this.setState({loader: true});
    try {
      const response = await axios.get(`/ido/list`);
      this.setState({
        projects: response.data.results,
        loader: false
      })
    } catch (e) {
      this.setState({loader: false});
      toast.error('Network Error!')
    }
  }
  render() {
    const {projects, loader} = this.state;
    return (
      <div className="col-12 pools">
        <h3><img src={clock} className="mr-2" alt=""/>UPCOMING POOLS</h3>
        <div className="row sub-pool">
          {loader ? 
            <Loader
              className="loader"
              type="Puff"
              color="#ffffff"
              height={ 80 }
              width={ 80 }
            /> 
            :
            projects.map(project => (
              <Card key={project._id} type='pool' status='pool' project={project} />
            ))
          }
      </div>
    </div>
        )
    }
}

export default withRouter(Pool);

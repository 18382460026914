import React, {Component} from 'react';
import Footer from '../components/footer';
import box from '../images/mystery/box.svg';
import { MysteryBoxes as MysteryBoxesArr } from '../data';
import binance from '../images/nftMarket/yellow.svg';
import ethereum from '../images/nftMarket/blue.svg';
import { getIdoCurrency } from '../utils';
import { FormattedNumber } from 'react-intl';

class MysteryBoxes extends Component {

  state = {
    array: []
  }
  
  componentDidMount() {
    this.setState({
      array: MysteryBoxesArr.filter((mysteryBox) => ( process.env.REACT_APP_ENV === 'local' && mysteryBox.env === 'dev' ) || mysteryBox.env  === process.env.REACT_APP_ENV)
    }) 
  }

  render() {
    const {array} = this.state;
    return (
      <div className="mystery row no-gutters" style={{scrollBehavior:'smooth'}}>
        <div className="col-12 first-div">
          <div className="row no-gutters">
          <div className="col-lg-1"></div>
          <div className="col-lg-5">
            <div className="d-flex justify-content-center flex-column align-items-center h-100">
              <h1>MYSTERY BOXES</h1>
              <p>Introducing Mystery Boxes on Prostarter, aiming to bring NFT collectibles of different projects for the 
                Prostarter community to explore, access, and grab amazing NFTs through NFT mystery boxes.</p>
              <a className="btn first-btn global-btn" href="#mystery-boxes">Explore Mystery Boxes</a>
            </div>
          </div>
          <div className="col-lg-5">
            <img src={box} alt=""/>
          </div>
          <div className="col-lg-1"></div>
          </div>
        </div>
       <div className="col-12" id="mystery-boxes" >
         <h2>UPCOMING MYSTERY BOXES</h2>
         <div className="img-box">
           {array.map(arr=>(
             <div onClick={()=> this.props.history.push('/buy-mystery-box/' + arr.shortId + '/participation-rules')} className="card" >
             <img className="card-img-top" src={arr.image} alt=""/>
             <div className="card-body">
               <h5 className="card-title">{arr.name}</h5>
               <p className="card-text">{arr.detail}</p>
               <div className="price-div">
                 <span>Price</span>
                 <div>
                   <h5>
                      {(arr.blockChainNetworkId === "56" || arr.blockChainNetworkId === "97") && <img width="20" className="mr-2" src={binance} alt=""/>}
                      {(arr.blockChainNetworkId === "1" || arr.blockChainNetworkId === "3" || arr.blockChainNetworkId === "42") &&
                      <img width="20" className="mr-2" src={ethereum} alt=""/>}
                      {arr.price} {getIdoCurrency('', arr.blockChainNetworkId)}
                    </h5>
                   {/* eslint-disable-next-line  react/style-prop-object */}
                   <p>= <FormattedNumber maximumFractionDigits={2} currency="USD" style="currency" value={ arr.usd || 0} /></p>
                 </div>
               </div>
             </div>
           </div>
           ))}
         </div>
       </div>
        <div className="col-md-12">
          <Footer />
        </div>
      </div>
        )
    }
}

export default MysteryBoxes;
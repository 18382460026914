import React, {Component} from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import emoji from '../../images/landing/emoji.svg';
import uni from '../../images/landing/uni.svg';
import hotbit from '../../images/landing/hotbit.svg';
import protbit from '../../images/landing/probit.svg';
import probitMobile from '../../images/landing/probitMobile.svg';
import hotbitMobile from '../../images/landing/hotbitMobile.svg';
import uniMobile from '../../images/landing/uniMobile.svg';

class EmailSubscription extends Component {
  
  state = {
    isDisable : false
  }

  keyHandler = (e) => {
    if (e.key === 'Enter') {
      this.subscibe();
    }
  }

  subscibe = async () => {
    this.setState({isDisable: true});
   try {
    const response = await axios.post(`/marketing/email/subscribe?email=${this.Email.value}`);
    this.Email.value = ''
    this.setState({isDisable: false});
    toast.success(response.data.message);
   } 
   catch (e){
    this.setState({isDisable: false});
     toast.error(e.response.data.message);
   }
  }
 
  render() {
    const {isDisable} = this.state;
    return (
    <>
      <div className="buy-token col-12">
        <div className="sub-token">
          <img className="emoji" src={emoji} alt="" />
          <h1>Buy <span>$PROT</span></h1>
          <p>Prostarter is a community-centric and cross-chain IDO launchpad offering projects
             fundraising, token sales, NFT marketplace, and many other opportunities 
            for the community and $PROT holders. Buy $PROT today before it's too late.</p>
          <div className="trad-div">
            <div>
             <a target="_blank" rel="noreferrer" href="https://app.uniswap.org/#/swap?outputCurrency=0x2341dd0a96a0dab62aa1efb93d59ff7f3bdb8932&use=V2"><img src={uni} alt=""/></a>
            </div>
            <div>
              <a target="_blank" rel="noreferrer" href="https://www.hotbit.io/exchange?symbol=PROT_USDT">
                <img className="hot-img" src={hotbit} alt=""/>
              </a>
            </div>
            <div>
              <a target="_blank" rel="noreferrer" href=" https://www.probit.com/app/exchange/PROT-USDT">
                <img className="pro-img" src={protbit} alt="" />
              </a>
            </div>
        </div>
        <a target="_blank" rel="noreferrer" href="https://app.uniswap.org/#/swap?outputCurrency=0x2341dd0a96a0dab62aa1efb93d59ff7f3bdb8932&use=V2">
          <img className="mobile-pic" src={uniMobile} alt=""/>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.hotbit.io/exchange?symbol=PROT_USDT">
          <img className="mobile-pic" src={hotbitMobile} alt=""/>
        </a>
        <a target="_blank" rel="noreferrer" href=" https://www.probit.com/app/exchange/PROT-USDT">
        <img className="mobile-pic" src={probitMobile} alt=""/>
        </a>
      </div>
      </div>
      <div className="col-12 notified-div">
          <div className="sub-notify">
            <h1>Get <span>notified</span> about new <span>pools</span>.</h1>
            <p>Subscribe to Prostarter Newsletter and be the first to be notified about new IDO projects!</p>
            <h4>Get <span>email</span> notification!</h4>
            <div className="input-group mb-3">
                <input placeholder="Enter Email Address" ref={r => this.Email = r} type="text" 
                onKeyDown={this.keyHandler}  className="form-control" />
                <div className="input-group-prepend">
                  <button onClick={this.subscibe} disabled={isDisable} className="btn search-btn" type="button">Subscribe</button>
                </div>
            </div>
         </div>

        </div>
          
      </>
    )
    }
}

export default EmailSubscription;
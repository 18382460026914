import React, {Component} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import Footer from '../components/footer';
class PrivacyPolicy extends Component {

  render() {
    return (
      <div className="privacy row no-gutters">
        <Scrollbars style={ { height: 560, width: '80%', margin: '0 auto' } }
              className="scroll"
              renderTrackHorizontal={ props => <div { ...props } className="track-horizontal"/> }
              renderTrackVertical={ props => <div { ...props } className="track-vertical"/> }>
          <div className="box">
            <h5>Privacy Policy</h5>
            <p>Herein is provided the privacy policy of Prostarter (owner and operator of Prostarter.io) This Privacy Policy describes how Prostarter.io will manage end user’s personal information which we collect as a result of your use of Prostarter.io, your use of our websites located at https://Prostarter.io and any pages or websites under the 'Prostarter' brand including any tablet or mobile phone applications that are owned and/or operated by us or whenever we otherwise deal with you. Prostarter is committed to ensuring that the personal information we collect about you is protected and is used, stored, and disclosed in accordance with the Privacy Act and this Privacy Policy.
            </p>
            <h5>Website Use</h5>
            <p>In using the website, you agree to be bound by the terms of this Privacy Policy. Prostarter.io may review the Privacy Policy periodically and reserves the right to change the Privacy Policy at any time at its discretion by posting such changes on the website. Changes will be effective immediately when posted on the website. The end users’ continued use of the website following posting will constitute your acceptance of those changes.
            </p>
            <p>This Privacy Policy will not apply to websites or apps that are linked to the website and over which Prostarter.io has no control.
            </p>
            <h5>Personal Information</h5>
            <p>The kinds of Personal Information that Prostarter.io may collect from you is:</p>
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Personally submitted preferences</li>
              <li>Location data</li>
              <li>IP address</li>
            </ul>
            <h5>Collection Purposes</h5>
            <ul>
              <li>If you wish to subscribe to Prostarter.io's weekly newsletter or other marketing communications.</li>
              <li>To update you on the technical progress of Prostarter.</li>
              <li>To provide services to you as a User. </li>
              <li>To identify you as a User for security purposes and to comply with our legal obligations.</li>
              <li>To maintain your User account. </li>
              <li>To upgrade and enhance your experience within the website or over the telephone, or to tailor or develop information, services, or products to suit your needs which may include market research and conducting promotions.</li>
              <li>To tell you about our products or services that we think may be of interest to you by communicating with you via email, SMS, or telephone.</li>
              <li>To create aggregate data about Users through demographic profiling, statistical analysis of the database to provide to potential and existing Prostarter holders, and to allow for the more efficient operation of Prostarter.io’s business.</li>
              <li>To comply with Prostarter.io’s legal and statutory obligations.</li>
              <li>For taking appropriate action if Prostarter.io has reason to suspect that unlawful activity or misconduct of a serious nature has been, is being, or maybe engaged in that relates to our functions and activities. </li>
              <li>To establish, exercise, or defend any legal claims.</li>
              <li>To manage job applications</li>
            </ul>
            <p>You may choose to deal with us on an anonymous basis or to use a pseudonym unless it is not practicable for us to deal with individuals who we cannot identify or we are required by law to only deal with identified individuals. Also, if you do not provide Prostarter.io with the Personal Information we request, we may be unable to process your request to become a User, provide you with our services or respond to your inquiry or complaint. Examples of Personal Information that may be collected by Prostarter.io include your name, email address, telephone numbers, date of birth, other verification information such as your Service usage activities. By becoming a User or otherwise choosing to provide Prostarter.io with Personal Information you consent to Prostarter.io collecting, using, and disclosing your Personal Information for the above purposes.</p>
            <h5>Direct Marketing and Opting Out</h5>
            <p>Occasionally we may use your Personal Information to inform you about Prostarter.io's products or services or about promotional activities which Prostarter.io believes may be of interest or of benefit to you. We may do this via email, SMS, telephone, or mail. If you no longer wish to receive marketing or promotional material from Prostarter.io at all or in any particular form, you may contact us at any time by email to media@Prostarter.io with your request with which we will comply as soon as is practical.
            </p>
            <p>From time to time we may contact you in relation to the management and administration of your Prostarter.io account. These communications can be via any of the modes of contact recorded when registering as a User. Such communication does not affect your opt-in or opt-out status for direct marketing communications.
            </p>
            <h5> Management of your Personal Information</h5>
            <p>Prostarter.io will take all reasonable steps and measures to ensure that the Personal Information which it collects, uses, or discloses is correct and is stored in a secure environment that is accessed only by authorized persons.
            </p>
            <p>The team at Prostarter.io will destroy or permanently de-identify the Personal Information we hold when it is no longer required for any purpose permitted under the APPs including our legal or operational obligations.
            </p>
            <h5>Security of Personal Information</h5>
            <p>You acknowledge that no data transmission over the Internet is totally secure. Accordingly, Prostarter.io does not warrant the security of any information which you transmit to it. Any information which you transmit to Prostarter.io is transmitted at your own risk. However, once Prostarter.io receives your transmission, Prostarter.io will take reasonable steps to protect your Personal Information from misuse, loss, and unauthorized access, modification, and disclosure including by using password-protected systems and databases and Secure Socket Layer (SSL) technology.
            </p>
            <p>Prostarter.io’s employees, agents, and contractors are required to maintain the confidentiality of Users’ Personal Information and trading behavior.
            </p>
            <p>The information posted on bulletin boards or communicated within a social media environment (for example, Facebook, Twitter, Chat Rooms) becomes public information. Prostarter.io cannot guarantee the security of this type of disclosed information.
            </p>
            <p>We take seriously the responsibility to exclude children from access to our services. We will not accept their information for the purposes of accessing or using the Service. It is, however, ultimately the responsibility of parents or guardians to monitor their children’s Internet activities including where appropriate by using Internet screening software.
            </p>
            <p>Remember to always log out of your account when you have completed your time on the website. This is particularly important if you share a computer with another person. You are responsible for the security of and access to your own computer, mobile device, or any other handset used to access the website.
            </p>
            <p>Ultimately, you are solely responsible for maintaining the secrecy of your username, password, and any account information. Please be careful whenever using the Internet and our website.
            </p>
            <h5>Access to Personal Information</h5>
            <p>You may access the Personal Information collected by Prostarter.io by contacting us at media@Prostarter.io. We will give you access to your Personal Information in the manner requested if that is possible and within a reasonable period. If we refuse your request or cannot give you access in the manner you have requested, we will do what we can to meet your requirements by other means. We may not be required to give you access to your Personal Information in certain circumstances which are set out in the APPs including where it may have an unreasonable impact on other individual's privacy. If we refuse access for such reasons, we will advise you in writing of the refusal and our reasons and the complaint mechanisms available to you.
            </p>
            <h5>Use of Website</h5>
            <p>By using this website, you agree to Prostarter.io collecting and processing your Personal Information on the basis explained in this Privacy Policy.
            </p>
            <h5>Contact Prostarter</h5>
            <p>If you have any queries, requests for access or correction, or complaints relating to the handling of your personal information, please contact us by email or contact us on any of our accounts given below.  
            </p>
            <p>Email: media@Prostarter.io</p>
            <p>Discussion Group: t.me/Prostarter</p>
            <p >Announcements Channel: t.me/prot_ann</p>
            <p>Twitter: Twitter.com/ProtOfficial</p>
            <p>Medium: Prostarter.medium.com</p>
            <p>Facebook: Facebook.com/Prostarter</p>
            <h5>Amendments in Privacy Policy</h5>
            <p>Prostarter.io may review, change and update this Privacy Policy to reflect our current practices and obligations. We will publish our current Privacy Policy on our website at Prostarter.io and the changes will take effect at the time of publishing. You should review this privacy policy regularly and remain familiar with its terms.</p>
          </div>
        </Scrollbars>
        <div className="col-md-12">
          <Footer />
        </div>
      </div>
        )
    }
}

export default PrivacyPolicy;
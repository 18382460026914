/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react';
import print from '../../images/dashboard/print.svg';
import {SynapsClient} from '@synaps-io/synaps-client-sdk-js'
import pass from '../../images/dashboard/pass.svg';          
import synaps from '../../images/dashboard/synaps.svg';          
import { withDashboardContext } from '../../store/DashboardContext';
const KYC_ENDPOINT = process.env.REACT_APP_KYC_ENDPOINT;
class Customer extends Component {

  initSynaps(sessionId) {
    const Synap = new SynapsClient( sessionId, 'workflow');
    Synap.init({
      type: "modal",
      element_id: "synaps-btn"
    });
  }
  
  componentDidMount() {
    this.props.dashboardContext.sessionId && this.initSynaps(this.props.dashboardContext.sessionId)
  }

  componentDidUpdate (prevProps) {
    if( 
        prevProps.dashboardContext && 
        this.props.dashboardContext && 
        (prevProps.dashboardContext.sessionId !== 
        this.props.dashboardContext.sessionId)
    ) {
      this.initSynaps(this.props.dashboardContext.sessionId)
    }
  }

  render() {
    const { dashboardContext: { userStatus, account } } = this.props;
    return (
      <div className="customer-div">
        {
          userStatus === 'unidentified' &&
          <>
            <div className="kyc-div">
              <p>Welcome to Prostarter Dashboard</p>
              <p className="paragraph">Connect Wallet to process your KYC.</p>
            </div>
          </>
        }
        {
          userStatus === 'prostarter_user' &&
          <>
            <div className="kyc-div">
              <p>KYC VERIFICATION</p>
              <p className="paragraph">Click on the button below to get your KYC verified through a professional and secured eKYC system Blockpass</p>
              <a className="btn" rel="noreferrer" target="_blank" href={ KYC_ENDPOINT + '&refId=' + account }>
                <img src={pass} alt="" width="16%" /> Verify  with Blockpass</a>
              <div className="hr-div">
              <hr />
              <span>OR</span>
              <hr />
            </div>
              <a className="btn synaps" id="synaps-btn" ><img src={synaps} width="10%" alt=""/> Verify with Synaps</a>
            </div>
            <img className="print-img" src={print} alt="" width="23%" />
          </>
        }
        {
          userStatus === 'non_prostarter_user' &&
          <>
            <div className="kyc-div">
                <p>Welcome to Prostarter Dashboard</p>
                <p className="paragraph">KYC is currently not available for you</p>
            </div>
          </>
        }
        {
          userStatus === 'not_verified' &&
          <>
            <div className="kyc-div">
                <p>Welcome to Prostarter Dashboard</p>
                <p className="paragraph">KYC verification is pending.</p>
            </div>
          </>
        }
        {
          userStatus === 'verified' &&
          <>
            <div className="kyc-div">
              <p>Welcome to Prostarter Dashboard</p>
              <p className="paragraph">You have done KYC successfully.</p>
            </div>
          </>
        }
      </div>
      )
    }
}

export default withDashboardContext(Customer);
import React from 'react';
import axios from 'axios';
import { getBlockChainNetworkId, getProtBalance, getUserAddress } from '../web3Helper';
import { StakingService } from '../services';
import { toast } from 'react-toastify';
const Context = React.createContext();

export default class DashboardContext extends React.Component {

	state = {
		id: null,
		account: null,
		sessionId: '',
		lockConfiguration: null,
		isProking: false,
		isVerified: false,
		totalProt: 0,
    totalHolding: 0,
    totalTokens: 0,
    locked: 0,
		tickets: 0,
    blockChainNetworkId: 0,
		userStatus: 'unidentified',
	}

	componentDidMount () {
		getBlockChainNetworkId()
    .then(blockChainNetworkId => 
      this.setState({
        blockChainNetworkId,
      })
    )
    .catch(e=> console.log(e))
    .finally(() => {
			this.initBalances();
			this.initUserData();
		});
		this.getLockConfig()
	}

	getLockConfig = async () => {
		try {
			const lockConfigurations = await axios.get('/config/constants');
			this.setState({
				lockConfigurations: lockConfigurations
					.data
					.configurations
					.find( config => config.name === "lockConstants" )
			})
		} catch (e) {
			console.log(e)
		}
	}

	initBalances = () => {
		const stakingService = new StakingService();

    Promise.all([
      stakingService.getStakeInfo(),
      getProtBalance()
    ]).then(([
      stakingResponse,
      protBalance
    ]) => {
      this.setState({ 
        totalProt: protBalance,
        totalHolding: stakingResponse.holding,
        totalTokens: protBalance + stakingResponse.holding + stakingResponse.locked,
        locked: stakingResponse.locked
      })
    }).catch(err=> {
      // toast.error(typeof err === 'string' ? err : err.message);
    })
	}

	initUserData = () => {
		getUserAddress()
			.then( async (account) => {
				if( !!account ) {
					try {
						const userResp = await axios.get(`/user/${account}`);
						let state = {
							account,
						};
						if( !!userResp.data && !!userResp.data.user ) {
							state['userStatus'] = !!userResp.data.user.isVerified 
								? 'verified' 
								: !!userResp.data.hasClaims ? 'prostarter_user'
								: 'non_prostarter_user';
							state.isVerified = userResp.data.user.isVerified;
							state.isProking = userResp.data.isProking;
							state.tickets = userResp.data.tickets;
						} else if( !!userResp.data && !!userResp.data.hasClaims ) {
							state['userStatus'] = 'prostarter_user';
						} else {
							state['userStatus'] = 'non_prostarter_user';
						}
						this.setState({ 
							...state
						},() => {
							this.getSynaps(account);
						})
					} catch(e){
						// toast.error('Server Error.')
					}
				}
			})
	}

	getSynaps = async (userAddress) => {
		try {
			const response = await axios.get(`user/synaps/session/${userAddress}`)
			this.setState({
				sessionId:response.data.sessionId
			});
		} catch (e) {
			console.log(e)
		}
	}
	
	render() {
		let {
			children 
		} = this.props;
		return (
			<Context.Provider 
				value={{ 
					...this.state, 
					initBalances: this.initBalances,
				}}
			>
				{children}
			</Context.Provider>
		)
	}
}

export const withDashboardContext = ChildComponent => props => {
  return (
    <Context.Consumer>
      {context => <ChildComponent {...props} dashboardContext={context} />}
    </Context.Consumer>
  );
};
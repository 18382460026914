/* eslint-disable class-methods-use-this */
import React from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import hotbit from '../../images/landing/hotbitModal.svg';
import probitModal from '../../images/landing/proModal.svg';
import uniModal from '../../images/landing/uniModal.svg';
import probitMobile from '../../images/landing/probitMobile.svg';
import hotbitMobile from '../../images/landing/hotbitMobile.svg';
import uniMobile from '../../images/landing/uniMobile.svg';
const SaleModal = ({toggle, onCloseModal})=> {

    return (
      <Modal open={ toggle } onClose={onCloseModal}  classNames={ {
          overlay: 'customOverlay',
          modal: 'sale-modal'  
        } } center>
            <button onClick={onCloseModal} className="cross">X</button>
            <h3>Select an Exchange</h3>
            <p>$PROT is available on the following exchanges. Please select an exchange to buy $PROT.</p>
            <div className="web">
              <a target="_blank" rel="noreferrer" href="https://app.uniswap.org/#/swap?outputCurrency=0x2341dd0a96a0dab62aa1efb93d59ff7f3bdb8932&use=V2">
                <img src={uniModal} alt="" />
              </a>
              <a target="_blank" rel="noreferrer" href=" https://www.probit.com/app/exchange/PROT-USDT">
                <img src={probitModal} alt="" />
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.hotbit.io/exchange?symbol=PROT_USDT">
                <img src={hotbit} alt="" />
              </a>
            </div>
            <div className="mobile">
              <a target="_blank" rel="noreferrer" href="https://app.uniswap.org/#/swap?outputCurrency=0x2341dd0a96a0dab62aa1efb93d59ff7f3bdb8932&use=V2">
                <img src={uniMobile} alt="" />
              </a>
              <a target="_blank" rel="noreferrer" href=" https://www.probit.com/app/exchange/PROT-USDT">
                <img src={probitMobile} alt="" />
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.hotbit.io/exchange?symbol=PROT_USDT">
                <img src={hotbitMobile} alt="" />
              </a>
            </div> 
      </Modal>
                
    )
}

export default SaleModal;
/* eslint-disable class-methods-use-this */
import React from 'react';
import {withRouter} from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Scrollbars } from 'react-custom-scrollbars';

const TermsModal = ({toggle, onCloseModal, onAgreedToTerms, history, match})=> {

  const agreeTerms = () => {
    history.push(`/allocation/${match.params.id}`);
    onCloseModal();
    onAgreedToTerms && onAgreedToTerms();
  }
    return (
      <Modal open={ toggle }  classNames={ {
          overlay: 'customOverlay',
          modal: 'terms-modal'  
        } } center>
          <button onClick={onCloseModal} className="cross">X</button>
          <h3>DISCLAIMER</h3>
          <Scrollbars style={ { height: 200, width: '100%' } }
            className="scroll"
            renderTrackHorizontal={ props => <div { ...props } className="track-horizontal"/> }
            renderTrackVertical={ props => <div { ...props } className="track-vertical"/> }>
            <p>
              The buyer acknowledges understanding and awareness that the crypto-active markets are decentralized and unregulated markets. The tokens issued and the services offered by Prostarter, therefore, concern unregulated markets which are not governed by any specific European framework. In other words, unlike "traditional" financial assets, the Central Bank cannot take corrective measures that could protect the value of crypto-assets in the event of a crisis or issue more assets on the markets.
            </p>
            <p>
              The buyer, therefore, understands that he should not purchase tokens unless he has the necessary knowledge and expertise, understands the characteristics of the token, the whitepaper, and his exposure to risk. The buyer of crypto tokens and the user of the services offered by Prostarter understands and accepts that Prostarter and its whitepaper are in no way intended to substitute the buyer and user in the knowledge they must hold for the purchase of tokens.
            </p>
          </Scrollbars>
          <button onClick={agreeTerms} className="btn global-btn">Accept & Continue!</button>
          <button onClick={onCloseModal} className="btn">No, I disagree!</button>

      </Modal>
                
    )
}

export default withRouter(TermsModal);
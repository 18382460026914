/* eslint-disable no-self-assign */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import logo from '../images/logo.png';
import facebook from '../images/facebooknav.svg';
import msg from '../images/msgnav.svg';
import twitter from '../images/twitternav.svg';
import social from '../images/socialnav.svg';
import email from '../images/emailnav.svg';
import meta from '../images/meta.svg';
import king from '../images/home/king.svg';
import getWeb3 from '../web3Utils';
import { getUserAddress } from '../web3Helper';
import { toast } from 'react-toastify';
import copy from '../images/home/copy.svg';
import myster from '../images/myster.svg';
import copyBlack from '../images/home/copy-black.svg';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import binnace from '../images/keymile/binance.svg';
import etherum from '../images/keymile/etherum.svg';
import polygon from '../images/keymile/polygn.svg';
// import fire from '../images/nftMarket/fire.png'
// import rocket from '../images/landing/rocket.svg';
// import Select from 'react-select';
class Navbar extends Component {
  
  constructor() {
    super();
    this.state = {
      activeAccountAddress: '',
      activeCopyImage: copy,
      option: [ {value: 'Ethereum', label: <div className="network"><img  src={etherum} alt=""/> Etherum</div> },
       {value: 'Binance', label:  <div className="network"><img src={binnace} alt=""/> Binance SmartChain</div> },
       {value: 'Polgon', label: <div className="network"><img src={polygon} alt=""/> Polygon</div>},],
      defaultOptions: [ {value: 'Ethereum', label: <div className="network"><img src={etherum} alt=""/> Etherum</div> }],
    }
  }
  
  connectToNetwork = () => {
    if( !this.state.activeAccountAddress ) {
      getWeb3().then( async (web3) => {
        const accounts = await web3.eth.getAccounts();
        this.setState({
          activeAccountAddress: accounts[0]
        })
        window.location.href = window.location.href;
      });
    }
  }

  componentDidMount() {
    getUserAddress().then((account) => this.setState({ activeAccountAddress: account }))
  }


  copyText = ()=> {
    this.setState({copied: true});
    toast.success("Address Copy to Clipboard");
  }

  // This is not the right mechanism, however to properly change this everwhere requires some development time, the svg should be used as a component such that it can be programatically controlled.
  handleHover = () => this.setState({ activeCopyImage: copyBlack })
  handleHoverEnd = () => this.setState({ activeCopyImage: copy })

  render() {
   const { history, bar } = this.props;
   const path = history.location.pathname.split('/');
  //  const {option, defaultOptions} = this.state; // this is required for react-select

    return (
      <>
     {bar === "home" && <nav className="navbar bar  fixed-top navbar-toggleable-md navbar-expand-lg scrolling-navbar double-nav">
        <div className="first-div">
          <div className="animated">
            <p> {/* Beware of scammers. Make sure the URL <a href="https://prostarter.io/" target="_blank" rel="noreferrer" >Prostarter.io</a> */} </p>
          </div>
          <div className="img-div">
            <a target="_blank" rel="noreferrer" href="https://prostarter.medium.com"><img src={social} alt="" /></a>
            <a target="_blank" rel="noreferrer" href="https://twitter.com/ProtOfficial"> <img src={twitter} alt="" /></a>
            <a target="_blank" rel="noreferrer" href="https://t.me/Prostarter"> <img src={msg} alt="" /></a>
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Prostarter/"><img src={facebook} alt="" /></a>
            <a target="_blank" rel="noreferrer" href="mailto: hello@prostarter.io"> <img src={email} alt="" /></a>
          </div>
         </div>
      </nav>}
      <nav className={`${bar === "home" ?
       "sec-nav navbar dark second-navbar fixed-top navbar-toggleable-md navbar-expand-lg navbar-light scrolling-navbar double-nav"
        : "navbar dark sticky-top navbar-fixed-top navbar-expand-lg navbar-light"} `}>
        <Link className="navbar-brand" to="/">
          <img src={ logo } alt="logo" />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" 
        data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" 
        aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
           <ul className="nav left  navbar-nav">
            {/* <li className="nav-item">
              <Link className={`${path[1] === 'nft'? 'active' : ''}`} to="/nft"><img src={fire} alt="" /> NFT Launchpad </Link>
            </li>  */}
            {/* <li className="nav-item line">
              |
            </li> */}
          {/* <li className="nav-item">
              <Link className={`${path[1] === 'nft-market'? 'active' : ''}`} to="/nft-market"><img className="mobile-img" width="10%" alt="mobileimg
              " src={nftMarkt}/><span>NFT Marketplace</span></Link>
          </li> */}
           
          {/* <li className="nav-item line">
              |
            </li> */}
          <li className="nav-item">
              <Link className={`${path[1] === 'mystery-boxes'? 'active' : ''} `} to="/mystery-boxes"> <img alt="myster" src={myster}/><span>NFT Mystery Boxes</span></Link>
          </li>
          <li className="nav-item line">
              |
            </li>
          <li className="nav-item meta">
              <Link disabled className={`${path[1] === ''? '' : ''}  `} > <img alt="meta" src={meta}/><span>Metaverse</span></Link>
              <span className="coming">Coming soon</span>
          </li>
          <li className="nav-item line">
              |
            </li>
           
            <li className="nav-item">
              <Link className={`${path[1] === 'pro-king'? 'active' : ''} `} to="/pro-king"> <img alt="king" src={king}/><span>Pro King</span></Link>
          </li>
          </ul>
          <ul className="right nav navbar-nav ml-auto">
              <li className="nav-item">
                  <Link to="/dashboard/accumulation" className={`${path[1] === 'dashboard'? 'active' : ''} global-btn btn `} >Dashboard</Link>
              </li>
              <li className="nav-item">
                { this.state.activeAccountAddress ? 
                  <button 
                  onMouseEnter={this.handleHover}
                  onMouseLeave={this.handleHoverEnd}
                  onTouchStart={this.handleHover}
                  onTouchEnd={this.handleHoverEnd}
                  
                    className={`${path[1] === 'connect'? 'active' : ''} btn address  disable`} onClick={this.connectToNetwork} >
                    { this.state.activeAccountAddress.substr(0,6) + '...'  + this.state.activeAccountAddress.substr(-4) } 
                    <CopyToClipboard
                      text={this.state.activeAccountAddress}
                      onCopy={this.copyText}>
                      <img width="15" className="ml-2 copy" src={this.state.activeCopyImage} alt="" />
                    </CopyToClipboard>
                  </button>
                  :
                  <button className={`${path[1] === 'connect'? 'active' : ''} btn address disable`} onClick={this.connectToNetwork} >
                    Connect Wallet 
                  </button>
                }
                {/* <Select
                  placeholder=""
                  className="react-select"
                  classNamePrefix="react-select"
                  onChange={ value => this.setState({ type: value.label }) }
                  styles={{
                    option: (base) => ({
                      ...base,
                     background: '#4A289C',
                     marginTop: '-5px',
                     marginBottom: '-4px'
                    }),
                  }}
                  defaultValue={defaultOptions}
                  options={option}
                   /> */}
              </li>
          </ul>
        </div>
      </nav>
   </>
    );
  }
}

export default withRouter(Navbar);

import React, {Component} from 'react';
import Footer from '../components/footer';
import king from '../images/proking/king2.png';
import king2 from '../images/proking/king2.svg';
import tick from '../images/proking/g-tick.png';
import star from '../images/proking/star.png';
import percent from '../images/proking/percent.png';
import Button from '../components/Button';
class ProKing extends Component {
  
  
  render() {
    return (
      <div className="king row no-gutters">
        <div className="col-md-5 mobile">
          <div className="right">
          <img src={king2} alt="" />
            <p>Do you have what it take to be a</p>
            <h1>PRO KING?</h1>
            <span>Check to see if you are eligible to be a 
              Pro King. For you ease we've developed a calculator so you don't miss your chance!</span>
              <Button onClick={()=> this.props.history.push('/dashboard/calculator')} value="Check Eligibility Calculator" />
          </div>
        </div>
        <div className="col-md-7">
          <div className="left">
            <img src={king} alt=""/>
            <h1>The Pro Kings</h1>
            <p>Prostarter is coming up with PRO Kings, something rewarding and exciting for our community. In order to be a PRO King, you would have to hold the specific amount of PROT tokens set by the platform.</p>
           
            <div className="sub-div">
            <div>
              <img src={star} alt=""/>
              <h5 className="heading">Guaranteed Allocation</h5>
              <p>Pro Kings will have a core advantage of getting one guaranteed allocation in every project IDO without participating in whitelisting.</p>   
            </div>
            <div>
              <img src={percent} alt=""/>
              <h5>Direct allocation</h5>
              <p>All kings will have a share in the direct allocation of tokens given by our collaborators for the Prostarter community.</p>   
            </div>
            </div>
            <div className="third-div">
              <img src={tick} alt=""/>
              <h5>Governance Vote</h5>
              <p>Along with the Prostarter team, Pro Kings will be able to decide the selection of featured projects through their votes.</p>   
            </div>
          </div>
          <span className="line"></span>
        </div>
        <div className="col-md-5 web">
          <div className="right">
            <img src={king2} alt="" />
            <p>Do you have what it take to be a</p>
            <h1>PRO KING?</h1>
            <span>Check to see if you are eligible to be a 
              Pro King. For you ease we've developed a calculator so you don't miss your chance!</span>
              <Button onClick={()=> this.props.history.push('/dashboard/calculator')} value="Check Eligibility Calculator" />
            {/* <h5>Watch this space</h5>
            <h1>COMING SOON</h1>
            <div className="count">
                    <Countdown date={new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1,  10, 23, 59, 50, 100).getTime()} />
                    </div>
          <div className="time">
              <div>
                <span>DAYS</span>
              </div>
              <div>
                <span>HOURS</span>
              </div>

              <div>
                <span>MNUTES</span>
              </div>

              <div>
                <span>SECONDS</span>
              </div>
  
            </div> */}

          </div>
        </div>
        <div className="col-md-12">
          <Footer />
        </div>
      </div>
        )
    }
}

export default ProKing;
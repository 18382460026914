import monster from '../images/mystery/monster.svg';
import chimeras from '../images/mystery/chimeras-box.png';
import mons from '../images/mystery/mons.svg'

export const MysteryBoxes = [
    {
        shortId: 'chimeras-nft-box',
        boxId: 'chimeras-nft-box',
        image: chimeras, 
        thumbnail: "",
        name: 'Chimeras NFT Pack', 
        detail: 'Presenting you the Chimeras NFT Pack featuring upgradeable NFT Characters and 2 XP Potions. Chimeras is a thrilling mobile free-to-play and play-to-earn game with the integrated DeFi farming and NFTs',
        price: 0.23,
        usd: 125.00,
        blockChainNetworkId: "56",
        env: 'prod',
        totalSold: 800,
        nftContractAddress: "",
        externalClaims: true,
        mediaLinks: {
            twitter: "https://twitter.com/ChimerasGame",
            telegram: "https://t.me/ChimerasMetaverse",
            medium: "https://chimeras-crypto.medium.com/",
            web: "https://chimeras.io/",
        },
        items: [
            {
                name: "Common 1*",
                imageUrl: "",
                quantity: 572,
            },
            {
                name: "Common 2*",
                imageUrl: "",
                quantity: 144,
            },
            {
                name: "Common 3*",
                imageUrl: "",
                quantity: 48,
            },
            {
                name: "Common 4*",
                imageUrl: "",
                quantity: 24,
            },
            {
                name: "Common 5*",
                imageUrl: "",
                quantity: 12,
            },
        ],
        benefits: `
            <li>The Chosen are unique creatures that players can use for battles.</li>
            <li>All participants will get early game access in December.</li>
            <li>The staking program for NFTs coming up in a few days.</li>
            <li><a class="text-decoration-underline" style="color:#5ff7ff;" href="https://marketplace.chimeras.io/asset/packs/xp_poition" target="blank" >Special Gift</a> in each pack - XP potion for leveling the characters up!</li>
            <li>After distribution, these NFTs will be tradeable on <a class="text-decoration-underline" style="color:#5ff7ff;" href="https://marketplace.chimeras.io/catalog" target="blank">Chimeras’ Marketplace</a> and other secondary markets.</li>
            <li>These limited creatures will be available for purchase only on INO platforms.</li>
        `
    },
    {
        shortId: 'monster-clan-box',
        boxId: 'box-1',
        image: monster, 
        thumbnail: mons,
        name: 'Monsters Clan NFT', 
        detail: 'Presenting you the Monsters Clan Mystery Box having lots of game NFTs required to play Monsters Clan NFT Game. Monsters Clan is a first-ever third person NFT game that allow players to buy, own, train, and play their Monsters in fights to win and earn. ',
        price: 0.1,
        usd: 20.00,
        blockChainNetworkId: "97",
        env: 'dev',
        totalSold: 5000,
        nftContractAddress: "0x7ec283073d8403a75D5Ab969fE4f49Aa5FA121ff",
        externalClaims: false,
        mediaLinks: {
            twitter: "https://twitter.com/_Monstersclan",
            telegram: "https://t.me/MONSofficial",
            medium: "https://medium.com/@monstersclan%C2%A0",
            web: "https://monstersclan.com/",
        },
        items: [
            {
                name: "Grumpy Engineer",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631109001202_470733_Grumpy%20Engineer.svg",
                quantity: 5,
            },
            {
                name: "Rootrecia",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631108909536_478493_Rootreecia.svg",
                quantity: 10,
            },
            {
                name: "Vasuki Dragon",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631109185175_979300_Vasuki%20Dragon.svg",
                quantity: 20,
            },
            {
                name: "Mr. Foodles",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631110482458_442398_Mr.%20Foodles.svg",
                quantity: 25,
            },
            {
                name: "Rudy",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631108962205_965416_Rudy.svg",
                quantity: 70,
            },
            {
                name: "Snakegator",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631109043786_152448_Snakegator.svg",
                quantity: 100,
            },
            {
                name: "Potion Cauldron",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631170067804_435778_Basic%20Cauldron.svg",
                quantity: 50,
            },
            {
                name: "Basic Cave",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1632112914235_754871_basicCave.svg",
                quantity: 520,
            },
            {
                name: "Mighty Potion",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631170592773_627047_Mighty%20Potion.svg",
                quantity: 1000,
            },
            {
                name: "Premium Coin Box",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631188153458_189630_Premium%20Coins%20Storage%20Box.svg",
                quantity: 400,
            },
            {
                name: "Super Coin Box",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631171898612_343088_Super%20Coins%20Storage%20Box.svg",
                quantity: 500,
            },
            {
                name: "Standard Coin Box",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631171833115_458218_Standard%20Coins%20Storage%20Box.svg",
                quantity: 800,
            },
            {
                name: "Basic Coins Box",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631171669349_347273_Basic%20Coins%20Storage%20Box.svg",
                quantity: 1500,
            },
        ]
    },
    {
        shortId: 'monster-clan-box',
        boxId: 'monster-clan-box',
        image: monster,
        thumbnail: mons,
        name: 'Monsters Clan NFT', 
        detail: 'Presenting you the Monsters Clan Mystery Box having lots of game NFTs required to play Monsters Clan NFT Game. Monsters Clan is a first-ever third person NFT game that allow players to buy, own, train, and play their Monsters in fights to win and earn. ',
        price: 0.035,
        usd: 20.00,
        blockChainNetworkId: "56",
        env: 'prod',
        totalSold: 5000,
        nftContractAddress: "0x372360677ec2b72cfc5ad89fc3c14b43e2e1a2f5",
        externalClaims: false,
        mediaLinks: {
            twitter: "https://twitter.com/_Monstersclan",
            telegram: "https://t.me/MONSofficial",
            medium: "https://medium.com/@monstersclan%C2%A0",
            web: "https://monstersclan.com/",
        },
        items: [
            {
                name: "Grumpy Engineer",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631109001202_470733_Grumpy%20Engineer.svg",
                quantity: 5,
            },
            {
                name: "Rootrecia",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631108909536_478493_Rootreecia.svg",
                quantity: 10,
            },
            {
                name: "Vasuki Dragon",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631109185175_979300_Vasuki%20Dragon.svg",
                quantity: 20,
            },
            {
                name: "Mr. Foodles",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631110482458_442398_Mr.%20Foodles.svg",
                quantity: 25,
            },
            {
                name: "Rudy",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631108962205_965416_Rudy.svg",
                quantity: 70,
            },
            {
                name: "Snakegator",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631109043786_152448_Snakegator.svg",
                quantity: 100,
            },
            {
                name: "Potion Cauldron",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631170067804_435778_Basic%20Cauldron.svg",
                quantity: 50,
            },
            {
                name: "Basic Cave",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1632112914235_754871_basicCave.svg",
                quantity: 520,
            },
            {
                name: "Mighty Potion",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631170592773_627047_Mighty%20Potion.svg",
                quantity: 1000,
            },
            {
                name: "Premium Coin Box",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631188153458_189630_Premium%20Coins%20Storage%20Box.svg",
                quantity: 400,
            },
            {
                name: "Super Coin Box",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631171898612_343088_Super%20Coins%20Storage%20Box.svg",
                quantity: 500,
            },
            {
                name: "Standard Coin Box",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631171833115_458218_Standard%20Coins%20Storage%20Box.svg",
                quantity: 800,
            },
            {
                name: "Basic Coins Box",
                imageUrl: "https://files-dev.monstersclan.com/assets/asset_1631171669349_347273_Basic%20Coins%20Storage%20Box.svg",
                quantity: 1500,
            },
        ],
    }
]
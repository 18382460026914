/* eslint-disable no-use-before-define */
/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import Countdown from 'react-countdown';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Scrollbars } from 'react-custom-scrollbars';
import Loader from 'react-loader-spinner';
import LazyLoad from 'react-lazyload';
import Footer from '../components/footer';
import { getUserAddress } from '../web3Helper';
import i from '../images/whitelisting/i.svg';
// import live from '../images/whitelisting/live.svg';
import search from '../images/whitelisting/search.svg';
import king from '../images/whitelisting/king.svg';
import arrow from '../images/whitelisting/arrow.svg';
import cup from '../images/whitelisting/cup.svg';
import star from '../images/whitelisting/star.svg';
import up from '../images/whitelisting/up.svg';
import down from '../images/whitelisting/down.svg';
import axios from 'axios';
// import socketClient from '../socketService';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedNumber } from 'react-intl';
import { getIdoCurrency } from '../utils';

class WhiteListing extends Component {
  
  state = {
    isShow: false,
    searchField: '',
    searchPublic: '',
    random: '00',
    activeAccountAddress:'',
    protSearch:'',
    publicSearch:'',
    winnerSearch:'',
    protData: [],
    publicData:[],
    winners : [],
    showWinners: [],
    winnerObj: null,
    protCount : 0,
    currentTime: new Date(),
    publicCount : 0,
    liveIdo: null,
    livePool: ''
  }

  componentDidMount() {
    const {match} = this.props;
    if(localStorage.getItem(`num-${match.params.id}` ) ===null){
      localStorage.setItem(`num-${match.params.id}`, 0);
    }
    this.setState({showWinners: !!localStorage.getItem(`winner-${match.params.id}`) ? JSON.parse(localStorage.getItem(`winner-${match.params.id}`)) : [] });

    axios(`/ido/${match.params.id}`)
    .then((resp) => {
      const { ido } = resp.data;
      this.setState({ 
        liveIdo:ido,
        winners: [],
        publicData: [],
        protData: [],
      }, () => {
        if(ido.status === 'LIVE_WHITELISTING_OPEN' || ido.status === 'LIVE_WHITELISTING_CLOSED') {
          this.getProtAddress()
          this.getPublicAddress();
          this.getWinners();
         
          getUserAddress()
            .then((account) => {
              this.setState({ activeAccountAddress: account });
              axios.get(`/winner/get-winners`,  { params: { projectId: ido._id, address: account } })
              .then((resp) => {
                if( !!resp.data.winners && !!resp.data.winners.length ) {
                  this.setState({
                    winnerObj: {
                      address: resp.data.winners[0].address,
                      allocations: resp.data.winners.length,
                    }
                  })
                }
              })
            })
        }
      });
        
      // socketClient.on('winner', (winner) => {
      //   this.setState({
      //     winners: !!(this.state.winners.find((w) => w.address === winner.address)) ? this.state.winners.map((w) => w.address === winner.address ? { ...w, allocations: w.allocations + 1 } : {...w} ) : [ ...this.state.winners, { allocations: 1, address: winner.address }  ],
      //     ...(
      //       winner.address === this.state.activeAccountAddress ? 
      //         { 
      //           winnerObj: !!this.state.winnerObj ? 
      //           { ...this.state.winnerObj, allocations: this.state.winnerObj.allocations + 1 } : 
      //           { allocations: 1, address: winner.address } } 
      //       : {}
      //     )
      //   })
      // })
  
      // socketClient.on('new-lottery-number', (lotteryNumber) => {
      //   console.log('NEW LOTTERY NUMBER', lotteryNumber)
      //   !!lotteryNumber && this.setState({
      //     random: lotteryNumber
      //   })
      // })
      if( ido.status === 'LIVE_WHITELISTING_OPEN' && 
        (!ido.liveWhitelistingEndDate ||  new Date() < new Date(ido.liveWhitelistingEndDate)) 
      ) {
        setInterval(()=> {
          this.checkWinner()
         }, 3000)
      }
    }).catch((e) => {
      toast.error('No IDO has Live Whitelisting Active!')
    })

    setInterval(()=> {
      this.setState({ currentTime: new Date() })
    }, 1000)
  }

  checkWinner = () => {
    const {liveIdo, winners} = this.state;
    const { match } = this.props;
    const array =  JSON.parse(localStorage.getItem(`winner-${match.params.id}`)) !== null ?  JSON.parse(localStorage.getItem(`winner-${match.params.id}`)) : []
    const index = parseInt(localStorage.getItem(`num-${match.params.id}`));
    if( liveIdo && liveIdo.status === 'LIVE_WHITELISTING_OPEN' && 
      (!liveIdo.liveWhitelistingEndDate ||  new Date() < new Date(liveIdo.liveWhitelistingEndDate)) 
    ) {
      if(index < winners.length){
        const isLotteryNumExists = array.find(val => val.lotteryNum === winners[index].lotteryNum || val.address === winners[index].address);
        const isAddressExist = array.find(val => val.address === winners[index].address);
        if(isLotteryNumExists === undefined) {
          array.push({address: winners[index].address, lotteryNum: winners[index].lotteryNum, allocations: 1 });
          localStorage.setItem(`winner-${match.params.id}`, JSON.stringify(array));
          console.log('winners[index].pool', winners[index].pool)
          this.setState({
            random: winners[index].lotteryNum,
            showWinners: array,
            livePool: winners[index].pool
          });
        }
        else if(isAddressExist) {
          const newArr = array.map(arr => arr.address === winners[index].address ? { ...arr, allocations: arr.allocations + 1 } : { ...arr } );
          localStorage.setItem(`winner-${match.params.id}`, JSON.stringify(newArr));
          this.setState({
            random: winners[index].lotteryNum,
            showWinners: newArr,
            livePool: winners[index].pool
          });
        }
        const num = index + 1;
        localStorage.setItem(`num-${match.params.id}`, num);
      }
        
    }
  }
  getWinners = ( offset = 0, limit = 10000, ) => {
    const { match } = this.props;
    
    const { liveIdo : { _id: projectId, status, liveWhitelistingEndDate }, winnerSearch } = this.state;
    let winnersArray =  JSON.parse(localStorage.getItem(`winner-${match.params.id}`));
    winnersArray = winnersArray === null ? []: winnersArray;
    if(!projectId) return;
    axios.get(`/winner/get-winners`, { params: { 
      projectId,
      limit,
      offset,
      ...( !!winnerSearch ? { search: winnerSearch } : {} )
    }})
      .then((resp) => {
        if( resp && resp.data ) {
          const winnersMap = new Map();
          for(let winner of resp.data.winners ) {
            if( !winnersMap.get(winner.address) ) {
              winnersMap.set(winner.address, { address: winner.address, prokingAllocations: 0, normalAllocations: 0 })
            }
            const currWinner = winnersMap.get(winner.address);
            winnersMap.set(winner.address, { 
              ...currWinner,
              ...(
                !winner.prokingStatus ? {
                  normalAllocations: currWinner.normalAllocations + 1 
                }
                : 
                {
                  prokingAllocations: currWinner.prokingAllocations + 1 
                }
              )
            })
          }
          if( status === 'LIVE_WHITELISTING_OPEN' &&
              (!liveWhitelistingEndDate ||  new Date() < new Date(liveWhitelistingEndDate)) 
            ) {
            const showWinners = [...winnersMap.values()]
              .filter((w) => !!w.prokingAllocations)
              .map((w) => ({ ...w, allocations: w.prokingAllocations }));

            winnersArray && !winnersArray.length && 
            localStorage.setItem(`winner-${match.params.id}`, JSON.stringify(showWinners) );
            
            this.setState({
              winners: resp.data.winners.filter(win => !win.prokingStatus),
              ...( winnersArray && !winnersArray.length 
                ? { showWinners }
                : {}
              )
            });
             
          } else {
            localStorage.removeItem(`winner-${match.params.id}`);
            this.setState({
              showWinners: [...winnersMap.values()].map((w) => ({ ...w, allocations: w.prokingAllocations + w.normalAllocations })),
            })
          }
        }
      })
      .catch(err=> console.log(err))
  }

  getProtAddress = ( offset = 0, limit = 100, ) => {
    const { liveIdo : { _id: projectId }, protSearch } = this.state;
    if(!projectId) return;
    axios.get(`/whitelist/list/prot`, { params: { 
      projectId,
      limit,
      offset,
      ...( !!protSearch ? { search: protSearch } : {} )
    }})
    .then(res=> {
      this.setState({
        protData: [ ...this.state.protData, ...res.data.results ],
        protCount: res.data.count,
      })
    })
      .catch(err=> {
        console.log(err)})
  }

  getPublicAddress = ( offset = 0, limit = 100, ) => {
    const { liveIdo : { _id: projectId }, publicSearch } = this.state;
    if(!projectId) return;
    axios.get(`/whitelist/list/public`, { params: { 
      projectId,
      limit,
      offset,
      ...( !!publicSearch ? { search: publicSearch } : {} )

    }})
    .then(res=> {
      this.setState({
        publicData: [ ...this.state.publicData, ...res.data.results ],
        publicCount: res.data.count,
      })
    }).catch(err=> {
      console.log(err)})
  }

  searchPublic = (e) => {
    const address = e.target.value;
    this.setState({ publicSearch: address, publicData: [] }, () => {
      this.getPublicAddress();
    })
  }

  searchProt = (e) => {
    const address = e.target.value;
    this.setState({ protSearch: address, protData: [] }, () => {
      this.getProtAddress();
    })
  }

  searchWinner = (e) => {
    this.setState({ searchField: e.target.value });
  }

  loadMore = (type) => {
    if( type === 'prot' ) {
      this.getProtAddress( this.state.protData.length );
    } else if ( type === 'public' ) {
      this.getPublicAddress( this.state.publicData.length );
    } else if ( type === 'winner' ) {
      this.getWinners( this.state.winners.length );
    }
  }

  loading = () => (
    <h4>Loading...</h4>
  )
  render() {
    const {
      isShow,
      winners, 
      protData, 
      publicData,
      random,
      winnerObj,
      protCount,
      publicCount,
      liveIdo,
      searchField,
      showWinners,
      livePool,
      currentTime
  } = this.state;

    const filteredWinners = showWinners !== null ? showWinners.filter(winner =>
    winner.address.toLowerCase().includes(searchField.toLowerCase())) : []
    return (
      <div className="white row no-gutters">
        
        <div className="col-lg-3 ml-2 left">
          <div className="top-div">
          <h3>
            PROT POOL 
            { livePool === 'prot' && <button className="live btn">Live</button> }
          </h3>
          {/* <button className="btn">LIVE</button> */}
          {/* <p>{protData.length}/{protCount}</p> */}
          </div>
          <input type="search" onChange={this.searchProt} className="form-control" placeholder="Search for a Token Address" />
          <img src={ search } className="search"  alt=""/>
          <div className="sec-div">
          <Scrollbars style={ { height: 560, width: '100%' } }
              className="scroll"
              renderTrackHorizontal={ props => <div { ...props } className="track-horizontal"/> }
              renderTrackVertical={ props => <div { ...props } className="track-vertical"/> }>
            
            <InfiniteScroll
              dataLength={protData.length} //This is important field to render the next data
              next={() => this.loadMore('prot')}
              hasMore={ protData.length < protCount }
              loader={<Loader
                        type="Puff"
                        color="#ffffff"
                        className="loader"
                        height={ 80 }
                        width={ 80 } />}>
             {
              protData.map((da, index) => (
                <LazyLoad  key={index} height={100} once placeholder={this.loading}>
                  <div className="sub-div">
                    <img width="20" className={`mx-2 ${!!da.prokingStatus ? '': 'hide'}`} src={ king } alt="" />
                    <div className="m-auto">{da.startTicket}-{da.endTicket}</div>
                    <p className="mx-2">{da.address}</p>
                  </div>
                </LazyLoad>
              ))}
            </InfiniteScroll>
           </Scrollbars>
          </div>
        </div>
        <div className="col">
        <div className="top-box">
            <div  className="header">
            <p>{liveIdo && liveIdo.type === 'GAME' ? 'IGO' : 'IDO'} Details</p>
            <img src={isShow ? up : down} alt="" className="arrow" onClick={()=> this.setState({isShow: !this.state.isShow})}/>
            </div>
          {isShow && !!liveIdo ? 
                <>
                <div className="horse-div my-2">
                  <img className="horse-img m-auto" width={70} height={70}  src={ process.env.REACT_APP_API_URL + liveIdo.logoUrl } alt="" />
                  <div className="my-3">
                    <h6>{liveIdo.name}</h6>
                    {
                      liveIdo.description.split('\n').map((description) => <p>{description}</p>)
                    }
                    <button
                      className="view btn"
                      onClick={()=> this.props.history.push(`/project-detail/${liveIdo._id}`)}
                    >
                      View project details 
                      <img src={ arrow } alt="" className="ml-1" />
                    </button>
                  </div>
                  </div>  
                  <div className="alloc-div">
                    <div>
                      <p>TOTAL ALLOCATION</p>
                      <span>{liveIdo.allocations}</span>
                    </div>
                    <div>
                      <p>PROT POOL</p>
                      <span>{liveIdo.protAllocations}</span>
                    </div>
                    <div>
                      <p>PUBLIC POOL</p>
                      <span>{liveIdo.publicAllocations}</span>
                    </div>
                  </div>
                  <div className="time-div pb-3">
                    <div>
                      <p>ALLOCATION SIZE (USD)</p>
                      <span>{liveIdo.allocationSize}</span>
                    </div>
                    <div>
                      <p>ALLOCATION SIZE ({getIdoCurrency(liveIdo.contractType, liveIdo.blockChainNetworkId)})</p>
                      <span><FormattedNumber value={liveIdo.allocationSizeEth} /></span>
                    </div>
                  </div>
                </>
              : null
            }
          </div>
        <div className="mid-div">
          <div className="sub-mid first-mid">
            <p>TIME REMAINING <img src={ i } alt="" /></p>
            {
              liveIdo && liveIdo.status === 'LIVE_WHITELISTING_OPEN' ?
                <>
                 {
                    !liveIdo.liveWhitelistingStartDate ?
                      <div className="mt-2">To Be Announced</div>
                    : liveIdo.liveWhitelistingStartDate && new Date(liveIdo.liveWhitelistingStartDate) > new Date() ?
                      <Countdown date={ new Date(liveIdo.liveWhitelistingStartDate).getTime() } />
                    : !liveIdo.liveWhitelistingEndDate ?
                      <div className="mt-2">To Be Announced</div>
                    : liveIdo.liveWhitelistingEndDate && new Date(liveIdo.liveWhitelistingEndDate) > new Date()?
                      <Countdown date={ new Date(liveIdo.liveWhitelistingEndDate).getTime() } />
                    : <div className="mt-2">Ended</div>
                 }   
                  <ProgressBar
                    animated
                    now={ 
                      liveIdo.liveWhitelistingStartDate && liveIdo.liveWhitelistingEndDate ?
                        ( (currentTime.getTime() - new Date(liveIdo.liveWhitelistingStartDate).getTime()) / (new Date(liveIdo.liveWhitelistingEndDate).getTime() - new Date(liveIdo.liveWhitelistingStartDate).getTime() ) ) * 100
                      : 0
                    } 
                  />
                </>
              : <div>CLOSED</div>
            }

          </div>
          <div className="sub-mid">
            <p>WINNING TICKET NUMBER <img src={ i } alt="" /></p>
            <h2>
              <span>{random ? random.toString().substr(0,1): ''}</span>
              {random && random.toString().length > 1 &&<span>{random ? random.toString().substr(1,1): ''}</span>}
              {random && random.toString().length > 2 &&<span>{random ? random.toString().substr(2,1): ''}</span>}
              {random && random.toString().length > 3 &&<span>{random ? random.toString().substr(3,1): ''}</span>}
              {random && random.toString().length > 4 &&<span>{random ? random.toString().substr(4,1): ''}</span>}
            </h2>
          </div>
        </div>
        <div className="winnerlist-div">
        <div className="cup-div">
          <h5><img src={ cup } alt="" /> WHITELISTED WINNERS <img src={ i } alt="" /></h5>
          <p>Total Winners: <b>{showWinners !==null ? showWinners.length : 0}</b></p>
        </div>
     
        <input type="search" className="form-control search-input" onChange={this.searchWinner}
        placeholder="Search for a Token Address" />
          <img src={ search } className="search" alt=""/>
        <div className="bottom-div">
          <div className="bottom-head hide">
            <div>
              <button className="btn"><img src={ star } alt="" /> You Won</button>
              <p className="web-p">{this.state.activeAccountAddress}</p>
              <p className="mob-p"> { this.state.activeAccountAddress ? this.state.activeAccountAddress.substr(0,6) + '...'
                + this.state.activeAccountAddress.substr(-4): 'Connect Wallet' }</p>
            </div>
            <button className="btn get-btn">
                Get your allocation ({<Countdown date={ new Date(1620831600000).getTime() + 600000} />})
            </button>
          </div>
          {
            !!winnerObj && 
              <div className="bottom-head show"> 
                <div>
                  <button className="btn font-weight-bold"><img src={ star } width="15" alt="" /> You Won</button>
                  <p className="web-p">{winnerObj.address} ({winnerObj.allocations})</p>
                  <p className="mob-p"> 
                    { winnerObj.address.substr(0,6) + '...' + winnerObj.address.substr(-4) } ({winnerObj.allocations})
                  </p>
                </div>
                {
                  liveIdo.saleDate && new Date(liveIdo.saleDate) > new Date() ?
                    <button disabled={true} className="btn get-btn">
                      Get your allocation ({<Countdown date={ new Date(liveIdo.saleDate).getTime() } />})
                    </button>
                  :
                  <button className="btn get-btn px-3" onClick={() => this.props.history.push(`/allocation/${liveIdo._id}`)}>
                    Buy Tokens
                  </button>
                }
              </div>
          }
          <Scrollbars style={ { height: 280, width: '100%', top:'-22px' } }
              className="scroll mid-scroll"
              renderTrackHorizontal={ props => <div { ...props } className="track-horizontal"/> }
              renderTrackVertical={ props => <div { ...props } className="track-vertical"/> }>
            <InfiniteScroll
                dataLength={winners.length} //This is important field to render the next data
                next={() => this.loadMore('winner')}
                hasMore={ false }
                loader={<h4>Loading...</h4>}
              >
                {filteredWinners.map(da => (
                  <div className="sub-bottom">
                  <p>{da.address} ({da.allocations})</p>
                  {/* <p>05:00:52</p> */}
                </div>
                ))}
            </InfiniteScroll>
          </Scrollbars>
        </div>
        </div>
        </div>
        <div className="col-lg-3 mr-2 right">
          <div className="top-div">
          <h3> PUBLIC POOL { livePool === 'public' && <button className="live btn">Live</button> }</h3>
          {/* { liveIdo && liveIdo.liveWhitelistingActivePool === 'public' && <img className="live" src={live} alt="" width="100"/> } */}
          {/* <p>{publicData.length}/{publicCount}</p> */}
          </div>
          <input type="search"  onChange={this.searchPublic} className="form-control" placeholder="Search for a Token Address" />
          <img src={ search } className="search"  alt=""/>
          <div className="sec-div">
          <Scrollbars style={ { height: 560, width: '100%' } }
              className="scroll"
              renderTrackHorizontal={ props => <div { ...props } className="track-horizontal"/> }
              renderTrackVertical={ props => <div { ...props } className="track-vertical"/> }>
                <InfiniteScroll
                    dataLength={publicData.length} //This is important field to render the next data
                    next={() => this.loadMore('public')}
                    hasMore={ publicData.length < publicCount }
                    loader={ <Loader
                              type="Puff"
                              color="#ffffff"
                              className="loader"
                              height={ 80 }
                              width={ 80 } />}>
                  {publicData.map((fil, index)=> (
                    <LazyLoad  key={index} height={100} once placeholder={this.loading}>
                      <div className="sub-div">
                        <div className="ml-2 my-auto">{fil.endTicket}</div>
                        <p className="mx-2 w-75">{fil.address}</p>
                      </div>
                    </LazyLoad>
                  ))}
              </InfiniteScroll>
            
            
            </Scrollbars>
          </div>
        </div>
        {/* <div className="col-md-1"></div> */}

        <div className="col-md-12">
          <Footer />
        </div>
      </div>
       )
    }
}

export default withRouter(WhiteListing);
import Locking from '../contracts/Locking.json';
import IdoSaleWithVesting from '../contracts/IdoSaleWithVesting.json';
import IdoSaleWithVestingV2 from '../contracts/IdoSaleWithVestingV2.json';
import IdoSaleWithVestingByTokens from '../contracts/IdoSaleWithVestingByTokens.json';
import UsdtToken from '../contracts/UsdtToken.json';
import ProtToken from '../contracts/ProtToken.json';
import MysteryBox from '../contracts/MysteryBox.json';
import MysteryBoxV2 from '../contracts/MysteryBoxV2.json';
import Staking from '../contracts/Staking.json';
import { getWeb3IfAvailable } from '../web3Utils';

export const getContractABI = (contractType) => {
    switch(contractType) {
        case 'IdoSaleWithVesting':
            return IdoSaleWithVesting.abi;
        case 'IdoSaleWithVestingV2':
            return IdoSaleWithVestingV2.abi;
        case 'IdoSaleWithVestingByTokens':
            return IdoSaleWithVestingByTokens.abi;
        case 'UsdtToken':
            return UsdtToken.abi;
        case 'ProtToken':
            return ProtToken.abi;
        case 'MysteryBox':
            return MysteryBox.abi;
        case 'MysteryBoxV2':
            return MysteryBoxV2.abi;
        case 'Staking':
            return Staking.abi;
        case 'Legacy':
        default:
            return Locking.abi;
    }
}


export const getContractAddress = async (contractType) => {
    const web3 = await getWeb3IfAvailable();
    const networkId = await web3.eth.net.getId();
    
    switch(contractType) {
        case 'IdoSaleWithVesting':
            return IdoSaleWithVesting.networks[networkId] ? IdoSaleWithVesting.networks[networkId].address : '';
        case 'IdoSaleWithVestingV2':
            return IdoSaleWithVestingV2.networks[networkId] ? IdoSaleWithVestingV2.networks[networkId].address: '';
        case 'IdoSaleWithVestingByTokens':
            return IdoSaleWithVestingByTokens.networks[networkId] ? IdoSaleWithVestingByTokens.networks[networkId].address: '';
         case 'UsdtToken':
            return UsdtToken.networks[networkId] ? UsdtToken.networks[networkId].address: '';
        case 'ProtToken':
            return ProtToken.networks[networkId] ? ProtToken.networks[networkId].address: '';
        case 'MysteryBoxV2':
            return MysteryBoxV2.networks[networkId] ? MysteryBoxV2.networks[networkId].address: '';
        case 'Staking':
            return Staking.networks[networkId] ? Staking.networks[networkId].address: '';
        case 'Legacy':
            return Locking.networks[networkId] ? Locking.networks[networkId].address: '';
        default:
            return ''
    }
}

import React, {Component} from 'react';

import keyMile from '../../images/landing/keyMile.svg';
class KeyMileStones extends Component {

  state = {
    totalTokensAllocated: 0,
    isTotalAllocationsLoading: true
  }


  render() {

    return (
      <div className="key col-12">
        <img src={keyMile} alt=""/>
        <h3>Key Milestones</h3>
        <p>We're extremely overwhelmed by investor's response. Our milestones serve
           as a key benchmark in being the top launchpads since our origin in March 2021.</p>
        
        <div className="row no-gutters">
          <div className="col">
            <div className="box">
              <span>Private Investments</span>
              <h1>$10,000,000+</h1>
            </div>
          </div>
          <div className="col">
          <div className="box">
              <span>Public Investments</span>
              <h1>$1,000,000+</h1>
            </div>
          </div>
          <div className="col">
          <div className="box">
              <span>Capital Raised</span>
              <h1>$750,000+</h1>
            </div>
          </div>
        </div>
        <div className="row no-gutters">
         
          <div className="col">
          <div className="box">
              <span>Top Influencers/KOLs</span>
              <h1>100+</h1>
            </div>
          </div>
          <div className="col">
          <div className="box">
              <span>Top Investors</span>
              <h1>100+</h1>
            </div>
          </div>
          <div className="col">
          <div className="box">
              <span>Top VC Firms</span>
              <h1>50+</h1>
            </div>
          </div>
        </div>
      </div>
    )
    }
}

export default KeyMileStones;

/* eslint-disable react/style-prop-object */
import React, {Component} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { toast } from 'react-toastify';
import {Link, Switch, Route} from 'react-router-dom';
import Footer from '../components/footer';
import copy from '../images/home/copy.svg';
import msg from '../images/msgnav.svg';
import twitter from '../images/twitternav.svg';
import social from '../images/socialnav.svg';
import website from '../images/landing/internet.svg';
import BuyModal from '../components/mysteryBox/buyModal';
import { MysteryBoxes as MysteryBoxesArr } from '../data';
import { getChainSymbol, getIdoCurrency, getNetworkName } from '../utils';
import { MysteryBoxService } from '../services';
import Loader from 'react-loader-spinner';
import { getUserBalance, getBlockChainNetworkId } from '../web3Helper';
import Rules from '../components/buyMystery/Rules';
import Items from '../components/buyMystery/Items';
import Rewards from '../components/buyMystery/Rewards';
import Countdown from 'react-countdown';
import binance from '../images/keymile/binance.svg';
import ethereum from '../images/keymile/ethereum.svg';
import polygon from '../images/keymile/polygon.svg';
import Benefits from '../components/buyMystery/Benefits';

class BuyMysteryBox extends Component {

  state = {
    toggle: false,
    mysteryBox: null,
    perWalletLimit: 0,
    perWalletLimitForProking: 0,
    boxesSold: 0,
    boxesToSell: 0,
    totalPurchased: 0,
    saleTime: 0,
    saleReleaseTime: 0,
    claimReleaseTime: 0,
    userRoleId: 0,
    perWalletLimitForTicketHolder: 0,
    isLoading: false,
    isPurchaseOpen: false,
    isProcessing: false,
    balance: 0,
    isClaimsLoading: false,
    purchases: [],
    claimsInProcess: {},
    currentBlockChainNetworkId: '',
  }

  componentDidMount() {
    const {match} = this.props;
    const shortId = match.params.id;

    getBlockChainNetworkId().then((blockChainNetworkId) => {
      this.setState({
        currentBlockChainNetworkId: blockChainNetworkId
      })
    })
    
    const mysteryBox = MysteryBoxesArr.find((arr) => (( process.env.REACT_APP_ENV === 'local' && arr.env === 'dev' ) || arr.env  === process.env.REACT_APP_ENV) && arr.shortId === shortId);
    if(mysteryBox) {
      this.setState({
        mysteryBox
      }, () => {
        this.init();
        this.initClaims();
      });
    }

  }

  init = () => {
    const {mysteryBox} = this.state;

    getUserBalance()
      .then((balance) => {
        this.setState({ balance });
      })

    const mysteryBoxService = new MysteryBoxService();
    this.setState({
      isLoading: true
    })
    mysteryBoxService.getBoxDetails(mysteryBox.boxId)
      .then((details) => {
        this.setState({
          isLoading: false,
          totalPurchased: details.totalPurchased,
          isPurchaseOpen: details.isPurchaseOpen,
          boxesToSell: details.boxesToSell,
          boxesSold: mysteryBox.totalSold || details.totalSold,
          perWalletLimit: details.perWalletLimit,
          perWalletLimitForProking: details.perWalletLimitForProking,
          perWalletLimitForTicketHolder: details.perWalletLimitForTicketHolder,
          userRoleId: details.userRoleId,
          saleTime: details.saleTime,
          saleReleaseTime: details.saleReleaseTime,
          claimReleaseTime: details.claimReleaseTime
        })
      })
      .catch((err) => {
        console.log('err',err);
        toast.error(err);
        this.setState({
          isLoading: false
        })
      })
  }

  initClaims = async () => {
    const {mysteryBox} = this.state;

    this.setState({
      isClaimsLoading: true
    })
    const mysteryBoxService = new MysteryBoxService()
    try {
      const purchases = await mysteryBoxService.getUserPurchases({
        boxId: mysteryBox.boxId
      })
      this.setState({
        purchases,
        isClaimsLoading: false
      })
    } catch(err) {
      this.setState({
        isClaimsLoading: false
      })
    }
  }

  CopyText = ()=> {
    toast.success("Address Copy to Clipboard");
  }

  handleBuy = ({ amount, quantity }) => {
    const { mysteryBox, balance } = this.state;
    if( amount > balance ) {
      toast.error('Insufficient Balance!')
      return;
    }
    const mysteryBoxService = new MysteryBoxService();
    this.setState({
      isProcessing: true,
      toggle: false
    })
    mysteryBoxService.buy({
      boxId: mysteryBox.boxId,
      amount,
      quantity
    })
      .then((response) => {
        toast.success(response);
        this.setState({
          isProcessing: false,
        })
        this.init();
        this.initClaims();
      })
      .catch((err) => {
        console.log('err',err);
        toast.error(err);
        this.setState({
          isProcessing: false
        })
      })
  }

  setClaimInProcess = (key, value) => {
    const { claimsInProcess } = this.state;
    this.setState({
      claimsInProcess: {
        ...claimsInProcess,
        [key]: value
      }
    })
  }

  handleClaim = ({index}) => {
    const {mysteryBox} = this.state;

    const mysteryBoxService = new MysteryBoxService();

    this.setClaimInProcess(`${index}`, true);

    mysteryBoxService.claim({ boxId: mysteryBox.boxId, index })
      .then((response) => {
        this.setClaimInProcess(`${index}`, false);
        toast.success(response);
        this.initClaims();
      })
      .catch((err) => {
        console.log('handleClaim', err);
        toast.error(err);
        this.setClaimInProcess(`${index}`, false);
      })
  }

  render() {
    const { 
      toggle,
      mysteryBox,
      boxesSold,
      boxesToSell,
      perWalletLimit,
      isLoading,
      isPurchaseOpen,
      balance,
      isProcessing,
      totalPurchased,
      isClaimsLoading,
      purchases,
      claimsInProcess,
      perWalletLimitForProking,
      perWalletLimitForTicketHolder,
      saleTime,
      saleReleaseTime,
      claimReleaseTime,
      currentBlockChainNetworkId
    } = this.state;
    const { match } = this.props;

    const progressOfSale = boxesToSell ? Math.ceil( (boxesSold * 100) / boxesToSell ) : 0;
    const items = this.state.mysteryBox && this.state.mysteryBox.items ? this.state.mysteryBox.items : [];
    const currentChainSymbol = getChainSymbol(currentBlockChainNetworkId.toString());

    return (
      <div className="row no-gutters buy-myster">
        <div className="col-12 first-div">
          {
            !isLoading && !mysteryBox?
              <h1 className="text-center p-5">Box not found!</h1>
            :
              <>
              {
                mysteryBox &&
                  <BuyModal
                    toggle={toggle}
                    balance={balance}
                    blockChainNetworkId={mysteryBox.blockChainNetworkId}
                    chainCurrency={getIdoCurrency('',
                    mysteryBox.blockChainNetworkId)}
                    price={mysteryBox.price}
                    usd={mysteryBox.usd}
                    onCloseModal={()=> this.setState({toggle: false})} 
                    onBuy={this.handleBuy}
                  />
              }
              <div className="row justify-content-md-center no-gutters">
                {/* <div className="col-lg-1 col-sm-1"></div> */}
                <div className="col-lg-5 col-sm-12">
                <h5 className="heading">Mystery Boxes > <span>{ mysteryBox && mysteryBox.name ? mysteryBox.name : '-' }</span></h5>
                  { 
                    mysteryBox && mysteryBox.image &&
                      <img src={mysteryBox.image } className="mr-2 image-left" alt=""/>
                  }
                </div>
                <div className="col-lg-5 col-sm-12">
                  <div className="box">
                    <div className="top-div">
                      <h2>
                        { mysteryBox && mysteryBox.thumbnail &&  <img src={mysteryBox.thumbnail} className="mr-2" alt=""/> }
                        { mysteryBox && mysteryBox.name ? mysteryBox.name : '-' }</h2>
                      <div>
                        {
                          mysteryBox && mysteryBox.mediaLinks && mysteryBox.mediaLinks.medium &&
                            <a href={mysteryBox.mediaLinks.medium} target="_blank" rel="noreferrer">
                              <img src={social} alt=""/> 
                            </a>
                        }
                        {
                          mysteryBox && mysteryBox.mediaLinks && mysteryBox.mediaLinks.twitter &&
                            <a href={mysteryBox.mediaLinks.twitter} target="_blank" rel="noreferrer">
                              <img src={twitter} alt=""/> 
                            </a>
                        }
                        {
                          mysteryBox && mysteryBox.mediaLinks && mysteryBox.mediaLinks.telegram &&
                            <a href={mysteryBox.mediaLinks.telegram} target="_blank" rel="noreferrer">
                              <img src={msg} alt=""/>
                            </a>
                        }
                        {
                          mysteryBox && mysteryBox.mediaLinks && mysteryBox.mediaLinks.web &&
                          <a href={mysteryBox.mediaLinks.web} target="_blank" rel="noreferrer">
                            <img src={website} alt=""/> 
                          </a>
                        }
                      </div>
                    </div>
                    <h6>
                      {
                        !currentBlockChainNetworkId ?
                          'Connect your wallet!'
                        : currentBlockChainNetworkId.toString() !== mysteryBox.blockChainNetworkId ?
                          <>Please switch to {getNetworkName(mysteryBox.blockChainNetworkId)} network!</>
                        : !boxesToSell ? 
                          'UNAVAILABLE'
                        : !isPurchaseOpen ? 
                          'CLOSED'
                        : new Date() < new Date(saleTime*1000) ? 
                          <Countdown date={new Date(saleTime * 1000).getTime()} />
                        : boxesToSell === boxesSold ? 
                          'SOLD OUT'
                        : 'AVAILABLE'
                      }
                    </h6>
                    <p>{ mysteryBox && mysteryBox.detail ? mysteryBox.detail : '-' }</p>
                    <div className="mid-div">
                    <div>
                      <span>PRICE</span>
                      <h5>{mysteryBox && mysteryBox.price ? `${mysteryBox.price} ${getIdoCurrency('', mysteryBox.blockChainNetworkId)}`: '-'}</h5>
                    </div>
                    <div>
                      <span>SUPPORTED NETWORK</span>
                      <h5>
                        {
                          currentChainSymbol === 'BSC' ? <img className="mr-2" src={binance} alt=""/> :
                          currentChainSymbol === 'ETH' ? <img className="mr-2" src={ethereum} alt=""/> :
                          currentChainSymbol === 'MATIC' ? <img className="mr-2" src={polygon} alt=""/> : null
                        }
                        { mysteryBox && mysteryBox.blockChainNetworkId ? getChainSymbol(mysteryBox.blockChainNetworkId) : '-' }
                      </h5>
                    </div>
                    <div>
                      <span>BUYING LIMIT/WALLET</span>
                      <h5>
                        {
                          isLoading ?
                            <Loader
                              type="Puff"
                              color="#ffffff"
                              className="d-inline mr-1"
                              height={ 15 }
                              width={ 15 }
                            />
                        :  perWalletLimit ?  
                            perWalletLimit === totalPurchased ? 'LIMIT EXHAUSTED' :
                            perWalletLimit - totalPurchased
                        : '-'
                        }
                      </h5>
                    </div>
                  </div>
                    <p>NFT CONTRACT ADDRESS</p>
                    <span className="address">
                      { 
                        mysteryBox && mysteryBox.nftContractAddress ?
                          <>
                            {mysteryBox.nftContractAddress}
                            <CopyToClipboard text={mysteryBox.nftContractAddress}
                              onCopy={this.CopyText}>
                              <img className="copy ml-2" src={copy} alt="" />
                            </CopyToClipboard>
                          </>
                        : '-'
                      } 
                    </span>
                    <p>SALE PROGRESS</p>
                    <ProgressBar now={progressOfSale} />
                    <div className="progress-div">
                      <p>
                        { 
                          isLoading ?
                            <Loader
                              type="Puff"
                              color="#ffffff"
                              className="d-inline mr-1"
                              height={ 15 }
                              width={ 15 }
                            />
                          : progressOfSale || '-'
                        }&nbsp;% Completed
                      </p>
                      <p>
                        {
                          isLoading ?
                            <Loader
                              type="Puff"
                              color="#ffffff"
                              className="d-inline mr-1"
                              height={ 15 }
                              width={ 15 }
                            />
                          : boxesSold || '-'
                        }
                        /
                        { isLoading ?
                            <Loader
                              type="Puff"
                              color="#ffffff"
                              className="d-inline mr-1"
                              height={ 15 }
                              width={ 15 }
                            />
                          : boxesToSell || '-'
                        }
                      </p>
                    </div>
                    {
                      !currentBlockChainNetworkId ?
                        <button disabled={true} className="btn global-btn">Connect your wallet!</button>
                      : currentBlockChainNetworkId.toString() !== mysteryBox.blockChainNetworkId ?
                        <button disabled={true} className="btn global-btn">Please switch to {getNetworkName(mysteryBox.blockChainNetworkId)} network!</button>
                      : !boxesToSell ?
                        <button disabled={true} className="btn global-btn">Unavailable</button>
                      : !isPurchaseOpen ? 
                        <button disabled={true} className="btn global-btn">Closed</button>
                      : new Date() < new Date(saleTime*1000) ? 
                        <button disabled={true} className="btn global-btn"><Countdown date={new Date(saleTime * 1000).getTime()} /></button>
                      : isProcessing ? 
                        <button disabled={true} className="btn global-btn">
                          <Loader
                            type="Puff"
                            color="#ffffff"
                            className="d-inline mr-1"
                            height={ 15 }
                            width={ 15 }
                          />
                        </button>
                      : boxesToSell === boxesSold ?
                        <button disabled={true} className="btn global-btn">SOLD OUT</button>
                      : <button onClick={()=> this.setState({toggle: true})} className="btn global-btn">Buy Now</button>
                    }
                  </div>
                </div>
              </div>
              <div className="row justify-content-md-center no-gutters">
                <div className="col-lg-10">
                  <div className="detail-box mb-4">
                    <ul className="nav">
                      <li className={match.url.includes('participation-rules') && 'active'}><Link to={`/buy-mystery-box/${match.params.id}/participation-rules`}>Participation Rules</Link></li>
                      <li className={match.url.includes('items') && 'active'}><Link to={`/buy-mystery-box/${match.params.id}/items`} >Mystery Box Items</Link></li>
                      {
                        mysteryBox && !mysteryBox.externalClaims &&
                        <li className={match.url.includes('rewards') && 'active'}><Link to={`/buy-mystery-box/${match.params.id}/rewards`} >Your Rewards</Link></li>
                      }
                      {
                        mysteryBox && mysteryBox.benefits && mysteryBox.benefits.length > 0 &&
                        <li className={match.url.includes('benefits') && 'active'}><Link to={`/buy-mystery-box/${match.params.id}/benefits`} >INO Sale Benefits</Link></li>
                      }
                    </ul>
                    <hr />
                    <Switch>
                      <Route 
                        path={`/buy-mystery-box/${match.params.id}/participation-rules`} 
                        render={
                          () => 
                            <Rules 
                              mysteryBoxName={mysteryBox ? mysteryBox.name : ''}
                              currentBlockChainNetworkId={currentBlockChainNetworkId}
                              blockChainNetworkId={mysteryBox ? mysteryBox.blockChainNetworkId : ''}
                              perWalletLimitForProking={perWalletLimitForProking}
                              perWalletLimitForTicketHolder={perWalletLimitForTicketHolder}
                              usd={mysteryBox ? mysteryBox.usd : 0}
                              saleTime={saleTime ? saleTime * 1000: 0}
                              claimReleaseTime={claimReleaseTime ? claimReleaseTime * 1000 : 0}
                              saleReleaseTime={saleReleaseTime ? saleReleaseTime * 1000 : 0}
                              boxesToSell={boxesToSell}
                              isLoading={isLoading} 
                            />
                        } 
                      />
                      <Route 
                        path={`/buy-mystery-box/${match.params.id}/items`} 
                        render={() => 
                          <Items 
                            currentBlockChainNetworkId={currentBlockChainNetworkId}
                            isLoading={isLoading}
                            boxesToSell={boxesToSell}
                            items={items}
                          />} 
                      />
                      <Route 
                        path={`/buy-mystery-box/${match.params.id}/rewards`} 
                        render={
                          () => 
                            mysteryBox && 
                            !mysteryBox.externalClaims &&
                            <Rewards 
                              isClaimsLoading={isClaimsLoading}
                              purchases={purchases}
                              claimsInProcess={claimsInProcess}
                              onClaim={this.handleClaim}
                            />
                        } 
                      />
                      <Route 
                        path={`/buy-mystery-box/${match.params.id}/benefits`} 
                        render={
                          () => 
                            mysteryBox && 
                            mysteryBox.benefits &&
                            mysteryBox.benefits.length > 0 &&
                            <Benefits 
                              isLoading={isLoading}
                              benefits={mysteryBox.benefits}
                            />
                        } 
                      />
                    </Switch>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
        <div className="col-12">
          <Footer />
        </div>
      </div>
    )
  }
}

export default BuyMysteryBox;
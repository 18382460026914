import React, {Component} from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import Footer from '../components/footer';


class Contact extends Component {
  
  state = {
      name: "sss",
      message: "",
      loader: false,
      type: '',
      options: [
        {value: "Private Investment", label: "Private Investment"},
        {value: "Partnership", label: "Partnership"},
        {value: "Marketing", label: "Marketing"}
      ]
  }

  sendEmail = (e)=> {
    e.preventDefault();

      const {message, type} = this.state;
      if(this.Email.value === "" || message === "" || this.Subject.value === "" || type === "") {
        return toast.error("Fields are required")
      }
      this.setState({loader: true});
      axios.post('/contact-us/send-email',{
          email: this.Email.value,
          message,
          subject: this.Subject.value,
          type
      })
      .then(res=> {
        this.Email.value = "";
        this.Subject.value= ""
        toast.success('Your message send successfully')
      this.setState({loader: false, message: '', type:""});
        console.log(res)})
      .catch(err=> {console.log(err)
        this.setState({loader: false});
      })
  }
  render() {
      const {message, loader,type, options} =  this.state;
    return (
      <div className="contact row no-gutters">
          <div className="col-md-12 main">
         <h3>Get in touch.</h3>
        <p>The team of Prostarter is always at your service to answer your questions or queries. Feel free to message us by filling the given form and our representative will contact you shortly.</p>
        <form>
            <div className="form-group">
              <label>Subject</label>
                <input  ref={ (r) => { this.Subject = r; } } type="text" className="form-control"/>
            </div>
            <label>Select type</label>
            <Select
            placeholder=""
             onChange={ value => this.setState({ type: value.label }) }
             defaultValue={type}
              options={ options } />
            <div className="form-group">
              <label>Email Address</label>
                <input ref={ (r) => { this.Email = r; } } type="email" className="form-control" id="exampleInputPassword1"/>
            </div>
            <div className="form-group form-check">
            </div>
            <label>Message</label>
            <textarea  value={message} onChange={ event => this.setState({ message: event.target.value }) }
            className="form-control" placeholder="" rows="6"></textarea>

            <button onClick={this.sendEmail} className="btn ">{ loader ?<Loader
                  type="Puff"
                  color="#5FF7FF"
                  height={ 30 }
                  width={ 30 }
                  timeout={ 9000 } //3 secs
               /> :  'Send'}</button>
        </form>
          </div>
        <div className="col-md-12">
          <Footer />
        </div>
      </div>
        )
    }
}

export default Contact;
import React, {Component} from 'react';
import Countdown from 'react-countdown';
import Footer from '../components/footer';
import nft from '../images/nft/nft.png';
import nftMobile from '../images/nft/nft-mobile.png';
import arrow from '../images/nft/arrow.png';
import logo from '../images/logo.png';

class NFT extends Component {
  
  state = {
      name: "sss"
  }
  render() {
    return (
      <div className="nft row no-gutters">
          <div className="col-md-5 mobile">
          <div className="right">
            <h5>Watch this space</h5>
            <h1>COMING SOON</h1>
            <div className="count">
              <Countdown date={new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() + 2,  0, 23, 59, 50, 100).getTime()} />
            </div>
          <div className="time">
              <div>
                {/* <h1>04</h1> */}
                <span>DAYS</span>
              </div>
              {/* <span className="line"></span> */}
              <div>
                {/* <h1>21</h1> */}
                <span>HOURS</span>
              </div>
              {/* <span className="line"></span> */}

              <div>
                {/* <h1>52</h1> */}
                <span>MINUTES</span>
              </div>
              {/* <span className="line"></span> */}

              <div>
                {/* <h1>02</h1> */}
                <span>SECONDS</span>
              </div>
  
            </div>
          
            <h1 className="per">12%</h1>
            <h6>DEDICATED ALLOCATION</h6>

          </div>
        </div>
        <div className="col-md-7">
         <div className="left">
             <img className="mbile-nft" src={nftMobile} alt="" />
            <div className="img-div">
                <img src={nft} alt=""/>
                <img src={arrow} alt=""/>
                <img src={logo} alt=""/>
            </div>
            <h1 className="heading">We love and support <span>NFTs</span></h1>
            <h3 className="m-head">We love and support</h3>
            <h3 className="m-h">NFTs</h3>
            <p>Prostarter is encouraging use of NFTs by giving 12% dedicated token allocation for NFT-based blockchain projects. Non-FungibleTokens have changed the way of DeFi market with digital assets and collectables. Which is why Prostarter is welcoming NFTs.</p>
            <h1 className="per">12%</h1>
            <h3>DEDICATED ALLOCATION</h3>
         </div>
           </div>
        <div className="col-md-5 web">
          <div className="right">
            <h5>Watch this space</h5>
            <h1>COMING SOON</h1>
            <div className="count">
                    <Countdown date={new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() + 2,  0, 23, 59, 50, 100).getTime()} />
                    </div>
          <div className="time">
              <div>
                {/* <h1>04</h1> */}
                <span>DAYS</span>
              </div>
              {/* <span className="line"></span> */}
              <div>
                {/* <h1>21</h1> */}
                <span>HOURS</span>
              </div>
              {/* <span className="line"></span> */}

              <div>
                {/* <h1>52</h1> */}
                <span>MINUTES</span>
              </div>
              {/* <span className="line"></span> */}

              <div>
                {/* <h1>02</h1> */}
                <span>SECONDS</span>
              </div>
  
            </div>
          


          </div>
        </div>
        <div className="col-md-12">
          <Footer />
        </div>
      </div>
        )
    }
}

export default NFT;
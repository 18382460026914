import React, {Component} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import { getUserAddress } from '../../web3Helper';
import axios from 'axios';
import success from '../../images/dashboard/success.svg';
import error from '../../images/dashboard/error.svg';
import { getIdoCurrency, getChainSymbol } from '../../utils';
import { FormattedNumber, FormattedDate } from 'react-intl';
import ProjectStatus from '../ProjectStatus';
import Loader from 'react-loader-spinner';

const MandatoryCheckboxes = [
  "follow-ido-twitter",
  "follow-ido-official-group",
  "follow-ido-announcement-channel",
  "follow-prostarter-twitter",
  "follow-prostarter-announcement-channel",
  "follow-prostarter-official-group",
  "retweet-whitelisting-tweet"
];

class WhiteList extends Component {
 
    state = {
      status : false,
      activeAccountAddress: '',
      pool: 'prot',
      isLoading: false,
      project: null,
      err: '',
      isError: false,
      checkArray: [],
      isMeta: false,
      isFormProcessing: false,
      data: {},
      isEdit: false,
      isProking: false,
      html:  `
      <h1>Heading</h1>
      <p>Paragraph</p>
    `
    }

    getIdo = async () => {
      const { match } = this.props;
      try {
        this.setState({
          isLoading: true
        })
        const response = await axios.get(`/ido/${match.params.id}`);
        this.setState({project: response.data.ido, isLoading: false}, this.checkAddress);
      }
      catch (e){
        this.setState({
          isLoading: false,
        })
        console.log('Failed to fetch IDO,', match.params.id, e )
      }
    }

    async componentDidMount  () {
      getUserAddress().then((account) => !!account && this.setState({ activeAccountAddress: account }, this.getIdo));
    }

    checkAddress = () => {
      const { activeAccountAddress } = this.state;
      const { match } = this.props;
      if(activeAccountAddress === null) {
        console.log(activeAccountAddress)
        return this.setState({isMeta: false});
      } 

      this.setState({isMeta: true});
      axios.get(`/whitelist/${activeAccountAddress}`, { params: { projectId: match.params.id } })
        .then(res => {
          this.setState({status: true, data: res.data.whitelist, checkArray: res.data.whitelist.consents });
        })
        .catch(err=> console.log(err))

      axios.get(`/user/${activeAccountAddress}`).then((userResp) => {
        this.setState({
          isProking: !!userResp.data && !!userResp.data.user && !!userResp.data.user.prokingStatus 
        });
      })
      .catch(err=> console.log('Unable to fetch user',err))
    }

    submitWhiteList  = () => {
      const {
        pool,
        activeAccountAddress,
        checkArray,
        isProking,
        project
      } = this.state;

      const mandatoryCheckBoxesFilled = !isProking ? MandatoryCheckboxes
        .filter((cArr) => {
          if(cArr === 'follow-ido-twitter' && !project.twitterLink) {
            return false;
          } else if (cArr === 'follow-ido-official-group' && !project.telegramLink) {
            return false;
          } else if (cArr === 'follow-ido-announcement-channel' && !project.telegramAnnouncementLink) {
            return false;
          }
          return true;
        })
        .every((cArr) => checkArray.includes(cArr)) : true;

      this.setState({isError: false, err: ''});
      
      if( !project ) {
        return this.setState({isError: true, err: 'Project Unavailable!'})
      }

      if(this.Name.value === '' || this.Email.value === ''|| activeAccountAddress  === '' || this.Telegram.value === ''
      || this.Twitter.value === '' ) {
        return this.setState({err: 'Fields are required', isError: true})
      }
      else if ( !mandatoryCheckBoxesFilled ) {
        return this.setState({isError: true, err: 'Check boxes are requires'})

      }
      
      this.setState({
        isFormProcessing: true
      })

      axios.post('/whitelist',{
        fullName: this.Name.value,
        email: this.Email.value,
        projectId: project._id,
        address: activeAccountAddress,
        pool,
        telegramHandle: this.Telegram.value,
        twitterhandle: this.Twitter.value,
        tweetLink: this.tweeterLink.value,
        consents: [...checkArray]
      })
      .then(res=> {
        this.setState({ 
          status: true,
          isFormProcessing: false,
          data: res.data.whitelist,
         });
      })
      .catch(err=>{
        this.setState({ isFormProcessing: false });
      });
    }

    updateWhiteList = () => {
      const {
        pool,
        activeAccountAddress,
        data,
        checkArray,
        isProking,
        project
      } = this.state;
      const mandatoryCheckBoxesFilled = !isProking ? MandatoryCheckboxes
        .filter((cArr) => {
          if(cArr === 'follow-ido-twitter' && !project.twitterLink) {
            return false;
          } else if (cArr === 'follow-ido-official-group' && !project.telegramLink) {
            return false;
          } else if (cArr === 'follow-ido-announcement-channel' && !project.telegramAnnouncementLink) {
            return false;
          }
          return true;
        })
        .every((cArr) => checkArray.includes(cArr)) : true;

      this.setState({isError: false, err: ''});

      if( !project ) {
        return this.setState({isError: true, err: 'Project Unavailable!'})
      }

      if(this.Name.value === '' || this.Email.value === ''|| activeAccountAddress  === '' || this.Telegram.value === ''
      || this.Twitter.value === '' ) {
        return this.setState({err: 'Fields are required', isError: true})
      }
      else if ( !mandatoryCheckBoxesFilled ) {
        return this.setState({isError: true, err: 'Check boxes are requires'})

      }  

      this.setState({ isFormProcessing: true });

      axios.patch(`/whitelist/${data._id}`,{
        fullName: this.Name.value,
        email: this.Email.value,
        projectId: project._id,
        address: activeAccountAddress,
        pool: pool,
        telegramHandle: this.Telegram.value,
        twitterhandle: this.Twitter.value,
        tweetLink: this.tweeterLink.value,
        consents: [...checkArray]
      })
      .then(res => {
        this.setState({
          status: true,
          data: res.data.whitelist,
          isFormProcessing: false
        });
      })
      .catch(err=>{
        this.setState({ isFormProcessing: false });
      });
    }
    checkboxChecked = (value) => {
      const {checkArray} = this.state;
      this.setState({
        checkArray: checkArray.find((cArr) => cArr === value) ? 
          checkArray.filter((cArr) => cArr !== value) : [...checkArray, value]
        }
      )
    }

  render() {
    const {
      status,
      project,
      activeAccountAddress,
      isEdit,
      checkArray,
      pool,
      err,isMeta,data,
      isError,
      isFormProcessing,
      isLoading
    } = this.state;

    return (
      <div className="row no-gutters apply">
      <div className="left col-lg-6 col-sm-6">
        { !project ? 
            <>
              {
              isLoading ?
                <Loader
                  type="Puff"
                  color="#ffffff"
                  className="d-inline mr-1"
                  height={ 35 }
                  width={ 35 }
                />
              : <p style={{
                  color: '#5ff7ff',
                  fontSize: '22px',
                  marginTop: '10rem',
                  textAlign: 'center'
                }}>
                  Project Not Found.
                </p>  
              }
            </>
          :
          <>
            <div className="top my-2">
              <img src={process.env.REACT_APP_API_URL + project.logoUrl} alt="" width="10%" />
              <h6 className="ml-2 flex-grow-1">
                {project.name}
              </h6>
              <ProjectStatus project={project} />
            </div>
            <Scrollbars style={ { height: 460, width: '100%' } }
              className="scroll"
              renderTrackHorizontal={ props => <div { ...props } className="track-horizontal"/> }
              renderTrackVertical={ props => <div { ...props } className="track-vertical"/> }>
                <>
                  <h5>Announcing {project.name || '-'} Whitelisting on Prostarter Launchpad</h5>
                  {
                    project.description && project.description.split('\n').map((description) => <p>{description}</p>)
                  }
                  {
                    project.whitelistingApplicationOpenImage &&
                    <img src={process.env.REACT_APP_API_URL + project.whitelistingApplicationOpenImage} width="97%" alt="" />
                  }
                  <h5>{project.name} {project.type === 'GAME' ? 'IGO' : 'IDO' } Detail</h5>
                
                  <ul>
                    <li>Live {project.type === 'GAME' ? 'IGO' : 'IDO' } Whitelisting Date: { project.liveWhitelistingStartDate ? <FormattedDate value={project.liveWhitelistingStartDate} /> : 'To Be Announced' }</li>
                    {/* eslint-disable-next-line react/style-prop-object */}
                    <li>Total Allocation: { project.allocationSize ? <FormattedNumber maximumFractionDigits={0} currency="USD" style="currency"  value={project.totalRaise} /> : '-' }</li>
                    {/* eslint-disable-next-line react/style-prop-object */}
                    <li>Max individual allocation: { project.allocationSize ? <FormattedNumber maximumFractionDigits={0} currency="USD" style="currency" value={project.allocationSize} /> : '-' }</li>
                    <li>{ project.protAllocations || '-' } Allocations for $PROT Pool</li>
                    <li>{ project.publicAllocations || '-' } Allocations for Public Pool</li>
                    <li>{ project.name || '-' } project is built on {getChainSymbol(project.blockChainNetworkId)} so winners will have to use {getIdoCurrency(project.contractType, project.blockChainNetworkId)} to claim their allocation.</li>
                  </ul>
                  <h5>Important Information</h5>
                  <p>Follow the important information regarding { project.tokenSymbol || '-' } {project.type === 'GAME' ? 'IGO' : 'IDO' } Whitelisting.</p>
                  <ul>
                    <li>No invalid BEP-20/ERC20 addresses, Telegram handles, or Twitter accounts</li>
                    <li>Entries by bots will not be accepted.</li>
                    <li>Entries with duplicate identities will be rejected.</li>
                    <li>Entries without following the required steps will not be accepted.</li>
                    <li>{project.type === 'GAME' ? 'IGO' : 'IDO' } Whitelisting for token { project.tokenSymbol || '-' } will be held live on the Prostarter launchpad on { project.liveWhitelistingStartDate ? <FormattedDate value={project.liveWhitelistingStartDate} /> : 'To Be Announced' }.</li>
                    <li>Once whitelisted, users will have 24 hours to pass the KYC process.</li>
                  </ul>  
                </>        
            </Scrollbars>
          </>
        }
      </div>
      <div className="right col-lg-6 col-sm-6">
        {
          !project ?
            <>
              {
                isLoading ?
                  <Loader
                    type="Puff"
                    color="#ffffff"
                    className="d-inline mr-1"
                    height={ 35 }
                    width={ 35 }
                  />
                : <p style={{
                    color: '#5ff7ff',
                    fontSize: '22px',
                    marginTop: '10rem'
                  }}>
                    Project Not Found.
                  </p>
              }
            </>
          : project.status === 'WHITELISTING_APPLICATION_OPEN' ?
          <>
            { !isMeta && <p style={{marginTop: '10rem'}}>Connect Wallet to process your Application.</p>}
            {!status && isMeta && <>
              <Scrollbars style={ { height: 460, width: '100%' } }
                className="scroll"
                renderTrackHorizontal={ props => <div { ...props } className="track-horizontal"/> }
                renderTrackVertical={ props => <div { ...props } className="track-vertical"/> }>
                  <h4>APPLY FOR WHITE LISTING</h4>
                  <p>Welcome to Prostarter Whitelisting! </p>
                  <p>Please fill out the form and perform all tasks accordingly. Ensure that the information submitted is accurate as it will be used for KYC afterwards.
                    If you have any questions, please contact us on <a target="_blank" rel="noreferrer" href="https://t.me/Prostarter">Telegram</a>.</p>
                  <p> Good luck and thank you for your support!</p>
                  < hr />
                    <div className="form-div">
                    <h6>Full Name</h6>
                  <input type="text" defaultValue={isEdit ? data.fullName : '' } ref={ (r) => { this.Name = r; } }  className="form-control" placeholder=""/>
                    </div>
                    <div className="form-div">
                    <h6>Email Address</h6>
                  <input  type="text" defaultValue={isEdit? data.email : ''} ref={ (r) => { this.Email = r; } }  className="form-control" placeholder=""/>
                    </div>
                    <div className="form-div">
                    <h6>ERC-20 Wallet Address</h6>
                    <p>{activeAccountAddress}</p>
                    </div>
                    <h6>Select Pool</h6>
                    <div className="pool-div">
                      <button onClick={()=> this.setState({pool: 'prot'})} className={`${pool === 'prot' ? 'active': ''} global-btn btn`}>$PROT POOL</button>
                      <button onClick={()=> this.setState({pool: 'public'})} className={`${pool === 'public' ? 'active': ''} global-btn btn`}>PUBLIC POOL</button>
                    </div>
                    <div className="form-div">
                    <h6>Telegram Handle</h6>
                  <input type="text" defaultValue={isEdit? data.telegramHandle : ''} className="form-control" ref={ (r) => { this.Telegram = r; } } placeholder="For example @michealjordan11"/>
                    </div>
                    <div className="form-div">
                    <h6>Twitter Handle</h6>
                  <input type="text" defaultValue={isEdit? data.twitterhandle : ''} className="form-control" ref={ (r) => { this.Twitter = r; } } placeholder=" For example michealjordan11"/>
                    </div>
                    <div className="form-div">
                    <h6>Paste the tweet link where you've tweeted about your favourite feature of {project.name} using the hashtags { project.tokenSymbol || '-' }, #Prostarter and $PROT (Optional)</h6>
                  <input type="text" defaultValue={isEdit? data.tweetLink : ''} className="form-control" ref={ (r) => { this.tweeterLink = r; } }
                    placeholder="https://twitter.com/ProtOfficial/status/1388946012862128128"/>
                    </div>

                    <h6>Have You followed the below steps</h6>
                    {
                      project.twitterLink && 
                      <label>
                        <Checkbox
                          checked={checkArray.find((cArr) => cArr === "follow-ido-twitter")}
                          id="follow-ido-twitter"
                          type="checkbox"
                          onChange={({ target: { id } }) => this.checkboxChecked(id)}
                        />
                        <div>
                          Have you followed <a className="link" target="_blank" rel="noreferrer" href={ project.twitterLink || '#' }>{project.name} Twitter</a> Account
                        </div>
                      </label>
                    }
                    {
                      project.telegramLink &&
                      <label>
                        <Checkbox
                          checked={checkArray.find((cArr) => cArr === "follow-ido-official-group")}
                          id="follow-ido-official-group"
                          type="checkbox"
                          onChange={({ target: { id } }) => this.checkboxChecked(id)}
                        />
                        <div>
                          Join <a className="link" target="_blank" rel="noreferrer" href={ project.telegramLink || '#' }>{project.name} Telegram</a>  Community Group
                        </div>
                      </label>
                    }
                    {
                      project.telegramAnnouncementLink &&
                      <label>
                        <Checkbox
                          checked={checkArray.find((cArr) => cArr === "follow-ido-announcement-channel")}
                          id="follow-ido-announcement-channel"
                          type="checkbox"
                          onChange={({ target: { id } }) => this.checkboxChecked(id)}
                        />
                        <div>
                          Join <a className="link" target="_blank" rel="noreferrer" href={ project.telegramAnnouncementLink || '#' }>{project.name} Annoucement Channel</a>  on Telegram
                        </div>
                      </label>
                    }
                    <label>
                      <Checkbox
                        checked={checkArray.find((cArr) => cArr === "follow-prostarter-twitter")}
                        id="follow-prostarter-twitter"
                        type="checkbox"
                        onChange={({ target: { id } }) => this.checkboxChecked(id)}
                      />
                      <div>
                        Follow <a className="link" target="_blank" rel="noreferrer" href="https://twitter.com/ProtOfficial">Prostarter on Twitter</a>
                      </div>
                    </label>
                    <label>
                      <Checkbox
                        checked={checkArray.find((cArr) => cArr === "follow-prostarter-announcement-channel")}
                        id="follow-prostarter-announcement-channel"
                        type="checkbox"
                        onChange={({ target: { id } }) => this.checkboxChecked(id)}
                      />
                      <div>
                        Join <a className="link" target="_blank" rel="noreferrer" href="https://t.me/Prot_ann">Prostarter Annoucement Channel</a> on Telegram
                      </div>
                    </label>
                    <label>
                      <Checkbox
                        checked={checkArray.find((cArr) => cArr === "follow-prostarter-official-group")}
                        id="follow-prostarter-official-group"
                        type="checkbox"
                        onChange={({ target: { id } }) => this.checkboxChecked(id)}
                      />
                      <div>
                        Join <a className="link" target="_blank" rel="noreferrer" href="https://t.me/Prostarter">Prostarter Official Group</a> on Telegram
                      </div>
                    </label>
                    <label>
                      <Checkbox
                        checked={checkArray.find((cArr) => cArr === "retweet-whitelisting-tweet")}
                        id="retweet-whitelisting-tweet"
                        type="checkbox"
                        onChange={({ target: { id } }) => this.checkboxChecked(id)}
                      />
                      <div>
                        Retweet the { project.tokenSymbol || '-' } {project.type === 'GAME' ? 'IGO' : 'IDO' } Whitelisting tweet.
                      </div>
                    </label>
                    <label>
                      <Checkbox
                        checked={checkArray.find((cArr) => cArr === "tweet-feature-with-hashtags")}
                        id="tweet-feature-with-hashtags"
                        type="checkbox"
                        onChange={({ target: { id } }) => this.checkboxChecked(id)}
                      />
                      <div>
                        Tweet your favorite feature of the { project.name } project using the hashtags { project.tokenSymbol || '-' }, $PROT, #Prostarter (Optional)
                      </div>
                    </label>
                  </Scrollbars>
                <div >
                  {isError && <p className="error"><img src={error} alt="" />{err}</p>}
                  <button disabled={isFormProcessing} onClick={isEdit ? this.updateWhiteList : this.submitWhiteList} className="btn global-btn">{ !!isFormProcessing ? 'Please Wait...' : isEdit ? 'Update': 'Submit' }</button>
                </div>
              </>
            }
          { !!status && !!isMeta && 
            <div className="success-div">
              <img src={success} alt="" />
              <h3>Thank you! Your Application is received!</h3>
              <p>If you are whitelisted you'll be notified via email from support@prostarter.io</p>
              <button 
                onClick={()=> this.setState({
                  isEdit: true,
                  status: false,
                  pool: data.pool
                })}
                className="btn edit"
              >
                Edit {project.type === 'GAME' ? 'IGO' : 'IDO' } Application
              </button>
            </div>
          }
        </>
        :
          <p style={{
            color: '#5ff7ff',
            fontSize: '22px',
            marginTop: '10rem'
          }}>
            Whitelisting Application is Closed
          </p>
        } 
      </div> 
    </div>)
  }
}

export default WhiteList;
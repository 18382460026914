import getWeb3, { ether, getWeb3IfAvailable, parseError, toNumber } from "./web3Utils";
import { toast } from 'react-toastify';
import Idosale from './contracts/Idosale.json';
import { getContractABI, getContractAddress } from "./utils/abiHelper";

const idoClaimedCallback = (event) => {
  if( event && event.returnValues && event.returnValues.amount) {
    toast.success(`Tokens Claimed.`);
  }
}

export const getUserBalance = async () => {
  const web3 = await getWeb3IfAvailable();
  const accounts = await web3.eth.getAccounts();
  return toNumber( await web3.eth.getBalance(accounts[0]) );
}

export const getBlockChainNetworkId = async () => {
  const web3 = await getWeb3IfAvailable();
  return web3.eth.net.getId();
  }
export const getUserAddress = () =>
  new Promise( async (resolve, reject) => {
    getWeb3IfAvailable().then( async (web3) => {
      const accounts = await web3.eth.getAccounts();
      resolve(accounts && accounts.length ? accounts[0] : null);
    }).catch(reject)
  }
)

export const getProtBalance = async () => {

  const web3 = await getWeb3IfAvailable();
  const abi = getContractABI('ProtToken');
  const contractAddress = await getContractAddress('ProtToken');
  const accounts = await web3.eth.getAccounts();

  const contractInstance = new web3.eth.Contract( abi, contractAddress );
  let balance = 0;
  try {
    balance = toNumber(await contractInstance.methods.balanceOf(accounts[0]).call());
  }catch(e) {
    console.log(e);
  }
  return balance;
}

export const claimVest = ({ 
  vestId,
  contractType,
  contractAddress
}) =>
  new Promise( async (resolve, reject) => {
    getWeb3IfAvailable().then( async (web3) => {
      const accounts = await web3.eth.getAccounts();
      const gasPrice = await web3.eth.getGasPrice();

      if( accounts && !!accounts.length ) {
        
        const abi = getContractABI(contractType)
        const contractInstance = new web3.eth.Contract( abi, contractAddress );
      
        try {
          
          let estimatedGas = await contractInstance.methods.claimVest( 
            accounts[0],
            vestId,
          ).estimateGas({
            from: accounts[0]
          });
          
          await contractInstance.methods.claimVest(
            accounts[0],
            vestId,
          ).send({ 
            from: accounts[0],
            gasPrice,
            gas: estimatedGas + 5000
          });
        }
        catch(e) {
          const parsedErrorMessage = parseError(e);
          if(parsedErrorMessage.includes('Already Claimed')) {
            reject('Already Claimed')
          } else {
            reject(parsedErrorMessage)
          }
          console.log('Caught error while claiming vest',e);
        }
        resolve('Vest claimed successfully')
      }
    }).catch(reject)
  }
)

export const checkClaim = ({ 
  vestId,
  contractType,
  contractAddress
}) =>
  new Promise( async (resolve, reject) => {
    getWeb3IfAvailable().then( async (web3) => {
      const accounts = await web3.eth.getAccounts();
      let isClaimed = false;

      if( !!vestId && 
          !!contractAddress &&
          contractType  &&
          accounts &&
          !!accounts.length ) {
        
        const abi = getContractABI(contractType)
        const contractInstance = new web3.eth.Contract( abi, contractAddress );
      
        try {
          const vest = await contractInstance.methods.getUserVest(
            accounts[0],
            vestId,
          ).call();
          isClaimed = vest.isClaimed;
        }
        catch(e) {
          const parsedErrorMessage = parseError(e);
          console.log('Caught error while fetching vest', parsedErrorMessage);
          reject('Unable to fetch vest status')
        }
        resolve(isClaimed)
      } else {
        reject('Unable to fetch vest status')
      }
    }).catch(reject)
  }
)


export const getTotalTokensAllocated = async ({
  contractAddress,
  contractType
}) => {

  const web3 = await getWeb3IfAvailable();
  const abi = getContractABI(contractType);
  const contractInstance = new web3.eth.Contract( abi, contractAddress );
  const totalTokensAllocated = await contractInstance.methods.getTotalTokensAllocated().call();
  return toNumber(totalTokensAllocated);
}

export const getContractUser = async ({
  contractAddress,
  contractType
}) => {

  const web3 = await getWeb3IfAvailable();
  const abi = getContractABI(contractType);
  const accounts = await web3.eth.getAccounts();
  const contractInstance = new web3.eth.Contract( abi, contractAddress );

  const user = await contractInstance.methods.getUser(
    accounts[0],
  ).call();

  return user;
}

export const getEthRaised = async ({
  contractAddress,
  contractType
}) => {

  const web3 = await getWeb3IfAvailable();
  const abi = getContractABI(contractType);
  const contractInstance = new web3.eth.Contract( abi, contractAddress );
  const getEthRaised = await contractInstance.methods.getTotalEthRaised().call();
  return toNumber(getEthRaised);
}

export const buyTokens = ({
  contractAddress,
  value,
  contractType
}, onlyEstiamte = false ) => new Promise( async (resolve, reject) => {

  getWeb3IfAvailable().then( async (web3) => {

    const gasPrice = await web3.eth.getGasPrice();
    // Use web3 to get the user's accounts.
    const accounts = await web3.eth.getAccounts();

    const abi = getContractABI(contractType);
    const contractInstance = new web3.eth.Contract( abi, contractAddress );

    try {
      if(contractType === 'IdoSaleWithVestingByTokens') {
        const estimatedGas = await contractInstance.methods.buyTokens( 
          accounts[0],
          new web3.utils.BN(web3.utils.toWei(value.toString(), 'ether'))
        ).estimateGas({
          from: accounts[0]
        });
        
        if( !!onlyEstiamte ) {
          resolve(estimatedGas)
        } else {
          await contractInstance
          .methods
          .buyTokens( accounts[0], new web3.utils.BN(web3.utils.toWei(value.toString(), 'ether')) )
          .send({ 
            from: accounts[0],
            gasPrice,
            gas: estimatedGas + 5000,
          });
          resolve('Tokens brought successfully')
        }
      } else {
        const estimatedGas = await contractInstance.methods.buyTokens( 
          accounts[0]
        ).estimateGas({
          from: accounts[0],
          value: new web3.utils.BN(web3.utils.toWei(value.toString(), 'ether')), 
        });
        
        if( !!onlyEstiamte ) {
          resolve(estimatedGas)
        } else {
          await contractInstance
          .methods
          .buyTokens( accounts[0] )
          .send({ 
            from: accounts[0],
            gasPrice,
            gas: estimatedGas + 5000,
            value: new web3.utils.BN(web3.utils.toWei(value.toString(), 'ether')), 
          });
          resolve('Tokens brought successfully')
        }
      }
    } catch(e) {
      const parsedErrorMessage = parseError(e);
      reject(parsedErrorMessage)
      console.log('Caught error while buying tokens',e);
    }
  }).catch(reject)
});

export const approveTokens = async  ({
  approvalAmount,
  contractType = 'UsdtToken',
  spenderAddress
}) => {
  const web3 = await getWeb3IfAvailable()
  const accounts = await web3.eth.getAccounts();
  const gasPrice = await web3.eth.getGasPrice();
  const abi = await getContractABI(contractType);
  const tokenAddress = await getContractAddress(contractType);
  
  const tokenInstance = new web3.eth.Contract(
    abi,
    tokenAddress
  );

  try {
    let estimatedGas = await tokenInstance.methods
    .approve( 
      spenderAddress,
      ether(approvalAmount).toString(),
    )
    .estimateGas({
      from: accounts[0]
    });
    await tokenInstance.methods.approve(
      spenderAddress,
      ether(approvalAmount).toString(),
    ).send({ from: accounts[0], gasPrice, gas: estimatedGas + 5000 });
  } catch (e) {
    console.log('EVM Error', e);
    const parsedErrorMessage = parseError(e);
    throw parsedErrorMessage
  }
      
  return 'Tokens approved successfully.';
}

export const balanceOf = async ({
  tokenAddress = '',
}) => {

  const web3 = await getWeb3IfAvailable()
  const accounts = await web3.eth.getAccounts();
  const abi = await getContractABI('UsdtToken');
  tokenAddress = await getContractAddress('UsdtToken');

  const tokenInstance = new web3.eth.Contract(
    abi,
    tokenAddress
  );

  try {

    const balance = await tokenInstance.methods.balanceOf(
      accounts[0],
    ).call();

    return toNumber(balance);
  } catch (e) {
    console.log('EVM Error', e);
    const parsedErrorMessage = parseError(e);
    throw parsedErrorMessage
  }
}

export const allowance = async ({
  spenderAddress,
  contractType = 'UsdtToken'
}) => {

  const web3 = await getWeb3IfAvailable()
  const accounts = await web3.eth.getAccounts();
  const abi = await getContractABI(contractType);
  const tokenAddress = await getContractAddress(contractType);

  const tokenInstance = new web3.eth.Contract(
    abi,
    tokenAddress
  );

  try {

    const allowance = await tokenInstance.methods.allowance(
      accounts[0],
      spenderAddress,
    ).call();

    return toNumber(allowance);
  } catch (e) {
    console.log('EVM Error', e);
    const parsedErrorMessage = parseError(e);
    throw parsedErrorMessage
  }
}

export const claimIdoSaleTokens = ({ contractAddress }) => new Promise( async (resolve, reject) => {

  if(!contractAddress) reject('Contract Address Unavailable!');

  getWeb3().then( async (web3) => {

    const gasPrice = await web3.eth.getGasPrice();
    // Use web3 to get the user's accounts.
    const accounts = await web3.eth.getAccounts();

    const contractInstance = new web3.eth.Contract(
      Idosale.abi,
      contractAddress
    );

    try {
      let estimatedGas = await contractInstance.methods
        .claim( 
          accounts[0]
        )
        .estimateGas({
          from: accounts[0]
        });

      contractInstance.events.TokensClaimed({
        filter: { beneficiary: accounts[0] },
        fromBlock: 'latest'
      })
      .on('data', (event) => idoClaimedCallback(event))
      .on('error', () => toast.error("Transaction Error."))

      const contractResponse = await contractInstance
        .methods
        .claim( accounts[0] )
        .send({ 
          from: accounts[0],
          gasPrice,
          gas: estimatedGas + 5000
        });
        
      resolve(contractResponse)
      
    } catch(e) {
      console.log('EVM Error', e);
      const parsedErrorMessage = parseError(e);
      reject(parsedErrorMessage) 
    }
  })
})

export default getWeb3;
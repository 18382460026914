import React from 'react';
import Countdown from 'react-countdown';
import tick from '../images/landing/tick.svg';

const ProjectStatus = ({project}) => (
  
  project.tokensSold && project.tokensSold === project.tokensToBeDistributed ? 
    <button disabled={true} className="btn filed"><img src={tick} className="mr-1" alt="" />Filled</button>
  :
    <button className="btn top-btn">
      { 
        project.status === "UPCOMING" && !!project.startDate ? 
        <div className="count">
          <Countdown date={new Date(project.startDate).getTime()} />
        </div> :
        project.status === "UPCOMING" ? 
        'Upcoming' :
        project.status === "WHITELISTING_APPLICATION_OPEN" ?
        'Applications Open': 
        project.status === 'WHITELISTING_APPLICATION_CLOSED' && !!project.liveWhitelistingStartDate ?
        <div className="count">
          <Countdown date={new Date(project.liveWhitelistingStartDate).getTime()} />
        </div> :
        project.status === 'WHITELISTING_APPLICATION_CLOSED' ?
        'Applications Closed' :
        project.status === 'LIVE_WHITELISTING_OPEN' || project.status === 'LIVE_WHITELISTING_CLOSED' ? 
        'Live' : 
        'Completed' 
      }
    </button>
)

export default ProjectStatus;
import React, { Component } from 'react';

import { withRouter, Link } from 'react-router-dom';

import  logo from '../images/light/logo.png';
import light from '../images/light/light.svg';
// import search from '../images/search.png';
class LightNavbar extends Component {
  
    // changeTheme = () => {
    //     console.log('ddsd');
    //     localStorage.setItem('theme', 'dark');
    //   }

  render() {
   const { history } = this.props;
   const path = history.location.pathname.split('/');
    return (
      <nav className="navbar light sticky-top navbar-fixed-top navbar-expand-lg navbar-light">
        <Link className="navbar-brand" to="/">
          <img src={ logo } alt="" />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
           <ul className="nav left  navbar-nav">
            <li className="nav-item">
              <Link className={`${path[1] === 'home'? 'active' : ''}`} to="/home">Home</Link>
            </li>
            <li className="nav-item line">
              |
            </li>
            <li className="nav-item ">
              <Link className={`${path[1] === 'pool'? 'active' : ''}`} to="/pool">Pools</Link>
            </li>
            <li className="nav-item line">
              |
            </li>
            <li className="nav-item">
              <Link className={`${path[1] === 'fav'? 'active' : ''}`} to="/favourite-page">About</Link>
            </li> 
            <li className="nav-item line">
              |
            </li>
           
            <li className="nav-item">
              <Link className={`${path[1] === 'fav'? 'active' : ''}`} to="/favourite-page">Whitelisting</Link>
            </li>
          </ul>
          <ul className="right nav navbar-nav ml-auto">
              <li className="nav-item img-item">
                    <img onClick={()=>this.props.changeTheme('dark')} src={light} alt="" width="65%"/>
             </li>
              <li className="nav-item">
                  {/* <img className="search" src={search} /> */}
                  <Link className={`${path[1] === 'dashboard'? 'active' : ''} btn`} to="/dashboard">Dashboard</Link>
              </li>
              <li className="nav-item">
                  {/* <img className="search" src={search} /> */}
                  <Link className={`${path[1] === 'connect'? 'active' : ''} btn`} >Connect Wallet</Link>
              </li>
            {/* {type === 'public' && this.renderLoggedInMenu()} */}
          </ul>
        </div>
      </nav>
    );
  }
}

export default withRouter(LightNavbar);

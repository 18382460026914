import React, {Component} from 'react';
import Footer from '../components/footer';
import Carousal from '../components/pool/carousal';
import project from '../images/project.svg';

class Pool extends Component {

  render() {
    return (
       <div className="row no-gutters pool">
         <div className="col-md-12 top-div">
             <img src={project} alt="" />
           <h1>Prostarter Project Alpha</h1>
           <small>0x862e6e9Fe5BAF7e1004Bf9F01613B73ACC8532e9</small>
           <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
           <button className="btn">Join  Pool</button>
           <button className="btn">View Detail</button>
         </div>
         <div className="col-md-12 carousal-div">
        <Carousal />
         </div>
         <div className="col-md-12">
           <Footer />
         </div>
          </div>
        )
    }
}

export default Pool;
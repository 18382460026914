import React, {Component} from 'react';
import Loader from 'react-loader-spinner';

class Benefits extends Component {
 
  render() {
    const { isLoading, benefits } = this.props;

    return (  
      <>
        {
          isLoading ?
            <div className="text-center">
              <Loader
                type="Puff"
                color="#ffffff"
                className="d-inline mr-1"
                height={ 50 }
                width={ 50 }
              /> 
            </div>
          :
          <div className="rules">
            <p className="ml-2">Here are the following reasons why this INO is an exciting opportunity for all of you that none of you would want to miss.</p>
            <ul dangerouslySetInnerHTML={{ __html: benefits }}>
            </ul>
          </div>
        }
      </>
    )
  }
}

export default Benefits;
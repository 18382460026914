import React, {Component} from 'react';
import {  Link } from 'react-router-dom';

import Footer from '../components/footer';

import tick from '../images/ido/tick.svg';
import arrow from '../images/ido/arrow.svg'
import arrowdark from '../images/ido/arrowdark.svg'

class IDO extends Component {
  

  render() {
    return (
      <div className="ido row no-gutters">
          <div className="col-md-3"></div>
          <div className="col-lg-6 col-sm-6 sub-div">
              <h1>Apply for <span>IDO!</span></h1>
              <p className="paragraph">Prostarter launchpad is welcoming all IDOs based on Ethereum and Binance Smart Chain whereas
                support for TRON based IDOs is coming soon. Everyone who is looking forward to raising funds for their
                blockchain projects is welcome to submit an IDO application on Prostarter</p>
        <p className="paragraph">We at Prostarter are aiming to offer deserving and premium blockchain projects for our community. All
            the stakeholders of Prostarter will review and vote your IDO application thoroughly to finalize your IDO
            selection. After reviewing your IDO application, Prostarter's representative will contact you through email
            regarding your project IDO application.</p>
            <h5>Eligibility Criteria</h5>
            <div className="img-div">
                <img src={ tick } alt="" />
                <p>If you want to raise funds for your <span>blockchain</span> project!</p>
            </div>
            <div className="img-div">
                <img src={ tick } alt="" />
                <p>Token must be on <span>Ethereum</span> or <span>Binance Smart Chain</span> Network!</p>
            </div>
            <div className="img-div">
                <img src={ tick } alt="" />
                <p>You are not among the sanctioned countries listed on our <Link>terms of service.</Link></p>
            </div>
            <a className="btn" target="_blank" rel="noreferrer" href="http://ido.prostarter.io">Continue to Sign Up</a>
            <br/>
          <a target="_blank" href="https://www.youtube.com/watch?v=9jporWOxz_A" rel="noreferrer"
           className="btn sec"><img className="ap" src={arrowdark} alt="" /> <img className="ap2" src={arrow} alt="" /> How to apply</a>
          </div>
       <div className="col-md-12">
          <Footer />
        </div>
      </div>
        )
    }
}

export default IDO;
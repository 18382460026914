import React, {Component} from 'react';
import Select from 'react-select';
import {withRouter} from 'react-router-dom';
import Footer from '../footer';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import { buyTokens, getTotalTokensAllocated, getUserAddress, getUserBalance, getBlockChainNetworkId, getContractUser, allowance, approveTokens, balanceOf } from '../../web3Helper';
import { toast } from 'react-toastify';
import { FormattedNumber } from 'react-intl';
import { getIdoCurrency, getChainSymbol } from '../../utils';
import swap from '../../images/whitelisting/swap2.svg';
import Countdown from 'react-countdown';
import TermsModal from '../whitelisting/termsModals';
import { toNumber, toRealNumber } from '../../web3Utils';
import binance from '../../images/keymile/binance.svg';
import ethereum from '../../images/keymile/ethereum.svg';
import polygon from '../../images/keymile/polygon.svg';

const defaultApprovalAmount = 1000000;
class GetAllocation extends Component {
  
  state = {
    option: [],
    balance: 0,
    toSelectValue: [],
    fromSelectValue: [],
    toOptions: [],
    defaultOptions: [
      { value: 'loading', label: 'Loading...' }
    ],
    isWarning: false,
    loader: false,
    isApprovalInProcess: false,
    checkAllowanceInProcess: false,
    type: '',
    tokensPerWei: 0,
    allocationSizeEth: 0,
    contractAddress: '',
    contractType: '',
    blockChainNetworkId: '',
    fromValue: 0,
    toValue: 0,
    totalTokensAllocated: 0,
    tokensSold: 0,
    tokensToBeDistributed: 0,
    logoUrl: '',
    saleDate: null,
    isDisclaimerModalOpen: false,
    disclaimerApproved: false,
    isTotalAllocationsLoading: true,
    hasAllowance: true,
    purchaseInitialised: false
  }

  componentDidMount() {
    const {match} = this.props;
    axios(`/ido/${match.params.id}`)
    .then( async (resp) => {
      const { ido } = resp.data;
      
      getBlockChainNetworkId()
        .then(id => this.setState({
          ...(
            id.toString() !== ido.blockChainNetworkId ? {
              isWarning: true,
            } : {
              isWarning: false,
            }
          ),
          blockChainNetworkId: id.toString(),
          option: [
            {
              value: getIdoCurrency(ido.contractType, id.toString()),
              label: getIdoCurrency(ido.contractType, id.toString()),
            }
          ],
          fromSelectValue: [
            {
              value: getIdoCurrency(ido.contractType, id.toString()),
              label: getIdoCurrency(ido.contractType, id.toString()),
            }
          ]
        }))
        .catch(err => {
          console.log('Error getting network',err);
          this.setState({ isWarning: true })
        });

      this.setState({
        logoUrl: ido.logoUrl,
        contractAddress: ido.contractAddress,
        contractType: ido.contractType,
        toSelectValue: [
          {value: ido.tokenSymbol, label: ido.tokenSymbol},
        ],
        toOptions: [
          {value: ido.tokenSymbol, label: ido.tokenSymbol},
        ],
        tokensSold: ido.tokensSold,
        tokensToBeDistributed: ido.tokensToBeDistributed,
        saleDate: ido.saleDate,
        tokensPerWei: ido.tokensPerWei,
        allocationSizeEth: ido.allocationSizeEth,
      });

      getUserAddress()
        .then( async (account) => {
          const balanceFunc = ido.contractType === 'IdoSaleWithVestingByTokens' ? balanceOf.bind(this, {}) : getUserBalance;
          balanceFunc()
            .then((balance) => {
              this.setState({ balance })
            })
            .catch((e) => console.log('Unable to fetch user balance', e))

          if(ido.contractAddress && !ido.tokensSold) {
            getTotalTokensAllocated({ contractAddress: ido.contractAddress, contractType: ido.contractType })
              .then((totalTokensAllocated) => 
                setTimeout(() => this.setState({ totalTokensAllocated, isTotalAllocationsLoading: false }), 5000)
              )
              .catch((e) => {
                console.log('Unable to fetch tokens allotted', e);
                setTimeout(() => this.setState({ isTotalAllocationsLoading: false }), 5000)
              })
          } else {
            setTimeout(() => this.setState({ isTotalAllocationsLoading: false }), 5000)
          }

          this.setState({ activeAccountAddress: account });
        })
    }).catch((err) => {
      console.log('IDO Unavailable!', err)
      toast.error('IDO Unavailable!')
    })
  }

  handleFromValueChange = (fromValue) => {
    const { tokensPerWei } = this.state;
    const toValue = parseFloat(fromValue) * tokensPerWei;

    this.setState({
      fromValue,
      toValue: isNaN(toValue) ? 0 : toValue
    })
  }

  initSwap = async () => {
    const { contractAddress, fromValue, disclaimerApproved, contractType } = this.state;
    try {
      
      if(isNaN(Number(fromValue))) return toast.error('Invalid Value!');

      if(!contractAddress) return;
      if(!disclaimerApproved) return this.setState({isDisclaimerModalOpen: true});

      const latestUserBalance = await (contractType === 'IdoSaleWithVestingByTokens' ? balanceOf.bind(this, {}) : getUserBalance)();

      if( Number(fromValue) > latestUserBalance ) {
        return toast.error('Insufficient Balance!');
      }

      
      let newState = {
        checkAllowanceInProcess: false,
        balance: latestUserBalance,
        purchaseInitialised: true,
        hasAllowance: true
      };
      if(contractType === 'IdoSaleWithVestingByTokens') {
        this.setState({
          checkAllowanceInProcess: true
        })
        const currentAllowance = await allowance({
          spenderAddress: contractAddress,
        })
        if( currentAllowance < Number(fromValue) ) {
          newState['hasAllowance'] = false;
        }
      }
      this.setState({
        ...newState
      })
    } catch(err) {
      this.setState({checkAllowanceInProcess: false});
      toast.error(typeof err === 'string' ? err : err.message);
    }
  }

  handleApproval = () => {
    const { contractAddress } = this.state;

    this.setState({isApprovalInProcess: true});
    approveTokens({
      approvalAmount: defaultApprovalAmount,
      spenderAddress: contractAddress,
    }).then((message) => {
      this.setState({isApprovalInProcess: false, hasAllowance: true});
      toast.success('Tokens Approved!');
    })
    .catch((err) =>{
      this.setState({isApprovalInProcess: false});
      toast.error(typeof err === 'string' ? err : err.message);
    });
  }

  handleSwap = () => {
    const { contractAddress, fromValue, contractType } = this.state;
    this.setState({
      loader:true
    })
    buyTokens({
      contractAddress,
      contractType, 
      value: fromValue
    }).then((message) => {
      this.setState({loader: false, fromValue: 0, toValue: 0, purchaseInitialised: false, hasAllowance: true});
      toast.success('Tokens Bought! The same will be available in accumulation tab in some time.');
    })
    .catch((err) =>{
      this.setState({loader: false});
      toast.error(typeof err === 'string' ? err : err.message);
    });
  }

  handleMaxClick = async () => {
    const { tokensPerWei, allocationSizeEth, contractAddress, balance, contractType } = this.state;

    if(!contractAddress) return;

    const user = await getContractUser({ contractAddress, contractType });
    const fromValue = (allocationSizeEth * (toRealNumber(user.allocations) || 1)) - toNumber(user.totalEthRaised);
    this.setState({
      fromValue: balance > fromValue ? fromValue > 0 ? fromValue : 0 : balance,
    }, () => {
      this.setState({
        toValue: parseFloat(this.state.fromValue) * tokensPerWei
      })
    })
    
  }

  render() {
    const{ 
      option, 
      toValue,
      fromValue,
      toOptions,
      toSelectValue,
      fromSelectValue,
      balance,
      loader,
      saleDate,
      isWarning,
      blockChainNetworkId,
      defaultOptions,
      logoUrl,
      isDisclaimerModalOpen,
      totalTokensAllocated,
      tokensSold,
      tokensToBeDistributed,
      contractType,
      isTotalAllocationsLoading,
      hasAllowance,
      purchaseInitialised,
      isApprovalInProcess,
      checkAllowanceInProcess
    } = this.state;
    const currentChainSymbol = getChainSymbol(blockChainNetworkId);

    return (
      <div className=" row no-gutters allocation">
       <div className="box">
          <h3>Get Allocation</h3>
          <TermsModal 
            toggle={isDisclaimerModalOpen}
            onCloseModal={()=> this.setState({isDisclaimerModalOpen: false})}
            onAgreedToTerms={() => this.setState(
              { disclaimerApproved: true }, 
              () => this.initSwap())
            }
          />
          {!isWarning &&<p className="sub-heading">Get your allocation instantly.</p>}
          {isWarning &&<p className="wrong">Wrong Network.</p>}
          <div className="sub-box">
            <div className="w-50 pl-2">
              <p className="font-weight-bold">From</p>
              <span>
                <input value={fromValue} disabled={isWarning} className="form-control"
                 placeholder="0.0" onChange={ (ev) => this.handleFromValueChange(ev.target.value) } />
              </span>
            </div>
            <div className="balance-div">
              <p className="title-2">
                Available Balance:&nbsp;
                <span className="font-weight-bold">
                  {balance} {getIdoCurrency(contractType, blockChainNetworkId)}
                </span>
              </p>
              <div className="value-div">
                <button disabled={isWarning} onClick={this.handleMaxClick} className="btn btn-sm font-weight-bold">Max</button>
                {
                  currentChainSymbol === 'BSC' ? <img width="30" className="mx-2" src={binance} alt=""/> :
                  currentChainSymbol === 'ETH' ? <img width="30" className="mx-2" src={ethereum} alt=""/> :
                  currentChainSymbol === 'MATIC' ? <img width="30" className="mx-2" src={polygon} alt=""/> : null
                }
                <Select
                  placeholder=""
                  isDisabled={isWarning}
                  className="react-select__menu chain-select"
                  onChange={ value => this.setState({ type: value.label }) }
                  value={fromSelectValue}
                  defaultValue={defaultOptions}
                  options={option} />
              </div>
            </div>

          </div>
          <img className="swap-img" src={swap} alt="" />
          <div className="sub-box">
            <div className="w-50  pl-2">
              <p className="font-weight-bold">To</p>
              <span>
                <input value={toValue} readOnly={true} className="form-control" placeholder="0.0" />
              </span>
            </div>
            <div className="balance-div">
              <p className="title-2">Remaining: 
                <span className="font-weight-bold ml-1">
                  {
                    isTotalAllocationsLoading ?
                      <Loader
                        type="Puff"
                        color="#ffffff"
                        className="d-inline"
                        height={ 15 }
                        width={ 15 }
                      />
                    : <FormattedNumber value={ tokensToBeDistributed - ( tokensSold || totalTokensAllocated ) } />
                  }
                </span>
              </p>
              <div className="value-div">
                <button className="btn btn-sm hide" >Max</button>
                <img className="mx-2" src={process.env.REACT_APP_API_URL + logoUrl} width="30" alt="" />
                <Select
                  isDisabled={isWarning}
                  placeholder=""
                  styles={{minWidth:'200px'}}
                  className="react-select__menu chain-select"
                  onChange={ value => this.setState({ type: value.label }) }
                  value={toSelectValue}
                  options={toOptions} />
              </div>
            </div>

          </div>
          {
            saleDate &&  new Date(saleDate) > new Date() ?
              <button disabled={true} className="btn swap-btn">
                Get your allocation ({<Countdown className="text-dark" date={ new Date(saleDate).getTime() } />})
              </button>
            : !hasAllowance ?
              <button disabled={isWarning || isApprovalInProcess} onClick={this.handleApproval} className="btn swap-btn">{
                isApprovalInProcess ?
                <Loader
                  type="Puff"
                  color="#ffffff"
                  height={ 40 }
                  width={ 40 } /> 
                : 'Approve Tokens'
              }</button>
            : purchaseInitialised ?
                <button disabled={isWarning || loader} onClick={this.handleSwap} className="btn swap-btn">{
                  loader ?
                  <Loader
                    type="Puff"
                    color="#ffffff"
                    height={ 40 }
                    width={ 40 } /> 
                  : 'Confirm Swap'
                }</button>
            :    
              <button disabled={isWarning || checkAllowanceInProcess} onClick={this.initSwap} className="btn swap-btn">
                {
                  checkAllowanceInProcess ?
                  <Loader
                    type="Puff"
                    color="#ffffff"
                    height={ 40 }
                    width={ 40 } /> 
                  : 'Swap'
                }
              </button>
          }
        </div>
        <div className="col-12">
          <Footer />
        </div>
      </div>
    )
    }
}

export default withRouter(GetAllocation);

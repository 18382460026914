import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FormattedNumber } from 'react-intl'
import ProjectStatus from '../ProjectStatus';
import { getTotalTokensAllocated } from '../../web3Helper';
import Loader from 'react-loader-spinner';
import binance from '../../images/keymile/binance.svg';
import ethereum from '../../images/keymile/ethereum.svg';
import polygon from '../../images/keymile/polygon.svg';
import { getChainSymbol } from '../../utils';

class Card extends Component {

  state = {
    totalTokensAllocated: 0,
    isTotalAllocationsLoading: true
  }

  componentDidMount() {
    const {project} = this.props;
    
    if(project.contractAddress && !project.tokensSold) {
      getTotalTokensAllocated({ contractAddress: project.contractAddress, contractType: project.contractType })
        .then((totalTokensAllocated) =>
          setTimeout(() => this.setState({ totalTokensAllocated, isTotalAllocationsLoading: false }), 5000)
        )
        .catch((e) => {
          console.log('Unable to fetch tokens allotted', e);
          setTimeout(() => this.setState({ isTotalAllocationsLoading: false }), 5000);
        })
    } else {
      setTimeout(() => this.setState({ isTotalAllocationsLoading: false }), 5000);
    }
  }

  render() {
    const {type, project, status, className} = this.props;
    const {totalTokensAllocated, isTotalAllocationsLoading} = this.state;
    const currentChainSymbol = getChainSymbol(project.blockChainNetworkId);
    return (
      <div className={ status === 'project' 
          ? "col-xl-4 col-lg-4 col-md-6 col-sm-12" 
          : "col-xl-3 col-lg-4 col-md-6 col-sm-12" 
        }>
       {project && <div className={`card ${ className ? className : '' }`}>
          <div className="card-body">
              <div className="card-heading mb-2">
                <img className="ido-logo mr-2" src={process.env.REACT_APP_API_URL + project.logoUrl}  height="70" width="70" alt=""/>
                <ProjectStatus project={project} />
              </div>
              <Link className="card-title" to={`/project-detail/${project._id}`}>{project.name}</Link>
              <div className="raise-div">
              <div>
                <small className="card-text ">TOTAL RAISE</small>
                  {/* eslint-disable-next-line react/style-prop-object */}
                <h4>{ !!project.isFree ? "FREE" : <FormattedNumber maximumFractionDigits={0} currency="USD" style="currency" value={project.totalRaise} /> } </h4>
              </div>
              <div>
                <small className="">NETWORK</small>
                {
                  currentChainSymbol === 'BSC' ? <img width="30" src={binance} alt=""/> :
                  currentChainSymbol === 'ETH' ? <img width="30" src={ethereum} alt=""/> :
                  currentChainSymbol === 'MATIC' ? <img width="30" src={polygon} alt=""/> : null
                }
              </div>
              </div>
              <small>PROGRESS</small>
                <ProgressBar variant="primary" animated now={ !isTotalAllocationsLoading ? ((project.tokensSold || totalTokensAllocated) * 100 / project.tokensToBeDistributed) : 0} />
              <div className="mid-div">
                <p>
                  {
                    isTotalAllocationsLoading ?
                      <Loader
                        type="Puff"
                        color="#ffffff"
                        className="d-inline mr-1"
                        height={ 15 }
                        width={ 15 }
                      />
                    : project.tokensToBeDistributed === 0 ? 0 : parseInt( (project.tokensSold || totalTokensAllocated) * 100 / project.tokensToBeDistributed)
                  }
                  %
                </p>
                <p>
                  {
                    isTotalAllocationsLoading ?
                      <Loader
                        type="Puff"
                        color="#ffffff"
                        className="d-inline mr-1"
                        height={ 15 }
                        width={ 15 }
                      />
                    : <FormattedNumber notation="compact" compactDisplay="short" value={project.tokensSold || totalTokensAllocated} />
                  }
                  /
                  <FormattedNumber notation="compact" compactDisplay="short" value={project.tokensToBeDistributed} /> {project.tokenSymbol.toUpperCase()}</p>
              </div>
              <div className="bottam-div">
                <div className="mr-1">
                  <small>PARTICIPANTS</small>
                  <p><FormattedNumber value={project.allocations} /></p>
                </div>
                <div className="mr-1">
                  <small>ALLOC. SIZE</small>
                  {/* eslint-disable-next-line react/style-prop-object */}
                  <p><FormattedNumber maximumFractionDigits={0} currency="USD" style="currency" value={project.allocationSize} /></p>
                </div>
                <div>
                  <small>ACCESS</small>
                  <p className="text-uppercase">
                    {
                      project.prokingOnly ? 'PRO KING' :
                      project.access.length > 1 ? `POOLS(${project.access.length})` : 
                      project.access.length ? project.access[0] :
                      '-'
                    }
                  </p>
                </div>
              </div>
            { type === 'pool' && <> {
                project.status === 'UPCOMING' ?
                  <button disabled className="btn global-btn ido-btn">UPCOMING</button> :
                project.status === 'WHITELISTING_APPLICATION_OPEN' ? 
                  <button
                    className="btn global-btn ido-btn"
                    onClick={()=> this.props.history.push({pathname: `/dashboard/whiteList-application/${project._id}` })}
                  >
                    Apply For Whitelisting
                  </button> :
                project.status === 'WHITELISTING_APPLICATION_CLOSED' ? 
                  <button disabled className="btn global-btn  ido-btn">Applications Closed</button> :
                project.status === 'LIVE_WHITELISTING_OPEN' ?
                  <button
                    onClick={()=> this.props.history.push(`/white-list/${project._id}`)}
                    className="btn global-btn ido-btn">
                    Live
                  </button>
                : project.status === 'LIVE_WHITELISTING_CLOSED' ?
                  <button
                    onClick={()=> this.props.history.push(`/allocation/${project._id}`)}
                    className="btn global-btn ido-btn">
                    Get Allocation
                  </button>
                : <button disabled className="btn global-btn ido-btn">Completed</button>
              }</>
            }
            </div>
        </div>
        }
      </div>
    )
    }
}

export default withRouter(Card);

/* eslint-disable class-methods-use-this */
import React, { useState } from 'react';
import {withRouter} from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import binance from '../../images/keymile/binance.svg';
import ethereum from '../../images/keymile/ethereum.svg';
import polygon from '../../images/keymile/polygon.svg';
import { getChainSymbol } from '../../utils';

const BuyModal = ({
  toggle,
  onCloseModal,
  onBuy,
  price,
  usd,
  blockChainNetworkId,
  balance,
  chainCurrency
})=> {

  const currentChainSymbol = getChainSymbol(blockChainNetworkId);

  const [value, setValue] = useState(0);
    return (
      <Modal open={ toggle } onClose={onCloseModal}  classNames={ {
          overlay: 'customOverlay',
          modal: 'terms-modal buy'  
        } } center>
          <button onClick={onCloseModal} className="cross">X</button>
          <h3>Monsters Clan Mystery Box</h3>
          <div className="add-quantity">
            <p>Quantity</p>
            <div>
            <button onClick={()=>value > 0 && setValue(value -1) } className="btn global-btn">-</button>
            <input onChange={(e)=> e.target.value === '' ? setValue(Number(0)): setValue( Number(e.target.value))} value={value} className="form-control" type="number" placeholder="0.0" />
            <button onClick={()=> setValue(value + 1) }  className="btn global-btn">+</button>
            </div>
            <span>You will pay</span>
            <h5>
              {
                currentChainSymbol === 'BSC' ? <img width="20" className="mr-2" src={binance} alt=""/> :
                currentChainSymbol === 'ETH' ? <img width="20" className="mr-2" src={ethereum} alt=""/> :
                currentChainSymbol === 'MATIC' ? <img width="20" className="mr-2" src={polygon} alt=""/> : null
              }
              {(value * price).toFixed(2)} {chainCurrency}
            </h5>
            <span>= {(value * usd).toFixed(2)} USD</span>
            <p className="last-p">Available Balance: <span>{balance} {chainCurrency}</span></p>
          </div>
          <button onClick={onCloseModal} className="btn button global-btn">Cancel</button>
          <button onClick={() => onBuy({ quantity: value, amount: Number((price * value).toFixed(2)) })}  className="btn button buy-btn global-btn">Buy</button>

      </Modal>
                
    )
}

export default withRouter(BuyModal);

export class ContractService {

    constructor() {
        this.addHooks();
    }

    addHooks() {
        for(let property of Object.getOwnPropertyNames(Object.getPrototypeOf(this))) {
            if(typeof this[property] === 'function' && property !== 'constructor' && property !== 'addHooks' && property !== 'preHook') {
                const instanceMethod = this[property].bind(this);
                this[property] = async (...args) => {
                    await this.preHook();
                    return instanceMethod(...args);
                };
            }
        }
    }

    // Need to override this.
    async preHook() {}
}
    

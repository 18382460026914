
export const duration = {
    seconds: function (val) { return val; },
    minutes: function (val) { return val * this.seconds(60); },
    hours: function (val) { return val * this.minutes(60); },
    days: function (val) { return val * this.hours(24); },
    weeks: function (val) { return val * this.days(7); },
    years: function (val) { return val * this.days(365); },
};


export const getChainCurrency = (blockChainNetworkId) => 
    (blockChainNetworkId === "1" || blockChainNetworkId === "3" || blockChainNetworkId === "42") ? 'ETH' :
    (blockChainNetworkId === "56" || blockChainNetworkId === "97") ? 'BNB' :
    (blockChainNetworkId === "137" || blockChainNetworkId === "80001") ? 'MATIC' :
    '-';


export const getIdoCurrency = (contractType,blockChainNetworkId) => 
    contractType === 'IdoSaleWithVestingByTokens' ? 
    process.env.REACT_APP_ENV === 'prod' ? blockChainNetworkId === "1" ? 'USDT' : 'BUSD'
    : 'MONSX' : getChainCurrency(blockChainNetworkId ? blockChainNetworkId.toString() : '')

export const getNetworkName = (blockChainNetworkId) => 
    blockChainNetworkId === "1" ? 'Ethereum' :
    blockChainNetworkId === "3" ? 'Ropsten' :
    blockChainNetworkId === "42" ? 'Kovan' :
    blockChainNetworkId === "56" ? 'Binance' :
    blockChainNetworkId === "97" ? 'Binance Testnet' :
    blockChainNetworkId === "137" ? 'Polygon' :
    blockChainNetworkId === "80001" ? 'Polygon Testnet' :
    '-';

export const getChainSymbol = (blockChainNetworkId) => 
    (blockChainNetworkId === "1" || blockChainNetworkId === "3" || blockChainNetworkId === "42") ? 'ETH' :
    (blockChainNetworkId === "56" || blockChainNetworkId === "97") ? 'BSC' :
    (blockChainNetworkId === "137" || blockChainNetworkId === "80001") ? 'MATIC' :
    '-';

export const calculateTickets = (tokens, daysLocked, ticketsDivisor) => (tokens * daysLocked) / ticketsDivisor;
export const calculateDaysForTickets = ( tokens, ticketsDivisor, tickets ) => Math.ceil( (tickets * ticketsDivisor) / tokens);
export const calculateTokensForTickets = ( days, ticketsDivisor, tickets ) => Math.ceil( ( tickets * ticketsDivisor) / days);
export const evaluateIsProking = ({ tokens, tickets, minTokensForProking, minTicketsForProking }) => (tokens >= minTokensForProking && tickets >= minTicketsForProking);

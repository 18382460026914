import React, {useState} from 'react';

import Navbar from '../../components/topNav';
import LightNavbar from '../../components/lightNave';

const withMasterLayout = WrappedComponent => props => 
{
  let textInput = React.createRef();
const [status, setStatus] = useState(true);
const [theme, setTheme] = useState('dark');
const changeTheme = (value) => {
  setTheme(value)
}
const  login = () =>{
  if(textInput.current.value === "irsolution") {
    setStatus( true);
  }
}
// const theme = localStorage.getItem('theme');
return(
  <>
   {!status && <div className="col-md-5 pasword">
     <h5>Enter Password to Access Website</h5>
      <input ref={textInput} className="form-control" type="password" />
      <button onClick={login} className="btn btn-primary">Enter</button>
    </div>}
  {status && <div key="master-layout" className="master-layout">
    {theme === 'light' && <LightNavbar changeTheme={changeTheme} />}
    {theme === 'dark' && <Navbar changeTheme={changeTheme}/>}
    <div className="row no-gutters master-height">
      <div className="col-12 col-sm-12">
        <WrappedComponent { ...props } />
      </div>
    </div>
  </div>}
  </>
);
}
export default withMasterLayout;

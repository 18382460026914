import { getContractABI, getContractAddress } from "../utils/abiHelper";
import { allowance, approveTokens } from "../web3Helper";
import { ether, getWeb3IfAvailable, parseError, toNumber } from "../web3Utils";
import { ContractService } from "./ContractService";
import { duration } from '../utils/index';

export const LOCKING_CONTRACT_TYPES = {
    LOCKING: 'Legacy',
    STAKING: 'Staking'
}
export default class StakingService extends ContractService {

    constructor(args = {}) {
        super(args);
    }

    async preHook() {
        this.web3 = await getWeb3IfAvailable()
        const networkId = await this.web3.eth.net.getId();
        this.contractType = networkId === 1 || networkId === 3 
            ? LOCKING_CONTRACT_TYPES.LOCKING : networkId === 56 || networkId === 97 
            ? LOCKING_CONTRACT_TYPES.STAKING : '';
        this.abi = getContractABI(this.contractType);
        this.accounts = await this.web3.eth.getAccounts();
        this.contractAddress = await getContractAddress(this.contractType);
        this.contractInstance = new this.web3.eth.Contract(
            this.abi,
            this.contractAddress
        );

        // To check if tokens can be released before release time.
        this.isReleasable = this.contractType === LOCKING_CONTRACT_TYPES.STAKING;
        this.isSupportedNetwork = Boolean(this.contractAddress);
    }

    async stake({
        amount = 0,
        periodInDays = 0,
    }) {
        const stakeAmount = ether(amount).toString();
        const gasPrice = await this.web3.eth.getGasPrice();

        if( this.contractType === LOCKING_CONTRACT_TYPES.LOCKING ) {

            const releaseTime = process.env.REACT_APP_ENV === 'prod' 
            ? Math.floor(new Date().getTime()/1000) + ( duration.days(Number(periodInDays)) )
            : Math.floor(new Date().getTime()/1000) + ( duration.minutes(5) );

            const lockId = `lock_${releaseTime}`;

            try {
                let estimatedGas = await this.contractInstance
                .methods
                .lock( 
                  this.accounts[0],
                  lockId,
                  stakeAmount,
                  releaseTime,
                  Number(periodInDays)
                )
                .estimateGas({
                    from: this.accounts[0]
                });

                await this.contractInstance
                .methods
                .lock(
                    this.accounts[0],
                    lockId,
                    stakeAmount,
                    releaseTime,
                    Number(periodInDays)
                ).send({ 
                    from: this.accounts[0],
                    gasPrice,
                    gas: estimatedGas + 5000 
                });
            } catch (e) {
                console.log('EVM Error', e);
                const parsedErrorMessage = parseError(e);
                throw parsedErrorMessage;
            }
            return 'Lock created successfully';
        } else if ( this.contractType === LOCKING_CONTRACT_TYPES.STAKING ) {
            try {
                let estimatedGas = await this.contractInstance
                .methods
                .stake( 
                  stakeAmount,
                  Number(periodInDays)
                )
                .estimateGas({
                    from: this.accounts[0]
                });

                await this.contractInstance
                .methods
                .stake(
                    stakeAmount,
                    Number(periodInDays)
                ).send({ 
                    from: this.accounts[0],
                    gasPrice,
                    gas: estimatedGas + 5000 
                });
            } catch (e) {
                console.log('EVM Error', e);
                const parsedErrorMessage = parseError(e);
                throw parsedErrorMessage
            }
            return 'Tokens staked successfully';
        }
    }

    async unStake({
        lockId = '',
    }) {

        const gasPrice = await this.web3.eth.getGasPrice();

        if( this.contractType === LOCKING_CONTRACT_TYPES.LOCKING ) {
            try {
                let estimatedGas = await this.contractInstance
                .methods
                .release( 
                    this.accounts[0],
                    lockId
                )
                .estimateGas({
                    from: this.accounts[0]
                });

                await this.contractInstance
                .methods
                .release(
                    this.accounts[0],
                    lockId
                ).send({ 
                    from: this.accounts[0],
                    gasPrice,
                    gas: estimatedGas + 5000 
                });
            } catch(e) {
                console.log('EVM Error', e);
                if(e.message.includes('Lock already released.')) {
                    // eslint-disable-next-line   no-throw-literal
                    throw 'Lock already released';
                } else {
                    throw parseError(e);
                }
            }

            return 'Lock released successfully.';
        } else if ( this.contractType === LOCKING_CONTRACT_TYPES.STAKING ) {
            try {
                let estimatedGas = await this.contractInstance
                .methods
                .unStake()
                .estimateGas({
                    from: this.accounts[0]
                });

                await this.contractInstance
                .methods
                .unStake()
                .send({ 
                    from: this.accounts[0],
                    gasPrice,
                    gas: estimatedGas + 5000 
                });
            } catch(e) {
                console.log('EVM Error', e);
                throw parseError(e);
            }
            return 'Tokens released successfully';
        }
    }

    async allowance() {
        return allowance({
            contractType: 'ProtToken',
            spenderAddress: this.contractAddress
        })
    }

    async approve ({
        amount
    }) {
    
        return approveTokens({
            contractType: 'ProtToken',
            approvalAmount: amount,
            spenderAddress: this.contractAddress,
        })
    }

    async checkStake({ 
        lockId
    }) {
        let isReleased = false;
        if( this.contractType === LOCKING_CONTRACT_TYPES.LOCKING ) {
            try {
                const lock = await this.contractInstance
                .methods
                .getUserLock(
                    this.accounts[0],
                    lockId,
                ).call();
                isReleased = lock.isReleased;
            } catch(e) {
                const parsedErrorMessage = parseError(e);
                console.log('Caught error while fetching lock', parsedErrorMessage);
                // eslint-disable-next-line   no-throw-literal
                throw 'Unable to fetch lock status';
            }
        } else if ( this.contractType === LOCKING_CONTRACT_TYPES.STAKING ) {
            try {
                const startTime = lockId.split('_')[0]
                const stakeInfo = await this.contractInstance
                .methods
                .getStakeInfo(
                    this.accounts[0],
                ).call();
                isReleased = !(startTime === stakeInfo.startTime);
            } catch(e) {
                const parsedErrorMessage = parseError(e);
                console.log('Caught error while fetching stake', parsedErrorMessage);
                // eslint-disable-next-line   no-throw-literal
                throw 'Unable to fetch stake';
            }
        }
        return isReleased;
    }

    async getStakeInfo() {
        let userStakeInfo = {
            holding: 0,
            locked: 0,
            real: 0,
        };
        if( this.contractType === LOCKING_CONTRACT_TYPES.LOCKING ) {
            try {
                const stakeInfo = await this.contractInstance.methods.users(this.accounts[0]).call();
                userStakeInfo = {
                    holding: stakeInfo ? toNumber(stakeInfo.holding) : 0,
                    locked: stakeInfo ? toNumber(stakeInfo.locked) : 0,
                    real: stakeInfo ? toNumber(stakeInfo.real) : 0,
                };
            } catch(e) {
                const parsedErrorMessage = parseError(e);
                console.log('Caught error while fetching lock data', parsedErrorMessage);
                // eslint-disable-next-line   no-throw-literal
                throw 'Unable to fetch lock data';
            }
        } else if ( this.contractType === LOCKING_CONTRACT_TYPES.STAKING ) {
            try {
                const stakeInfo = await this.contractInstance
                .methods
                .getStakeInfo(
                    this.accounts[0],
                ).call();
                userStakeInfo = {
                    holding: 0,
                    locked: stakeInfo ? toNumber(stakeInfo.totalAmount) : 0,
                    real: 0,
                };
            } catch(e) {
                const parsedErrorMessage = parseError(e);
                console.log('Caught error while fetching stake info', parsedErrorMessage);
                // eslint-disable-next-line   no-throw-literal
                throw 'Unable to fetch stake info';
            }
        }
        return userStakeInfo;
    }
}
import React, {Component} from 'react';
import Card from './card';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import rocket from '../../images/landing/rocket.svg';
class FeaturedPool extends Component {
  
  state = {
    projects: [],
    loader: false
  }
  componentDidMount() {
    this.featuredProjects();
  }

  featuredProjects = async () => {
    this.setState({loader: true});
    try {
      const response = await axios.get(`/ido/featured-idos`);
      this.setState({
        projects: response.data.featuredIdos,
        loader: false
      })
    } 
    catch (e) {
      this.setState({loader: false});
      toast.error('Network Error!');
    }
  }
  render() {
    const {projects, loader} = this.state;

    return (
      <div className="col-12 pools">
        <h3><img src={rocket} alt=""/> FEATURED POOLS</h3>
        <div className="row sub-pool">
          {loader ? 
            <Loader
            className="loader"
            type="Puff"
            color="#ffffff"
            height={ 80 }
            width={ 80 }/> 
            :
            projects.map(project => (
              <Card key={project._id} type='feature' project={project} />
            ))
          } 
        </div>
      </div>
        )
    }
}

export default FeaturedPool;
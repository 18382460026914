import React, {Component} from 'react';
import axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import Card from '../landing/card';
class Project extends Component {

  constructor(){
    super();
    this.state = {
      projects: [],
      featureProjects: [],
      status: false
    }
  }
  componentDidMount() {
    this.initProjects();
    this.featuredProjects();
  }

  initProjects = async () => {
    try {
      const response = await axios.get(`/ido/list`);
      this.setState({
        projects: response.data.results
      })
    } catch (e) {
      toast.error('Network Error!')
    }

  }
  featuredProjects = async () => {
    try {
      const response = await axios.get(`/ido/featured-idos`);
      this.setState({
        featureProjects: response.data.featuredIdos
      })
    } catch (e) {
      toast.error('Network Error!')
    }
  }

  render() {
    const { projects, featureProjects, status } = this.state;
    return (
       
          <div className="project px-3">
            <button onClick={()=> this.setState({status: !status})} className={`${!status ? 'active': ''} btn top-button global-btn`}>Upcoming Projects</button>
            <button onClick={()=> this.setState({status: !status})} className={`${status ? 'active': ''} btn top-button global-btn`}>Featured Project</button>
            <Scrollbars style={ { height: 580, width: 'auto' } }
              className="scroll"
              renderTrackHorizontal={ props => <div { ...props } className="track-horizontal"/> }
              renderTrackVertical={ props => <div { ...props } className="track-vertical"/> }>
              <div className="row no-gutters">
              {!status &&
                  projects.map((project) => (
                    <Card type='pool'  status='project' project={project} />
                ))}     
                {status &&
                  featureProjects.map((project) => (
                    <Card type='feature' status='project' project={project} />
                ))
                }
              </div>
            </Scrollbars>
          </div>
        )
    }
}

export default withRouter(Project);